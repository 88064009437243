import React from 'react';
import '../css/Switch.css';

const Switch = ({isOn, handleToggle, sku}) => {
  return (
    <>
      <input
        checked={isOn}
        onChange={handleToggle}
        value={sku}
        className="react-switch-checkbox"
        id={`${sku}`}
        type="checkbox"
      />
      <label
        style={{ background: isOn ?  '#06D6A0' : "gray" }}
        className="react-switch-label"
        htmlFor={`${sku}`}
      >
        <span className={`react-switch-button`} />
      </label>
    </>
  );
};

export default Switch;
