import React, { useState, useEffect } from 'react';
import { useLocation, useSearchParams, useParams } from 'react-router-dom';
import axios from 'axios'
import moment from 'moment';
import {FULFILLMENTOPTIONS} from '../shared/fulfillmentOptions.js';
import { useAuth0 } from "@auth0/auth0-react";

import {professionalRateCalculator} from '../shared/sharedFunctionsFrontEnd.js';

function OrderDetail (props) {
  const [order, setOrder] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [backendIdentifier] = useState("orderSearch");
  const [fulfillmentOptions] = useState(FULFILLMENTOPTIONS);
  const [fulfillmentStatus, fulfillmentStatusUpdate] = useState("");
  const [paymentStatus, paymentStatusUpdate] = useState("");
  const [disabled, setDisabled] = useState(0)
  const [width, setWidth] = useState("")
  const [height, setHeight] = useState("")
  const [length, setLength] = useState("")
  const [weight, setWeight] = useState("")
  const [trackingId, setTrackingId] = useState("")
  const location = useLocation();
  const { user } = useAuth0();
  const orderIdFromParams = useParams()

  const orderUpdate = (event) => {
    event.preventDefault();
    console.log(paymentStatus)
    axios.post('/orders/' + event.target.name, {
      fulfillmentStatus: fulfillmentStatus,
      paymentStatus: paymentStatus,
      backendIdentifier: backendIdentifier,
      emailId: order[0].orderDetails.customerEmail,
      customerName: order[0].orderDetails.customerName,
      user: user.name
    })
    .then(res => {
      var orderArray = []
      orderArray.push(res.data)
      setOrder(orderArray)
      window.location.reload();
    })
    .catch(err => console.log(err));
  }

  useEffect(() => {
    console.log(orderIdFromParams)
    axios.get('/orders/' + orderIdFromParams.orderId)
    .then(res => {
      setOrder(res.data)
      setWidth(res.data[0].shipping.width || "0")
      setLength(res.data[0].shipping.length || "0")
      setHeight(res.data[0].shipping.height || "0")
      setWeight(res.data[0].shipping.weight || "0")
      setTrackingId(res.data[0].shipping.trackingId || "")
    })
    .catch(err => console.log(err));
  }, [location]);


  const packageAndTrackingUpdate = (event) => {
    event.preventDefault();
    axios.put('/orders/' + orderIdFromParams.orderId, {
      height: height,
      length: length,
      width: width,
      weight: weight,
      trackingId: trackingId,
      backendIdentifier: "packageDetailsUpdate",
    })
    .then(res => {
      window.location.reload();
    })
    .catch(err => console.log(err));
  };

  const exportLabel = (e) => {
    e.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0)
   }.bind(this),10000);
    console.log('exportLabel being called');
    axios.post('/dashboard/print-label', {
      orders: order[0].orderDetails.orderId,
    }, {
     responseType: 'arraybuffer',
     headers: {
       'Accept': 'application/pdf'
     }
   })
    .then(res => {
      console.log(res)
      const blob = new Blob([res.data], {type: 'application/pdf'})
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `label.pdf`;
      link.click()
    })
    .catch(err => console.log(err.response));
  }

  const exportXml = (e) => {
    e.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0)
   }.bind(this),10000);
    console.log('Export XML being called');
    axios.post('/dashboard/tally-order-upload', {
      orderId: order[0].orderDetails.orderId,
    }, {
     responseType: 'arraybuffer',
     headers: {
       'Accept': 'text/xml'
     }
   })
    .then(res => {
      console.log(res)
      const blob = new Blob([res.data], {type: 'text/xml'})
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `order.xml`;
      link.click()
    })
    .catch(err => console.log(err.response));
  }

  const printPOD = (e) => {
    e.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0)
   }.bind(this),10000);
    console.log('printPOD being called');
    axios.post('/dashboard/print-pod', {
      orders: order[0].orderDetails.orderId,
    }, {
     responseType: 'arraybuffer',
     headers: {
       'Accept': 'application/pdf'
     }
   })
    .then(res => {
      console.log(res)
      const blob = new Blob([res.data], {type: 'image/jpeg'})
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `POD.jpg`;
      link.click()
    })
    .catch(err => console.log(err.response));
  }

  const uploadToAggregator = (e) => {
    e.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0)
   }.bind(this),10000);
    console.log('Uploading to aggregators');
    axios.get('/dashboard/upload-to-aggregators?orderId=' + order[0].orderDetails.orderId)
    .then(res => {
      console.log("Succesfully uploaded orders to shipease/shiprocket")
    })
    .catch(err => console.log(err.response));
  }

  const orderDisplay = (
    <>
    <hr className="hr-large d-none d-md-block"/>
    <input className="btn bulk-update-button btn-large bulk-update-order-button" onClick={(e) => exportXml(e)} type='submit' value={disabled ? 'Exporting XML...' : 'Export XML' } disabled={disabled}/>
    <input className="btn bulk-update-button btn-large bulk-update-order-button" onClick={(e) => exportLabel(e)} type='submit' value={disabled ? 'Printing Label...' : 'Print Label' } disabled={disabled}/>
    <input className="btn bulk-update-button btn-large bulk-update-order-button" onClick={(e) => printPOD(e)} type='submit' value={disabled ? 'Printing POD...' : 'Print POD' } disabled={disabled}/>
    <input className="btn bulk-update-button btn-large bulk-update-order-button" onClick={(e) => uploadToAggregator(e)} type='submit' value={disabled ? 'Uploading...' : 'Upload To Shipease/Shiprocket' } disabled={disabled}/>
    <hr className="hr-large d-none d-md-block"/>
    {order.map((orderOne) => (
    <div className="rowcontainer" key={orderOne.orderDetails.orderId}>
      <div className="col-md-12 col-xl-12 col-sm-12 col-xs-12">
        <div className="b2corders">
          <h4 className="text-center"> Order Details </h4>
          <table className='table'>
            <thead>
              <tr>
                <th scope="col" data-sortable='true'> Order ID </th>
                <th scope="col" data-sortable='true'> Order Total </th>
                <th scope="col" data-sortable='true'> Order Date </th>
                <th scope="col" data-sortable='true'> Deliver By Date </th>
                <th scope="col" data-sortable='true'> Delivery Address </th>
                <th scope="col" data-sortable='true'> Fulfillment Status </th>
                <th scope="col" data-sortable='true'> Payment Status </th>
                <th scope="col" data-sortable='true'> Delivery Date </th>
              </tr>
            </thead>
              <tbody>
                <tr>
                  <td> {orderOne.orderDetails.orderId} </td>
                  <td> {orderOne.orderDetails.orderTotal} </td>
                  <td> {moment(orderOne.orderDetails.orderDate).format("DD-MMM-YY")} </td>
                  <td> {moment(orderOne.orderDetails.deliverByDate).format("DD-MMM-YY")} </td>
                  <td>
                    {orderOne.orderDetails.deliveryAddress.deliveryStreet1} <br/>
                    {orderOne.orderDetails.deliveryAddress.deliveryStreet2} <br/>
                    {orderOne.orderDetails.deliveryAddress.deliveryCity}, {orderOne.orderDetails.deliveryAddress.deliveryState}, {orderOne.orderDetails.deliveryAddress.deliveryZip}
                  </td>
                  <td> {orderOne.fulfillmentStatus} </td>
                  <td> {orderOne.paymentStatus ? orderOne.paymentStatus : "" }</td>
                  <td> {orderOne.deliveryDate ? moment(orderOne.deliveryDate).format("DD-MMM-YY") : ""}</td>
                </tr>
              </tbody>
          </table>
          <div className="order-adjustment-parent space-top">
            <div className="order-adjustment b2corders">
              <h4 className="text-center"> Customer Details </h4>
              <table className="b2corders table">
                <thead>
                  <tr>
                    <th scope="col" data-sortable="true"> Customer Name </th>
                    <th scope="col" data-sortable="true"> Customer Phone </th>
                    <th scope="col" data-sortable="true"> Customer Email </th>
                  </tr>
                </thead>
                  <tbody>
                    <tr>
                      <td> {orderOne.orderDetails.customerName} </td>
                      <td> {orderOne.orderDetails.customerPhone} </td>
                      <td> {orderOne.orderDetails.customerEmail} </td>
                    </tr>
                  </tbody>
              </table>
            </div>
            <div className="order-adjustment b2corders">
              <h4 className="text-center"> Product & Package Details </h4>
              <table className="b2corders table">
                <thead>
                  <tr>
                    <th scope="col" data-sortable="true"> Product Name </th>
                    <th scope="col" data-sortable="true"> Product Quantity </th>
                    <th scope="col" data-sortable="true"> Product Price Without Tax </th>
                    <th scope="col" data-sortable="true"> Product Tax (%) </th>
                    <th scope="col" data-sortable="true"> Product Tax Amount </th>
                    <th scope="col" data-sortable="true"> Discount %</th>
                    <th scope="col" data-sortable="true"> Discount </th>
                    <th scope="col" data-sortable="true"> Product Price </th>
                    <th scope="col" data-sortable="true"> Product Amount </th>
                  </tr>
                </thead>
                  <tbody>
                  {orderOne.orderDetails.productDetails.map((product) => (
                    <tr key={product.productSku}>
                      <td> {product.productName}, {product.productOption ? product.productOption : ''} </td>
                      <td> {product.productQuantity} </td>
                      <td> {product.productPriceWithoutTax ? product.productPriceWithoutTax : "" } </td>
                      <td> {product.taxPercentage ? product.taxPercentage : "" } </td>
                      <td> {product.taxAmount ? product.taxAmount : "" } </td>
                      <td> {product.discountPer ? product.discountPer : "" } </td>
                      <td> {product.discount ? product.discount : "" } </td>
                      <td> {product.productPrice ? product.productPrice : "" } </td>
                      <td> {product.productPrice ? product.productPrice * product.productQuantity : "" } </td>
                    </tr>
                  ))}
                    <tr>
                      <td>  </td>
                      <td>  </td>
                      <td>  </td>
                      <td>  </td>
                      <td>  </td>
                      <td>  </td>
                      <td>  </td>
                      <td> <strong>Tax</strong> </td>
                      <td> {orderOne.orderDetails.orderTotalTax} </td>
                    </tr>
                    <tr>
                      <td>  </td>
                      <td>  </td>
                      <td>  </td>
                      <td>  </td>
                      <td>  </td>
                      <td>  </td>
                      <td>  </td>
                      <td> <strong> Shipping </strong> </td>
                      <td> {orderOne.orderDetails.shippingPrice} </td>
                    </tr>
                    <tr>
                      <td>  </td>
                      <td>  </td>
                      <td>  </td>
                      <td>  </td>
                      <td>  </td>
                      <td>  </td>
                      <td>  </td>
                      <td> <strong> Discount </strong> </td>
                      <td> {orderOne.orderDetails.discount} </td>
                    </tr>
                    <tr>
                      <td>  </td>
                      <td>  </td>
                      <td>  </td>
                      <td>  </td>
                      <td>  </td>
                      <td>  </td>
                      <td>  </td>
                      <td> <strong> Total </strong> </td>
                      <td> {orderOne.orderDetails.orderTotal} </td>
                    </tr>
                  </tbody>
              </table>
            </div>
            <div className="order-adjustment b2corders section-hide">
              <h4 className="text-center"> Fulfillment Details </h4>
              <table className="b2corders table">
                <thead>
                  <tr>
                    <th scope="col" data-sortable="true"> SNS Tracking ID </th>
                    <th scope="col" data-sortable="true"> 3rd Party Courier Name </th>
                    <th scope="col" data-sortable="true"> Aggregator </th>
                    <th scope="col" data-sortable="true"> 3rd Party Tracking ID </th>
                    <th scope="col" data-sortable="true"> Package Details </th>
                    <th scope="col" data-sortable="true"> Rate </th>
                    <th scope="col" data-sortable="true"> Courier Billed Rate </th>
                    <th scope="col" data-sortable="true"> Update Shipment Details </th>
                  </tr>
                </thead>
                  <tbody>
                    <tr>
                      <td> {orderOne.trackingId} </td>
                      <td> {orderOne.shipping.courier} </td>
                      <td> {orderOne.assignedRoute} </td>
                      <td>
                        <label><input  className="tracking-input" name="trackingId" value={trackingId} onChange={e => setTrackingId(e.target.value)}/> </label>
                      </td>
                      <td>
                        <div>
                          <span className="dim-input-span">
                            <label><input  className="dim-input" name="length" value={length} onChange={e => setLength(e.target.value)}/> L (cm) </label>
                          </span>
                          <span className="dim-input-span">
                            <label><input  className="dim-input" name="width" value={width} onChange={e => setWidth(e.target.value)}/> W (cm) </label>
                          </span>
                          <span className="dim-input-span">
                            <label><input  className="dim-input" name="height" value={height} onChange={e => setHeight(e.target.value)}/> H (cm) </label>
                          </span>
                          <span className="dim-input-span">
                            <label><input  className="dim-input" name="weight" value={weight} onChange={e => setWeight(e.target.value)}/> WE (Kg) </label>
                          </span>
                        </div>
                      </td>
                      <td> {orderOne.shipping.weight && orderOne.shipping.courier === "Professional"? professionalRateCalculator(orderOne) : "Package Details Not Set / Courier Not Professional"}</td>
                      <td> {orderOne.shipping.courierChargedRate} </td>
                      <td>
                        <div className = "iuo">
                          <input className = "btn btn-large btn-primary" onClick={packageAndTrackingUpdate} type='submit' value='&#10004;' />
                        </div>
                      </td>
                    </tr>
                  </tbody>
              </table>
            </div>
            <div className="order-adjustment b2corders">
              <h4 className="text-center"> Tracking Details </h4>
              <table className="b2corders table">
                <thead>
                  <tr>
                    <th scope="col" data-sortable="true"> Status </th>
                    <th scope="col" data-sortable="true"> Time Stamp </th>
                    <th scope="col" data-sortable="true"> User </th>
                  </tr>
                </thead>
                  <tbody>
                  {orderOne.trackingDetails.map((trackingDetails,i) => (
                    <tr key={trackingDetails.timeStamp}>
                      <td> {trackingDetails.fulfillmentStatus}</td>
                      <td> {moment(trackingDetails.timeStamp).format("DD-MMM-YY, h:mm:ss a")}  </td>
                      <td> {trackingDetails.user}  </td>
                    </tr>
                  ))}
                  </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="thumbnail center well well-sm text-center text-center space-top">
        <div className="bulk-update">
          <div className='input-group'>
            <div className="bulk-update-select">
              <select className='form-control' type="text" onChange={e => fulfillmentStatusUpdate(e.target.value)} name="fulfillmentStatus" required>
                {fulfillmentOptions.map((status) => (
                  <option key={status.id} value={status.name}> {status.name} </option>
                ))}
              </select>
            </div>
            <div className="bulk-update-select">
              <select className='form-control' type="text" onChange={e => paymentStatusUpdate(e.target.value)} name="paymentStatus" required>
                <option value=''> -- </option>
                <option value='Unpaid'> Unpaid </option>
                <option value='Paid'> Paid </option>
                <option value='Refunded'> Refunded </option>
              </select>
            </div>
          </div>
        </div>
        <div className = "iuo">
          <input className = "btn btn-large btn-primary" type='submit' value='UPDATE STATUS' name={orderOne.orderDetails.orderId} onClick={orderUpdate} />
        </div>
      </div>
    </div>
  ))}
    </>
  )

  return (
    <div className="container-fluid">
      <div className="content-wrapper">
        <header>
          <div className="">
            <h1>Orders</h1>
          </div>
        </header>
        {orderDisplay}
      </div>
    </div>
  );
}

export default OrderDetail

// <form className="needs-validation" onSubmit={orderUpdate} action={'/orders/' + orderOne.orderDetails.orderId} method='POST'>
//   <div className="input-group orderOnePageUpdateFormDiv">
//     <span className="input-group-addon">
//       <i className="fa fa-envelope"/>
//       <div>
//         <select className='form-control orderOneSelect' type="text" name="fulfillmentStatus">
//           {fulfillmentOptions.map((status) => (
//             <option key={status.id} value={status.name}> {status.name} </option>
//           ))}
//         </select>
//         <label htmlFor='fulfillmentStatus'/>
//         <select className='form-control orderOneSelect' type="text" name="paymentStatus">
//           <option value="Unpaid"> Unpaid </option>
//           <option value="Unpaid"> Unpaid </option>
//           <option value="Paid"> Paid </option>
//           <option value="Refunded"> Refunded </option>
//         </select>
//         <label htmlFor='fulfillmentStatus'/>
//       </div>
//       <div className="hidden">
//         <input  name="orderId" type="hidden" value={orderOne.orderDetails.orderId} />
//         <input  name="email" type="hidden" value={orderOne.orderDetails.customerEmail} />
//         <input  name="name" type="hidden" value={orderOne.orderDetails.customerName} />
//         <label htmlFor='orderId'/>
//       </div>
//     </span>
    // <div className = "iuo">
    //   <input className = "btn btn-large btn-primary" type='submit' value='UPDATE STATUS' />
    // </div>
//   </div>
// </form>
