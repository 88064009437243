import React, { useState, useEffect } from 'react';
import {Modal} from 'react-bootstrap'
import { useLocation } from 'react-router-dom';
import axios from 'axios'
import { useAuth0 } from "@auth0/auth0-react";

function OrderDetail (props) {
  const [product, setProduct] = useState({image: "",sku:"",name:"",amazonSku: "",ecwidProductId:"",tallyName:"",upc:"", hsn: "", qty: "",safetyStock:"", purchasePrice:"", tax:"",mrp:"",expiryDate:"",brand:"",category:"",vendor:""});
  const [disabled, setDisabled] = useState(0);
  const [show, setShow] = useState(false);
  const [productDetails, setProductDetails] = useState({image: "",amazonSku: "", flipkartSku: "",tallyName:"",upc:"", hsn: "", qty: "", purchasePrice:"", tax:"",mrp:"",expiryDate:""})
  const [fifteenDaySales, updateFifteenDaySales] = useState(0)
  const [brands, setBrands] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [category, setCategory] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [safetyStock, safetyStockUpdate] = useState(0)
  const [variationSafetyStock, variationSafetyStockUpdate] = useState("")
  const [amazonSku, amazonSkuUpdate] = useState("");
  const [variationAmazonSku, setVariationAmazonSku] = useState("")
  const [tallyName, tallyNameUpdate] = useState("");
  const [variationTallyName, setVariationTallyName] = useState("")
  const [sku, setSku] = useState("");
  const [variationQty, setVariationQty] = useState("");
  const [newLinkItem, setNewLinkItem] = useState({linkedSku: "",factor: ""})
  const location = useLocation()

  useEffect(() => {
    axios.get('/products/' + props.match.params.sku)
    .then(res => {
      console.log("Succesfully obtained data from backend")
      setProduct(prevState => ({
          ...prevState,
          image: res.data.image || "",
          sku:res.data.sku || "",
          name:res.data.name || "",
          amazonSku: res.data.amazonSku || "",
          flipkartSku: res.data.flipkartSku || "",
          ecwidProductId:res.data.ecwidProductId|| "",
          tallyName: res.data.tallyName || "",
          upc: res.data.upc || "",
          hsn: res.data.hsn || "",
          qty: res.data.qty || "",
          safetyStock:res.data.safetyStock || "",
          purchasePrice: res.data.purchasePrice || "",
          tax: res.data.tax || "",
          mrp: res.data.mrp || "",
          expiryDate: res.data.expiryDate || "",
          brand:res.data.brand || "",
          category:res.data.category || "",
          vendor:res.data.vendor || "",
      }));

    })
    .catch(err => console.log(err));

    axios.post('/dashboard/product-analysis', {
      sku: props.match.params.sku
    })
    .then(res => {
      updateFifteenDaySales(res.data.fifteenDaySales || 0)
    })
    .catch(err => console.log(err));

    axios.get('/products/distinct')
    .then(res => {
      res.data.brand.sort(function( a, b ) {
        a = a.toLowerCase();
        b = b.toLowerCase();
        return a < b ? -1 : a > b ? 1 : 0;
      });

      res.data.vendor.sort(function( a, b ) {
        a = a.toLowerCase();
        b = b.toLowerCase();
        return a < b ? -1 : a > b ? 1 : 0;
      });

      res.data.category.sort(function( a, b ) {
        a = a.toLowerCase();
        b = b.toLowerCase();
        return a < b ? -1 : a > b ? 1 : 0;
      });

      res.data.tax.sort((a, b) => a - b);


      setBrands(res.data.brand )
      setVendors(res.data.vendor )
      setCategory(res.data.category )
      setTaxes(res.data.tax )
    })
    .catch(err => console.log(err));

  }, [location]);

  const handleClose = () => {
    setShow(false)
  }

  const handleProductDetailsChange = e => {
    const { name, value } = e.target;
    setProduct(prevState => ({
        ...prevState,
        [name]: value.trim()
    }));
  };

  const productDetailsUpdate = (event) => {
    setShow(true)
    console.log(product.sku)
    event.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0);
    }.bind(this),10000);
    axios.put('/products/' + product.sku, {
      image: product.image || "",
      sku:product.sku || "",
      name:product.name || "",
      amazonSku: product.amazonSku || "",
      flipkartSku: product.flipkartSku || "",
      ecwidProductId:product.ecwidProductId|| "",
      tallyName: product.tallyName || "",
      upc: product.upc || "",
      hsn: product.hsn || "",
      qty: product.qty || "",
      safetyStock:product.safetyStock || "",
      purchasePrice: product.purchasePrice || "",
      tax: product.tax || "",
      mrp: product.mrp || "",
      expiryDate: product.expiryDate || "",
      brand:product.brand || "",
      category:product.category || "",
      vendor:product.vendor || "",
      backendIdentifier: "productDetails"
    })
    .then(res => {
      setProduct(prevState => ({
          ...prevState,
          image: res.data.image || "",
          sku:res.data.sku || "",
          name:res.data.name || "",
          amazonSku: res.data.amazonSku || "",
          flipkartSku: res.data.flipkartSku || "",
          ecwidProductId:res.data.ecwidProductId|| "",
          tallyName: res.data.tallyName || "",
          upc: res.data.upc || "",
          hsn: res.data.hsn || "",
          qty: res.data.qty || "",
          safetyStock:res.data.safetyStock || "",
          purchasePrice: res.data.purchasePrice || "",
          tax: res.data.tax || "",
          mrp: res.data.mrp || "",
          expiryDate: res.data.expiryDate || "",
          brand:res.data.brand || "",
          category:res.data.category || "",
          vendor:res.data.vendor || "",
      }));
    })
    .catch(err => console.log(err));
  };

  const updateSafetyStock = (e) => {
    axios.put(`/products/${e.target.name}`, {
      safetyStock: safetyStock,
      backendIdentifier: "productDetails"
    })
    .then(res => {
      window.location.reload();
    })
    .catch(err => console.log(err));
  }

  const updateVariationSafetyStock = (e) => {
    axios.put(`/products/${e.target.name}`, {
      variationSafetyStock: variationSafetyStock,
      backendIdentifier: "productDetails"
    })
    .then(res => {
      window.location.reload();
    })
    .catch(err => console.log(err));
  }

  const updateAmazonSku = (e) => {
    axios.put(`/products/${e.target.name}`, {
      amazonSku: amazonSku,
      backendIdentifier: "productDetails"
    })
    .then(res => {
      window.location.reload();
    })
    .catch(err => console.log(err));
  }

  const updateVariationAmazonSku = (e) => {
    axios.put(`/products/${e.target.name}`, {
      variationAmazonSku: variationAmazonSku,
      backendIdentifier: "productDetails"
    })
    .then(res => {
      window.location.reload();
    })
    .catch(err => console.log(err));
  }

  const updateTallyName = (e) => {
    axios.put(`/products/${e.target.name}`, {
      tallyName: tallyName,
      backendIdentifier: "productDetails"
    })
    .then(res => {
      window.location.reload();
    })
    .catch(err => console.log(err));
  }

  const updateVariationTallyName = (e) => {
    axios.put(`/products/${e.target.name}`, {
      variationTallyName: variationTallyName,
      backendIdentifier: "productDetails"
    })
    .then(res => {
      window.location.reload();
    })
    .catch(err => console.log(err));
  }

  const updateVariationQty = (e) => {
    axios.put(`/products/${e.target.name}`, {
      variationQty: variationQty,
      backendIdentifier: "productDetails"
    })
    .then(res => {
      // console.log(res.prod)
      // setProduct(res.prod)
      window.location.reload();
    })
    .catch(err => console.log(err));
  }

  const handleChangeLinkedItem = e => {
    const { name, value } = e.target;
    setNewLinkItem(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  const linkedItemUpdate = (e) => {
    e.preventDefault();
    console.log(props.match.params.sku)
    axios.put(`/products/${props.match.params.sku}`, {
      linkedSku: newLinkItem.linkedSku,
      factor: newLinkItem.factor,
      backendIdentifier: "productDetails"
    })
    .then(res => {
      console.log("Successfully added pending items to database")
      setProduct(res.data)
      setNewLinkItem(prevState => ({
          ...prevState,
          linkedSku: "",
          factor: "",
      }));
    })
    .catch(err => console.log(err));
  };

  const { loading, user } = useAuth0();

  const productDisplay = (
    <>
    <hr className="hr-large d-none d-md-block"/>
    <div className="rowcontainer" key={product.sku}>
      <div className="col-md-12 col-xl-12 col-sm-12 col-xs-12">
        <div className="b2corders">
          <h4 className="text-center"> Product Details </h4>
          <table className='table'>
            <thead>
              <tr>
                <th scope="col" data-sortable='true'> Image </th>
                <th scope="col" data-sortable='true'> SKU </th>
                <th scope="col" data-sortable='true'> Name </th>
                <th scope="col" data-sortable='true'> Amazon SKU </th>
                <th scope="col" data-sortable='true'> Flipkart SKU </th>
                <th scope="col" data-sortable='true'> ECWID Product ID </th>
                <th scope="col" data-sortable='true'> Tally Name </th>
                <th scope="col" data-sortable='true'> UPC No </th>
                <th scope="col" data-sortable='true'> HSN </th>
              </tr>
            </thead>
              <tbody>
                <tr>
                  <td> <a href={product.url} target="_blank"> <img className="product-image" src={product.image ? product.image: ""}></img> </a></td>
                  <td>
                    {product.sku}
                  </td>
                  <td> {product.name} </td>
                  <td>
                    <label><input  className="tracking-input" name="amazonSku" value={product.amazonSku || ""} onChange={handleProductDetailsChange} /> </label>
                  </td>
                  <td>
                    <label><input  className="tracking-input" name="flipkartSku" value={product.flipkartSku || ""} onChange={handleProductDetailsChange} /> </label>
                  </td>
                  <td> {product.ecwidProductId} </td>
                  <td>
                    <label><input  className="tracking-input" name="tallyName" value={product.tallyName || ""} onChange={handleProductDetailsChange} /> </label>
                  </td>
                  <td>
                    <label><input  className="tracking-input" name="upc" value={product.upc || ""} onChange={handleProductDetailsChange} /> </label>
                  </td>
                  <td>
                    <label><input  className="dim-input" name="hsn" value={product.hsn || ""} onChange={handleProductDetailsChange} /> </label>
                  </td>
                </tr>
              </tbody>
          </table>
          <div className="order-adjustment-parent space-top dashboard">
            <div className="dashboard order-adjustment b2corders col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12">
              <h4 className="text-center"> Quantity & Price </h4>
              <table className="b2corders table">
                <thead>
                  <tr>
                    <th scope="col" data-sortable="true"> Quantity </th>
                    <th scope="col" data-sortable="true"> Safety Stock </th>
                    <th scope="col" data-sortable="true"> Fifteen Day Sales </th>
                    <th scope="col" data-sortable="true"> Purchase Price </th>
                    <th scope="col" data-sortable="true"> MRP </th>
                    <th scope="col" data-sortable="true"> Expiry Date </th>
                  </tr>
                </thead>
                  <tbody>
                    <tr>
                      <td>
                        <label><input  className="dim-input" name="qty" value={product.qty || ""} onChange={handleProductDetailsChange} /> </label>
                      </td>
                      <td> {product.safetyStock} </td>
                      <td> {fifteenDaySales} </td>
                      <td>
                      <label><input  className="dim-input" name="purchasePrice" value={product.purchasePrice || ""} onChange={handleProductDetailsChange} /> </label>
                      </td>
                      <td>
                      <label><input  className="dim-input" name="mrp" value={product.mrp || ""} onChange={handleProductDetailsChange} /> </label>
                      </td>
                      <td>
                        <label><input  className="tracking-input" name="expiryDate" value={product.expiryDate || ""} onChange={handleProductDetailsChange} /> </label>
                      </td>
                    </tr>
                  </tbody>
              </table>
            </div>
            <div className="dashboard order-adjustment b2corders col-md-6 col-lg-6 col-xl-6S col-sm-12 col-xs-12">
              <h4 className="text-center"> Product Attributes </h4>
              <table className="b2corders table">
                <thead>
                  <tr>
                  <th scope="col" data-sortable='true'> Brand </th>
                  <th scope="col" data-sortable='true'> Category </th>
                  <th scope="col" data-sortable='true'> Supplier Name </th>
                  <th scope="col" data-sortable="true"> Tax </th>
                  </tr>
                </thead>
                  <tbody>
                    <tr>
                      <td>
                        {product.brand}
                        <div className="bulk-update-select">
                          <select className='form-control' type="text" onChange={handleProductDetailsChange} name="brand" required>
                            {brands.map((brand,i) => (
                              <option key={i} value={brand}> {brand} </option>
                            ))}
                          </select>
                        </div>
                      </td>
                      <td>
                        {product.category}
                        <div className="bulk-update-select">
                          <select className='form-control' type="text" onChange={handleProductDetailsChange} name="category" required>
                            {category.map((eachCategory,i) => (
                              <option key={i} value={eachCategory}> {eachCategory} </option>
                            ))}
                          </select>
                        </div>
                      </td>
                      <td>
                        {product.vendor}
                        <div className="bulk-update-select">
                          <select className='form-control' type="text" onChange={handleProductDetailsChange} name="vendor" required>
                            {vendors.map((vendor,i) => (
                              <option key={i} value={vendor}> {vendor} </option>
                            ))}
                          </select>
                        </div>
                      </td>
                      <td>
                        {product.tax}
                        <div className="bulk-update-select">
                          <select className='form-control' type="text" onChange={handleProductDetailsChange} name="tax" required>
                            {taxes.map((tax,i) => (
                              <option key={i} value={tax}> {tax} </option>
                            ))}
                          </select>
                        </div>
                      </td>
                    </tr>
                  </tbody>
              </table>
            </div>
            <div className="dashboard order-adjustment b2corders col-md-4 col-lg-4 col-xl-4 col-sm-12 col-xs-12">
              <h4 className="text-center"> Product Packaging </h4>
              <table className="b2corders table">
                <thead>
                  <tr>
                  <th scope="col" data-sortable='true'> Length </th>
                  <th scope="col" data-sortable='true'> Width </th>
                  <th scope="col" data-sortable='true'> Height </th>
                  <th scope="col" data-sortable='true'> Weight </th>
                  </tr>
                </thead>
                  <tbody>
                    <tr>
                      <td> {product.packaging ? product.packaging.length : 0} </td>
                      <td> {product.packaging ? product.packaging.width : 0} </td>
                      <td> {product.packaging ? product.packaging.height : 0} </td>
                      <td> {product.packaging ? product.packaging.weight : 0} </td>
                    </tr>
                  </tbody>
              </table>
            </div>
            <div className="dashboard order-adjustment b2corders col-md-3 col-lg-3 col-xl-3 col-sm-12 col-xs-12">
              <h4 className="text-center"> Linked Item </h4>
              <table className="b2corders table">
                <thead>
                  <tr>
                  <th scope="col" data-sortable='true'> Linked Sku </th>
                  <th scope="col" data-sortable='true'> Factor </th>
                  <th scope="col" data-sortable='true'> Update </th>
                  </tr>
                </thead>
                  <tbody>
                    {product.linkedItem ? (product.linkedItem.map((linkedItem, i) => (
                      <tr key={i}>
                        <td> {linkedItem.linkedSku} </td>
                        <td> {linkedItem.factor} </td>
                        <td>
                          <div className = "iuo">
                            <input className = "btn btn-large btn-primary" type='submit' value='X'   />
                          </div>
                        </td>
                      </tr>
                    ))) : []}
                    <tr>
                      <td>
                        <div className = "input-inputButton">
                          <input className='tracking-input margin-right' type='text' name="linkedSku" defaultValue={product.linkedItem ? product.linkedItem.linkedSku : ""} onChange={handleChangeLinkedItem}/>
                        </div>
                      </td>
                      <td>
                        <div className = "input-inputButton">
                          <input className='tracking-input margin-right' type='text' name="factor" defaultValue={product.linkedItem ? product.linkedItem.factor : ""} onChange={handleChangeLinkedItem}/>
                        </div>
                      </td>
                      <td>
                        <div className = "iuo">
                          <input className = "btn btn-large btn-primary" type='submit' value='&#10004;' name={product.sku} onClick={linkedItemUpdate}  />
                        </div>
                      </td>
                    </tr>
                  </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )

  return (
    <div className="container-fluid">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Product Details Updated Succesfully</Modal.Title>
        </Modal.Header>
        <Modal.Body>Product Details Updated Successfully</Modal.Body>
      </Modal>
      <div className="content-wrapper">
        <header>
          <div className="header-align">
            <h3 className="header">{product.name}</h3>
            <input className="btn bulk-update-button btn-large" onClick={productDetailsUpdate} type='submit' value={disabled ? 'Saving...' : 'Save' } disabled={disabled}/>
          </div>
        </header>
        {productDisplay}
      </div>
    </div>
  );
}

export default OrderDetail
