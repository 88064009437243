import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

import Editable from "../components/Editable.js";

function LeadsTracking (props) {
  const [dataView, setDataView] = useState(false)
  const [leads, setLeads] = useState([]);
  const [newLead, setNewLead] = useState({name: "",repName: "",phone:"",email:"", status: "", comments: "", lastContacted:"", salesRep:""});
  const [editLead, setEditLead] = useState({_id:"", name: "",repName: "",phone:"",email:"", status: "", comments: "", lastContacted:"", salesRep: ""});
  const [isEditing, setEditing] = useState(false)
  const [editRow, setEditRow] = useState(null)
  const [status, setStatus] = useState(0);
  const [disabled, setDisabled] = useState(0);
  const [tenantId, setTenantId] = useState("")
  // const { loading, user } = useAuth0();
  const { user, isAuthenticated, isLoading } = useAuth0();


  useEffect(() => {
    if(user) {
      setDataView(true)
    }
    setTenantId(user.tenantId)
    axios.get('/leads-tracking?tenantId=' + user.tenantId)
    .then(res => {
      setLeads(res.data)
    })
    .catch(err => console.log(err));
  }, []);

  const updateLead = (e) => {
    axios.put("/leads-tracking?tenantId=" + tenantId, {
      lead: editLead,
      id: e.target.name,
      update: "lead",
      status: status
    })
    .then(res => {
      console.log("status updated!");
      setLeads(res.data);
      setEditing(false)
      setEditRow(null)
    })
    .catch(err => console.log(err));
  }

  const editRowFunc = (e) => {
    console.log(e.target.name)
    setEditing(true);
    const leadEdit = leads[e.target.name]
    setEditRow(leadEdit._id);
    // console.log(leadEdit)
    setEditLead({...editLead,
        _id: leadEdit._id,
        name: leadEdit.name,
        repName: leadEdit.repName,
        phone: leadEdit.phone,
        email: leadEdit.email,
        status: leadEdit.status,
        comments: leadEdit.comments,
        lastContacted: leadEdit.lastContacted,
        salesRep: leadEdit.salesRep,
        tenantId: tenantId
    });
  }

  const handleEditLeads = e => {
    const { name, value } = e.target;
    setEditLead(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  const handleLeads = e => {
    const { name, value } = e.target;
    setNewLead(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  const handlePaymentFollowUp = e => {
    const { name, value } = e.target;
    setNewLead(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  const leadsUpdate = (event) => {
    event.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0);
    }.bind(this),10000);
    axios.post('/leads-tracking?tenantId=' + tenantId, {
      name: newLead.name,
      repName: newLead.repName,
      phone: newLead.phone,
      email: newLead.email,
      status: status,
      comments: newLead.comments,
      lastContacted: newLead.lastContacted,
      salesRep: newLead.salesRep,
      tenantId: tenantId
     })
    .then(res => {
      console.log("Successfully added pending items to database")
      setLeads(res.data);
      setNewLead(prevState => ({
          ...prevState,
          name: "",
          repName: "",
          phone: "",
          email: "",
          comments: "",
          lastContacted: "",
          salesRep: ""
      }));
    })
    .catch(err => console.log(err));
  };

  const leadDelete = (event) => {
    event.preventDefault();
    console.log(event.target.name)
    axios.put('/leads-tracking', {
      id: event.target.name,
    })
    .then(res => {
      console.log("Succesfully deleted customer requirement")
      setLeads(res.data);
      // window.location.reload();
    })
    .catch(err => console.log(err));
  };


  const leadsHeader = (
    <>
      <thead>
        <tr>
          <th scope="col" data-sortable='true'> No. </th>
          <th scope="col" data-sortable='true'> Customer Name </th>
          <th scope="col" data-sortable='true'> Customer Rep </th>
          <th scope="col" data-sortable='true'> Rep Phone No. </th>
          <th scope="col" data-sortable='true'> Rep Email ID </th>
          <th scope="col" data-sortable='true'> Last Contacted (DD/MMM/YY)</th>
          <th scope="col" data-sortable='true'> Comments </th>
          <th scope="col" data-sortable='true'> Sales Rep </th>
          <th scope="col" data-sortable='true'> Status </th>
          <th scope="col" data-sortable='true'> Update </th>
          <th className = {user.name==="umamaheshwar@stalksnspice.com" ? "" : "hidden" } scope="col" data-sortable='true'> Delete </th>
        </tr>
      </thead>
    </>
  )

  const leadsDisplay = (
    <div className="rowcontainer space-top">
      <div className="col-md-12 col-xl-12 col-sm-12 col-xs-12 b2corders">
        <table className='table'>
          {leadsHeader}
          <tbody>
            <tr>
              <td className="section-hide"></td>
              <td>
                <label><input  className="tracking-input" name="name" value={newLead.name || ""} onChange={handleLeads} /> </label>
              </td>
              <td>
                <label><input  className="tracking-input" name="repName" value={newLead.repName || ""}  onChange={handleLeads}/> </label>
              </td>
              <td>
                <label><input  className="tracking-input" name="phone" value={newLead.phone || ""}  onChange={handleLeads}/> </label>
              </td>
              <td>
                <label><input  className="tracking-input" name="email" value={newLead.email || ""}  onChange={handleLeads}/> </label>
              </td>
              <td>
                <label><input  className="tracking-input" name="lastContacted" value={newLead.lastContacted || ""}  onChange={handleLeads}/> </label>
              </td>
              <td>
                <label><input  className="tracking-input" name="comments" value={newLead.comments || ""}  onChange={handleLeads}/> </label>
              </td>
              <td>
                <label><input  className="tracking-input" name="salesRep" value={newLead.salesRep || ""}  onChange={handleLeads}/> </label>
              </td>
              <td>
                <label>
                  <span> Prospect </span>
                </label>
              </td>
              <td>
                <div className = "iuo">
                  <input className = "btn btn-large btn-primary" type='submit' value='&#10004;' onClick={leadsUpdate}  disabled={disabled} />
                </div>
              </td>
            </tr>
            {leads.map((lead, i) => (
              <Fragment key={lead._id}>
                <tr className={`${(moment(new Date()).isAfter(moment(lead.lastContacted).add(30, 'days').calendar()) && lead.status !== "Closed") ? "row-space date-crossed" : lead.status === "Closed" ? "row-space payment-made" : "row-space"}`}>
                  <td> {i+1} </td>
                  <td>
                    <span className={isEditing && editRow ===lead._id ? "hidden" : ""}> {lead.name} </span>
                    <label> <input className={isEditing && editRow ===lead._id ? "tracking-input" : "hidden"} type="text" name="name" value={editLead.name} onChange={handleEditLeads}/> </label>
                  </td>
                  <td>
                    <span className={isEditing && editRow ===lead._id ? "hidden" : ""}> {lead.repName} </span>
                    <label><input  className={isEditing && editRow ===lead._id ? "tracking-input" : "hidden"} type="text" name="repName" value={editLead.repName || ""} onChange={handleEditLeads} /> </label>
                  </td>
                  <td>
                    <span className={isEditing && editRow ===lead._id ? "hidden" : ""}> {lead.phone} </span>
                    <label><input  className={isEditing && editRow ===lead._id ? "tracking-input" : "hidden"} type="text" name="phone" value={editLead.phone || ""} onChange={handleEditLeads} /> </label>
                  </td>
                  <td>
                    <span className={isEditing && editRow ===lead._id ? "hidden" : ""}> {lead.email} </span>
                    <label><input  className={isEditing && editRow ===lead._id ? "tracking-input" : "hidden"} type="text" name="email" value={editLead.email || ""} onChange={handleEditLeads} /> </label>
                  </td>
                  <td>
                    <span className={isEditing && editRow ===lead._id ? "hidden" : ""}> {moment(lead.lastContacted).format("DD-MMM-YY")} </span>
                    <label><input  className={isEditing && editRow ===lead._id ? "tracking-input" : "hidden"} type="text" name="lastContacted" value={editLead.lastContacted || ""} onChange={handleEditLeads} /> </label>
                  </td>
                  <td>
                    <span className={isEditing && editRow ===lead._id ? "hidden" : ""}> {lead.comments} </span>
                    <label><input  className={isEditing && editRow ===lead._id ? "tracking-input" : "hidden"} type="text" name="comments" value={editLead.comments || ""} onChange={handleEditLeads} /> </label>
                  </td>
                  <td>
                    <span className={isEditing && editRow ===lead._id ? "hidden" : ""}> {lead.salesRep} </span>
                    <label><input  className={isEditing && editRow ===lead._id ? "tracking-input" : "hidden"} type="text" name="salesRep" value={editLead.salesRep || ""} onChange={handleEditLeads} /> </label>
                  </td>
                  <td>
                    <span className={isEditing && editRow ===lead._id ? "hidden" : ""}> {lead.status} </span>
                    <select className={isEditing && editRow ===lead._id ?'form-control' : "hidden"} type="text" onChange={handleEditLeads} name="status">
                      <option value='Prospect'> Prospect </option>
                      <option value='Contacted'> Contacted </option>
                      <option value='Quote Sent'> Quote Sent </option>
                      <option value='Negotiating'> Negotiating </option>
                      <option value='Closed'> Closed </option>
                      <option value='No Sale'> No Sale </option>
                    </select>
                  </td>
                  <td>
                    <div className = "iuo">
                      <input className = {isEditing && editRow ===lead._id ? "hidden" : "btn btn-large btn-primary"} type='submit' value='&#x270D;' name={i} onClick={editRowFunc} />
                      <input className = {isEditing && editRow ===lead._id ? "btn btn-large btn-primary" : "hidden" } type='submit' value='&#10004;' name={lead._id} onClick={updateLead} />
                      <input className = {isEditing && editRow ===lead._id?  "btn btn-large btn-primary" : "hidden" } type='submit' value='X' name={lead._id} onClick={(e) => setEditing(false)} />
                    </div>
                  </td>
                  <td>
                    <div className = {user.name==="umamaheshwar@stalksnspice.com" ? "iou" : "hidden" }>
                      <input className = {user.name==="umamaheshwar@stalksnspice.com" ? "btn btn-large btn-primary" : "hidden" } type='submit' value='X' name={lead._id} onClick={leadDelete} />
                    </div>
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )

  return (
    <div className="container-fluid">
      <div className="content-wrapper">
        <header>
          <div className="header-align">
            <h3 className="header">Leads Management</h3>
          </div>
        </header>
        <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12 dashboard">
        </div>
        <div className="space-top">
          <div>
            {leadsDisplay}
          </div>
        </div>
      </div>
    </div>
  );
}


export default LeadsTracking;
