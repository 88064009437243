import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios'
import {Modal} from 'react-bootstrap'
import { useAuth0 } from "@auth0/auth0-react";

import { CSVLink } from "react-csv";
import moment from 'moment';


function QuickShip (props) {
  const [backendIdentifier] = useState("quickShip");
  const [dataView, setDataView] = useState(false);
  const [channel, setChannel] = useState("");
  const [courier, setCourier] = useState("")
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadResult, setUploadResult] = useState([])
  const [ecwidOrderId, setEcwidOrderId] = useState("");
  const [modalHeader, setModalHeader] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [disabled, setDisabled] = useState(0);
  const [show, setShow] = useState(false);
  const [tenantId, setTenantId] = useState("")
  const { loading, user } = useAuth0();

  useEffect(() => {
    if(user) {
      setDataView(true)
    }
    setTenantId(user.tenantId)
    if(dataView) {
      axios.get('/dashboard/upload-result?tenantId=' + tenantId)
      .then(res => {
        setUploadResult(res.data)
      })
      .catch(err => console.log(err));

    }
  }, [dataView]);

  const prevUploadStatusMsgRef = useRef();

  const uploadCsv = (e) => {
    e.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0);
   }.bind(this),4000);
    const data = new FormData();
    data.append('file', selectedFile);
    data.append('channel', channel)
    data.append('tenantId', tenantId)
    console.log(data);
    axios.post('/bulktask/bulkupload-page', data)
    .then(res => {
      console.log("Shipment Created Successfully")
      setUploadResult(res.data)
    })
    .catch(err => {
      if (err.response) {
        console.log(err.response.data);
      }
    })
  }

  const amazonToZohoFunc = (e) => {
    e.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0);
   }.bind(this),10000);
    const data = new FormData();
    data.append('file', selectedFile);
    console.log(data);
    axios.post('/dashboard/amazon-zoho-mtr?tenantId=' + tenantId, data)
    .then(res => {
      console.log("Orders Sent To Backend");
      console.log(res.data);
      const blob = new Blob([res.data], {type: 'csv'})
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `ZohoFormant.csv`
      link.click()
      window.location.reload();
      // setUploadResult(res.data)
    })
    .catch(err => {
      if (err.response) {
        console.log(err.response.data);
      }
    })
  }

  const bulkPrintLabel = (e) => {
    e.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0);
   }.bind(this),10000);
    const data = new FormData();
    data.append('file', selectedFile);
    console.log(data);
    axios.post('/dashboard/print-label-bulk', data
    , {
     responseType: 'arraybuffer',
     headers: {
       'Accept': 'application/pdf'
     }
    })
    .then(res => {
      console.log(res)
      const blob = new Blob([res.data], {type: 'application/pdf'})
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `labelsbulk.pdf`;
      link.click()
    })
    .catch(err => {
      if (err.response) {
        console.log(err.response.data);
      }
    })
  }



  const ecwidToZohoFunc = (e) => {
    e.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0);
   }.bind(this),10000);
    const data = new FormData();
    data.append('file', selectedFile);
    console.log(data);
    axios.post('/dashboard/ecwid-zoho?tenantId=' + tenantId, data)
    .then(res => {
      console.log("Orders Sent To Backend");
      console.log(res.data);
      const blob = new Blob([res.data], {type: 'text/plain'})
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `ZohoFormantEcwid.csv`
      link.click()
      window.location.reload();
      // setUploadResult(res.data)
    })
    .catch(err => {
      if (err.response) {
        console.log(err.response.data);
      }
    })
  }

  const amazonToXpressbeesUploadFunc = (e) => {
    e.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0);
   }.bind(this),10000);
    const data = new FormData();
    data.append('file', selectedFile);
    console.log(data);
    axios.post('/dashboard/amazon-xpressbees?tenantId=' + tenantId, data)
    .then(res => {
      console.log("Orders Sent To Backend");
      console.log(res.data);
      const blob = new Blob([res.data], {type: 'text/plain'})
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `XpressbeesFormat.csv`
      link.click()
      window.location.reload();
      // setUploadResult(res.data)
    })
    .catch(err => {
      if (err.response) {
        console.log(err.response.data);
      }
    })
  }

  const flipkartToTallyFunc = (e) => {
    e.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0);
   }.bind(this),10000);
    const data = new FormData();
    data.append('file', selectedFile);
    console.log(data);
    axios.post('/flipkart/flipkart-tally?tenantId=' + tenantId, data)
    .then(res => {
      console.log("Orders Sent To Backend");
      console.log(res.data);
      const blob = new Blob([res.data], {type: 'text/plain'})
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `ZohoFormant.csv`
      link.click()
      window.location.reload();
      // setUploadResult(res.data)
    })
    .catch(err => {
      if (err.response) {
        console.log(err.response.data);
      }
    })
  }

  const amazonMTRToTallyFunc = (e) => {
    e.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0);
   }.bind(this),10000);
    const data = new FormData();
    data.append('file', selectedFile);
    console.log(data);
    axios.post('/dashboard/tally-amazon-payments?tenantId=' + tenantId, data)
    .then(res => {
      console.log("Orders Sent To Backend");
      console.log(res.data);
      const blob = new Blob([res.data], {type: 'text/xml'})
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `TallyFormat.xml`
      link.click()
      window.location.reload();
      // setUploadResult(res.data)
    })
    .catch(err => {
      if (err.response) {
        console.log(err.response.data);
      }
    })
  }

  const csvToTallyFuncPayments = (e) => {
    e.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0);
   }.bind(this),10000);
    const data = new FormData();
    data.append('file', selectedFile);
    console.log(data);
    axios.post('/dashboard/tally-payments-xml?tenantId=' + tenantId, data)
    .then(res => {
      console.log("Orders Sent To Backend");
      console.log(res.data);
      const blob = new Blob([res.data], {type: 'text/xml'})
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `TallyFormat.xml`
      link.click()
      window.location.reload();
      // setUploadResult(res.data)
    })
    .catch(err => {
      if (err.response) {
        console.log(err.response.data);
      }
    })
  }

  const exportInvoice = (e) => {
    e.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0);
   }.bind(this),10000);
    console.log('exportPdf being called')
    axios.get('/dashboard/export-invoice',{
       responseType: 'arraybuffer',
       headers: {
         'Accept': 'application/pdf'
       }
    })
    .then(res => {
      console.log(res)
      const blob = new Blob([res.data], {type: 'application/pdf'})
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `Invoice`
      link.click()
    })
    .catch(err => console.log(err.response));
  }

  const syncFlipkart = (e) => {
    e.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0);
   }.bind(this),10000);
    axios.get('/flipkart/get-orders?tenantId' + tenantId)
    .then(res => {
      console.log("Orders Sync Started")
      setModalBody("Flipkart Orders Synced Successsfully");
      setModalHeader("Flipkart Order Sync");
      setShow(true)
      // setUploadResult(res.data)
    })
    .catch(err => {
      if (err.response) {
        console.log(err.response.data);
      }
    })
  }

  const uploadTrackingCsv = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('file', selectedFile);
    data.append('courier', courier)
    console.log(data);
    axios.post('/bulktask/bulkupdate-tracking', data)
    .then(res => {
      console.log("Shipment Updated Successfully")
      console.log(res.data);
      const blob = new Blob([JSON.stringify(res.data)], {type: 'text/plain'})
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `TrackingUpdateResponse`
      link.click()
    })
    .catch(err => {
      if (err.response) {
        console.log(err.response.data);
      }
    })
  }

  const addEcwidOrder = (e) => {
    axios.post('/dashboard/ecwid-updates', {
      entityId: ecwidOrderId.replace(/[^0-9\.]+/g, ""),
      eventType: "order.created",
      tenantId: tenantId
    })
    .then(res => {
      console.log("Shipment Created Successfully")
      setModalBody("Ecwid Order added to Orders Page & to Shiprocket & Shipease Panel Successsfully");
      setModalHeader("Eciwd Order Upload");
      setShow(true)
      setEcwidOrderId("")
      // window.location.reload();
    })
    .catch(err => console.log(err))

  }

  const addShipment = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    console.log("data" + data.get('phone'))
    axios.post('/orders?tenantId=' + tenantId, {
      orderId: data.get('orderId'),
      name: data.get('name'),
      phone: data.get('phone'),
      street: data.get('street'),
      city: data.get('city'),
      state: data.get('state'),
      zip: data.get('zip'),
      orderTotal: data.get('orderTotal'),
      products: data.get('products'),
      qty: data.get('qty'),
      backendIdentifier: backendIdentifier,
      user: user.name,
    })
    .then(res => {
      console.log("Shipment Created Successfully")
      window.location.reload();
    })
    .catch(err => console.log(err))
  }


  const updateOrder = (
    <>
      <hr className="hr-large d-none d-md-block"/>
      <div className="bulk-update section-hide align-upload">
        <select className='form-edit-order-create' type="text" onChange={e => setChannel(e.target.value)} name="locality" required>
          <option value=''> -- </option>
          <option value='HORECA'> HORECA </option>
          <option value='Amazon'> Amazon </option>
        </select>
        <input type='file' onChange={(e) => setSelectedFile(e.target.files[0]) } accept=".csv, .txt, text/xml"/>
        <input className="btn bulk-update-button btn-large" onClick={uploadCsv} type='submit' value='UPLOAD' disabled={channel === "" || disabled} />
      </div>
    </>
  )

  const updateTracking = (
    <>
      <hr className="hr-large d-none d-md-block"/>
      <h4> UPDATE TRACKING INFORMATION </h4>
      <div className="bulk-update section-hide align-upload">
        <select className='form-edit-order-create' type="text" onChange={e => setCourier(e.target.value)} name="courier" required>
          <option value=''> -- </option>
          <option value='Professional'> Professional </option>
          <option value='Shiprocket'> Shiprocket </option>
          <option value='Pickrr'> Pickrr </option>
          <option value='Shipease'> Shipease </option>
        </select>
        <input type='file' onChange={(e) => setSelectedFile(e.target.files[0]) } accept=".csv"/>
        <input className="btn bulk-update-button btn-large" onClick={uploadTrackingCsv} type='submit' value='UPLOAD' disabled={courier === ""}/>
      </div>
      <div>
        <CSVLink  className="btn btn-large btn-primary bulk-update-order-button" data={[["TrackingId", "Status", "DeliveryDate"]]} >Professional Update Format</CSVLink>
        <CSVLink  className="btn btn-large btn-primary bulk-update-order-button" data={[["Status", "Order ID", "Order Delivered Date", "AWB Code"]]} >Shiprocket Update Format</CSVLink>
      </div>
    </>
  )

  const amazonToZoho = (
    <>
      <hr className="hr-large d-none d-md-block"/>
      <h4> AMAZON MTR TO ZOHO INVOICE </h4>
      <div className="bulk-update section-hide align-upload">
        <input type='file' onChange={(e) => setSelectedFile(e.target.files[0]) } accept=".csv, .txt"/>
        <input className="btn bulk-update-button btn-large" onClick={amazonToZohoFunc} type='submit' value='UPLOAD'/>
      </div>
    </>
  )

  const ecwidToZoho = (
    <>
      <hr className="hr-large d-none d-md-block"/>
      <h4> ECWID INVOICE EXPORT TO ZOHO INVOICE </h4>
      <div className="bulk-update section-hide align-upload">
        <input type='file' onChange={(e) => setSelectedFile(e.target.files[0]) } accept=".csv, .txt"/>
        <input className="btn bulk-update-button btn-large" onClick={ecwidToZohoFunc} type='submit' value='UPLOAD'/>
      </div>
    </>
  )

  const csvToTally = (
    <>
      <hr className="hr-large d-none d-md-block"/>
      <h4> AMAZON PAYMENTS UPDATE VIA MTR FILE UPLOAD </h4>
      <div className="bulk-update section-hide align-upload">
        <input type='file' onChange={(e) => setSelectedFile(e.target.files[0]) } accept=".csv, .txt"/>
        <input className="btn bulk-update-button btn-large" onClick={amazonMTRToTallyFunc} type='submit' value='UPLOAD'/>
      </div>
    </>
  )

  const amazonMTRToTally = (
    <>
      <hr className="hr-large d-none d-md-block"/>
      <h4> CSV TO TALLY PAYMENT UPLOAD XML </h4>
      <div className="bulk-update section-hide align-upload">
        <input type='file' onChange={(e) => setSelectedFile(e.target.files[0]) } accept=".csv, .txt"/>
        <input className="btn bulk-update-button btn-large" onClick={csvToTallyFuncPayments} type='submit' value='UPLOAD'/>
      </div>
    </>
  )

  const amazonToXpressbeesUpload = (
    <>
      <hr className="hr-large d-none d-md-block"/>
      <h4> Amazon To Xpressbees Upload </h4>
      <div className="bulk-update section-hide align-upload">
        <input type='file' onChange={(e) => setSelectedFile(e.target.files[0]) } accept=".csv, .txt"/>
        <input className="btn bulk-update-button btn-large" onClick={amazonToXpressbeesUploadFunc} type='submit' value='UPLOAD'/>
      </div>
    </>
  )

  const flipkartToTally = (
    <>
      <hr className="hr-large d-none d-md-block"/>
      <h4> FLIPKART SALES TO TALLY XML </h4>
      <div className="bulk-update section-hide align-upload">
        <input type='file' onChange={(e) => setSelectedFile(e.target.files[0]) } accept=".csv, .txt"/>
        <input className="btn bulk-update-button btn-large" onClick={flipkartToTallyFunc} type='submit' value='UPLOAD'/>
      </div>
    </>
  )

  const syncFlipkartOrders = (
    <>
      <hr className="hr-large d-none d-md-block"/>
      <h4> SYNC FLIPKART ORDERS </h4>
      <div className="bulk-update section-hide align-upload">
        <input className="btn bulk-update-button btn-large" onClick={syncFlipkart} type='submit' value='SYNC FLIPKART ORDERS'/>
      </div>
    </>
  )

  const exportInvoiceSection = (
    <>
      <hr className="hr-large d-none d-md-block"/>
      <h4> Export Invoice </h4>
      <div className="bulk-update section-hide align-upload">
          <input className="btn bulk-update-button btn-large" onClick={exportInvoice} type='submit' value='EXPORT INVOICE'/>
      </div>
    </>
  )

  const bulkPrintLabelSection = (
    <>
      <hr className="hr-large d-none d-md-block"/>
      <h4> BULK PRINT LABEL </h4>
      <div className="bulk-update section-hide align-upload">
        <input type='file' onChange={(e) => setSelectedFile(e.target.files[0]) } accept=".csv, .txt"/>
        <input className="btn bulk-update-button btn-large" onClick={bulkPrintLabel} type='submit' value='UPLOAD'/>
      </div>
    </>
  )

  const createShipment = (
    <>
    <hr className="hr-large d-none d-md-block"/>
    <div className="rowcontainer">
      <div className="thumbnail center well well-sm text-center text-center space-top">
        <form className="needs-validation" onSubmit={addShipment} method='POST'>
          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 dashboard">
            <input className='form-control' type='text' name='orderId'/>
            <label htmlFor="orderId"> Order ID </label>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 dashboard">
            <input className='form-control' type='text' name='name'/>
            <label htmlFor="name"> Customer Name </label>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 dashboard">
            <input className='form-control' type='text' name='phone'/>
            <label htmlFor="phone"> Customer Phone </label>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 dashboard">
            <input className='form-control' type='text' name='street'/>
            <label htmlFor="street"> Street Address</label>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 dashboard">
            <input className='form-control' type='text' name='city'/>
            <label htmlFor="city"> City </label>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 dashboard">
            <input className='form-control' type='text' name='state'/>
            <label htmlFor="state"> State </label>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 dashboard">
            <input className='form-control' type='text' name='zip'/>
            <label htmlFor="zip"> Zip Code</label>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 dashboard">
            <input className='form-control' type='text' name='orderTotal'/>
            <label htmlFor="orderTotal"> Order Total</label>
          </div>
          <div className="align-center">
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 dashboard">
              <input className='form-control' type='text' name='products'/>
              <label htmlFor="products"> Product </label>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 dashboard">
              <input className='form-control' type='text' name='qty'/>
              <label htmlFor="qty"> Qty </label>
            </div>
          </div>
          <div>
            <input className = "btn btn-large btn-primary" type='submit' value='CREATE SHIPMENT' />
          </div>
        </form>
      </div>
    </div>
    </>
  )

  const uploadResultHeader = (
    <>
      <thead>
        <tr>
          <th className="section-hide"> No. </th>
          <th className="section-hide"> Date </th>
          <th className="section-hide"> Category </th>
          <th scope="col" data-sortable='true'> description </th>
          <th scope="col" data-sortable='true'> Upload Results </th>
        </tr>
      </thead>
    </>
  )

  const uploadResultDisplay = (
    <div className="col-md-12 col-xl-12 col-sm-12 col-xs-12 b2corders">
      <table className='table'>
        {uploadResultHeader}
        <tbody>
          {uploadResult.map((result, i) => (
            <tr key={result._id} className="row-space">
              <td className="section-hide"> {i+1} </td>
              <td className="section-hide"> {result.date ? moment(result.date).format("DD-MMM-YY h:mm:ss") : ""} </td>
              <td className="section-hide"> {result.category} </td>
              <td className="section-hide"> {result.description} </td>
              <td> <a href={result.fileUrl}>Shipping Confirmation File</a> </td>
            </tr>
          ))}
          </tbody>
        </table>
    </div>
  )

  const handleClose = () => {
    setShow(false)
  }



  return (
    <div className="container-fluid">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title> {modalHeader}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalBody}</Modal.Body>
      </Modal>
      <div className="content-wrapper">
        <header>
          <div>
            <h1> ADD ORDERS </h1>
          </div>
        </header>
        {updateOrder}
        {createShipment}
      </div>
      <hr className="hr-large d-none d-md-block"/>
      <div>
        <h4> ADD ECWID ORDER & UPLOAD TO SHIPROCKET & SHIPEASE PANEL </h4>
        <span>
          <label><input  className="ecwid-order-input" name="ecwidOrderId" value={ecwidOrderId} onChange={e => setEcwidOrderId(e.target.value)}/> Enter ECWID Order ID </label>
        </span>
      </div>
      <div>
        <div>
          <input className="btn bulk-update-button btn-large" onClick={addEcwidOrder} type='submit' value='UPLOAD' disabled={ecwidOrderId === ""} />
        </div>
      </div>
      <div>
        {amazonToZoho}
      </div>
      <div>
        {ecwidToZoho}
      </div>
      <div>
        {flipkartToTally}
      </div>
      <div>
        {amazonMTRToTally}
      </div>
      <div>
        {csvToTally}
      </div>
      <div>
        {syncFlipkartOrders}
      </div>
      <div>
        {exportInvoiceSection}
      </div>
      <div>
        {bulkPrintLabelSection}
      </div>
      {
        tenantId !== "stalksnspice"
        ? null
        : (
          <div>
            {updateTracking}
          </div>
        )
      }
      <div >
        <hr className="hr-large d-none d-md-block"/>
        <h4> UPLOAD STATUS </h4>
        {uploadResultDisplay}
      </div>
    </div>
  );
}


// <div className={prevUploadStatusMsg === "" ? "" : "section-hide"}>
//   <hr className="hr-large d-none d-md-block"/>
//   <h4> UPLOAD STATUS </h4>
//   // <p> {prevUploadStatusMsg} </p>
// </div>

export default QuickShip
