export const FULFILLMENTOPTIONS =
[
  {
    id: 0,
    name: "All Orders"
  },
  {
    id: 1,
    name: "Invoiced"
  },
  {
    id: 2,
    name: "Order Created"
  },
  {
    id: 3,
    name: "Marked For Delivery"
  },
  {
    id: 4,
    name: "Shipped"
  },
  {
    id: 5,
    name: "In Transit"
  },
  {
    id: 6,
    name: "Delivered"
  },
  {
    id: 7,
    name: "Damaged in Transit"
  },
  {
    id: 8,
    name: "Delivery Exception"
  },
  {
    id: 9,
    name: "RTO"
  },
  {
    id: 10,
    name: "Buyer Cancelled"
  },
  {
    id: 11,
    name: "Damaged in Transit"
  },
  {
    id: 12,
    name: "Customer Phone Unreachable"
  },
]
