import React, { Component } from 'react'
import { Link } from 'react-router-dom';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeMap: "https://www.google.co.in/maps/d/embed?mid=1ZF8vNyjFS8imX8vu1abKTzraUXfA8eLD"
    };
    this.routeSelect = this.routeSelect.bind(this)
  }

  routeSelect = async(e) => {
    e.preventDefault();
    console.log(e.target.value)
    if (e.target.value === 'Route 1') {
      this.setState ({
        routeMap: "https://www.google.co.in/maps/d/embed?mid=1ZF8vNyjFS8imX8vu1abKTzraUXfA8eLD"
      })
    } else if (e.target.value === 'Route 2') {
      this.setState ({
        routeMap: "https://www.google.com/maps/d/embed?mid=1gkGd24JEk3kiTSHjQ1AC_F76eCE"
      })
    } else if (e.target.value === 'Route 3') {
      this.setState ({
        routeMap: "https://www.google.co.in/maps/d/embed?mid=1ZF8vNyjFS8imX8vu1abKTzraUXfA8eLD"
      })
    }
  }

    render() {
      const routeSelect = (
        <>
          <Link className="nav-link" to={"/routes/1"}> Route 1 </Link>
          <Link className="nav-link" to={"/routes/2"}> Route 2 </Link>
          <Link className="nav-link" to={"/routes/3"}> Route 3 </Link>
          <Link className="nav-link" to={"/routes/4"}> Route 4 </Link>
          <Link className="nav-link" to={"/routes/5"}> Route 5 </Link>
        </>
      )
        return (
          <div className="content-wrapper">
            <div className="route-map-view space-top-extra">
              <h1 className="space-top-extra"> ROUTES </h1>
              {routeSelect}
            </div>
          </div>
        )
    }
}

export default Home

// const routeMapSelect = (
//   <>
//     <input className="btn btn-large btn-primary bulk-update-order-button" onClick={this.routeSelect} type='submit' value='Route 1'/>
//     <input className="btn btn-large btn-primary bulk-update-order-button" onClick={this.routeSelect} type='submit' value='Route 2'/>
//     <input className="btn btn-large btn-primary bulk-update-order-button" onClick={this.routeSelect} type='submit' value='Route 3'/>
//   </>
// )
// <iframe src={this.state.routeMap} className="map-size"></iframe>
