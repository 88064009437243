import React, { useState, useEffect, Fragment } from 'react';
import {Pagination, Modal} from 'react-bootstrap'
import axios from 'axios';
import moment from 'moment';
import { Link, useLocation, withRouter, useNavigate } from 'react-router-dom';
import { CSVLink } from "react-csv";
import queryString from 'query-string';
import Switch from "../components/Switch.js";
import '../css/Switch.css';
import { useAuth0 } from "@auth0/auth0-react";

function Products (props) {
  const [productQty, productQtyUpdate] = useState("");
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [products, setProducts] = useState([]);
  const [limit] = useState("");
  const [prevPage, setPrevPage] = useState("");
  const [nextPage, setNextPage] = useState("")
  const [length, setLength] = useState(0)
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProducts, updateSelectedProducts] = useState([]);
  const [productsToUpdate, setProductsToUpdate] = useState([])
  const [stockStatus, setStockStatus] = useState("")
  const [productStatus, setProductStatus] = useState("")
  const [modalTitle, setModalTitle] = useState("")
  const [modalBody, setModalBody] = useState("")
  const location = useLocation();
  const { loading, user, tenantId } = useAuth0();
  const navigate = useNavigate();


  useEffect(() => {
    var queryValues = queryString.parse(props.location.search)
    setPage(parseInt(queryValues.page))
    console.log(user)
    if(queryValues.page === 1) {
      setPrevPage(1)
    } else {
      setPrevPage(queryValues.page-1)
    }
    if(queryValues.page === 9) {
      setNextPage(9)
    } else {
      setNextPage(queryValues.page+1)
    }
    setLength(Math.ceil(678/limit))
    // setPageNos(new Array(Math.ceil(678/limit)));
    axios.get('/products?&limit=' + limit + '&page=' + queryValues.page + '&productStatus=' + queryValues.productStatus + '&stockStatus=' + queryValues.stockStatus + '&searchTerm=' + queryValues.searchTerm + '&tenantId=' + tenantId)
    .then(res => {
      setProducts(res.data)
    })
    .catch(err => console.log(err));

  }, [location]);

  const searchQuery = (e) => {
    props.navigate('/products?&limit=' + limit + '&page=' + page + '&productStatus=' + productStatus + '&stockStatus=' + stockStatus + '&searchTerm=' + searchTerm);
  }

  const searchOnEnter = (e) => {
    if(e.which === 13) {
      searchQuery(e);
    }
  }

  const productExport = () => {
    setModalTitle("Products Exported")
    setModalBody("Products are succesfully exported! Visit MIS Reports Page to download the report")

    axios.get('/products/export?tenantId=' + tenantId)
    .then(res => {
      // console.log(res)
      setShow(true)
    })
    .catch(err => console.log(err.response));

  }

  const productAnalysisExport = () => {
    setModalTitle("Products Analysis Exported")
    setModalBody("Products Analsyis are succesfully exported! Visit MIS Reports Page to download the report")

    axios.get('/products/analysis-export?tenantId=' + tenantId)
    .then(res => {
      // console.log(res)
      setShow(true)

    })
    .catch(err => console.log(err.response));

  }

  const syncAmazonInventory = () => {
    setShow(true)
    setModalTitle("Amazon Sync Success")
    setModalBody("Amazon Products Have Been Succesfully Synced! It'll take 5-10 minutes to succesfully update amazon inventory.")

    axios.get('dashboard/amazon-inventory-sync?tenantId' + tenantId, {
     responseType: 'arraybuffer',
     headers: {
       'Accept': 'csv'
     }
    })
    .then(res => {
      console.log("Succesfully updated stock");
      // window.location.reload();

    })
    .catch(err => console.log(err));
  }

  const handleClose = () => {
    setShow(false)
  }

  const updateProduct = (e, productId, amazonSku) => {
    console.log(productQty)
    console.log(e.target.name)
    axios.put(`/products/${e.target.name}`, {
      qty: productQty,
      id: productId,
      amazonSku: amazonSku,
    })
    .then(res => {
      console.log("Succesfully updated stock");
      // window.location.reload();
    })
    .catch(err => console.log(err));
  }

  const updateStatus = (e, status, i) => {
    e.preventDefault();
    var status = !status
    axios.put(`/products/${e.target.value}`, {
      status: status,
    })
    .then(res => {
      console.log("Succesfully updated stock");
      products[i] = res.data
      var newProductsArray = [...products]
      console.log(newProductsArray[i])
      setProducts(newProductsArray)
      // window.location.reload();
    })
    .catch(err => console.log(err));
  }

  const deleteProduct = (e) => {
    e.preventDefault();
    var idToDelete = e.target.name
    axios.put('/products', {
      id: e.target.name,
      stockStatus: stockStatus
    })
    .then(res => {
      console.log("Succesfully deleted customer requirement")
      var index = products.map(function(i) { return i._id; }).indexOf(idToDelete);
      console.log(index)
      if (index !== -1) {
        products.splice(index, 1);
        setProducts([...products])
      }
    })
    .catch(err => console.log(err));
  }

  const updateVariationQty = (e, productId, variationId, amazonSku) => {
    console.log(variationId)
    axios.put(`/products/${e.target.name}`, {
      qty: productQty,
      id: productId,
      variationId: variationId,
      amazonSku: amazonSku
    })
    .then(res => {
      // window.location.reload();
    })
    .catch(err => console.log(err));
  }

  const selectedProductsUpdate = (e) =>  {
    console.log(e.target.value)
    if(e.target.checked) {
      updateSelectedProducts(selectedProducts.concat(e.target.value))
      var productsToPush = []
      products.map((product) => {
        productsToPush = productsToPush.concat(products.producSku)
      })
      if (productsToPush.length === selectedProducts.length) {
        var checkbox = document.getElementById('selectAllCheckbox');
        checkbox.checked = true
      }
    } else {
      var updatedSelectedProducts = selectedProducts.filter(filterEvent => filterEvent !== e.target.value)
      updateSelectedProducts(updatedSelectedProducts)
      var checkbox = document.getElementById('selectAllCheckbox');
      if (checkbox.checked === true) {
        checkbox.checked = false
      }
    }
  }

  const handleOnSelectAll = (e) => {
    var productsToPush = []
    if(e.target.checked) {
      products.map((product) => {
        productsToPush = productsToPush.concat(product.productSku)
      })
      var checkboxes = document.getElementsByName('selectedProducts');
      for (var checkbox of checkboxes) {
        checkbox.checked = true;
      }
      updateSelectedProducts(productsToPush)
    } else {
      var checkboxes = document.getElementsByName('selectedProducts');
      for (var checkbox of checkboxes) {
        checkbox.checked = false;
      }
      updateSelectedProducts(productsToPush)
    }
  }

  const stockStatusUpdate = (value) => {
    setStockStatus(value)
    navigate('/products?&limit=' + 100 + '&page=' + page + '&productStatus=' + productStatus + '&stockStatus=' + value + '&searchTerm=' + searchTerm);
  }

  const productStatusUpdate = (value) => {
    setProductStatus(value)
    navigate('/products?&limit=' + 100 + '&page=' + page + '&productStatus=' + value + '&stockStatus=' + stockStatus + '&searchTerm=' + searchTerm);
  }


  const filterProductPage = (
    <div className="bulk-update">
      <div className='input-group input-group-modifier small-screen-hide'>
        <div className="bulk-update-select small-screen-hide">
          <select className='form-control' type="text" onChange={e => productStatusUpdate(e.target.value)} name="stockStatus">
            <option value=''> All Products </option>
            <option value='enabled'> Enabled </option>
            <option value='disabled'> Disabled </option>
          </select>
        </div>
        <div className="bulk-update-select small-screen-hide">
          <select className='form-control' type="text" onChange={e => stockStatusUpdate(e.target.value)} name="stockStatus">
            <option value=''> All Products </option>
            <option value='instock'> In Stock </option>
            <option value='outofstock'> Out of Stock </option>
            <option value='lowstock'> Low Stock </option>
            <option value='amazonSku'> Amazon SKU's </option>
          </select>
        </div>
        <div className="bulk-update-select small-screen-hide">
          <input className='btn bulk-update-button btn-large' type='submit' onClick={syncAmazonInventory} value='Amazon Inventory Sync'/>
          <input className='btn bulk-update-button btn-large' type='submit' onClick={productAnalysisExport} value='30 Day Sales Report'/>
          <input className='btn bulk-update-button btn-large' type='submit' onClick={productExport} value='Export Products'/>
        </div>
      </div>
      <div className="bulk-update-select">
        <div className='input-group'>
          <span className="input-group-addon"> <i className="fa fa-envelope"></i></span>
          <input className='form-control' type='text' value={searchTerm} onChange={e => setSearchTerm(e.target.value)} onKeyPress={e => searchOnEnter(e)} name='orderId' placeholder='Search Product'/>
          <input className='btn btn-large update-order' type='submit' onClick={(e) => searchQuery(e)} value='SEARCH'/>
        </div>
      </div>
    </div>
  )

  const productHeader = (
    <>
      <thead>
        <tr>
          <th className="section-hide"> No. </th>
          <th className="section-hide"> <input type="checkbox" id="selectAllCheckbox" name="selectAll" onChange={handleOnSelectAll}/></th>
          <th scope="col" data-sortable='true'> Image </th>
          <th scope="col" data-sortable='true'> Product Identifiers </th>
          <th scope="col" data-sortable='true'> Product Name <br/> Tally Name</th>
          <th className="section-hide" scope="col" data-sortable='true'> Warehouse Inventory </th>
          <th className="section-hide" scope="col" data-sortable='true'> Amazon Inventory </th>
          <th className="section-hide" scope="col" data-sortable='true'> Website Inventory </th>
          <th className="section-hide" scope="col" data-sortable='true'> Flipkart Inventory </th>
          <th className="section-hide" scope="col" data-sortable='true'> Safety Stock </th>
          <th className="section-hide" scope="col" data-sortable='true'> Purchase Price </th>
          <th className="section-hide" scope="col" data-sortable='true'> MRP </th>
          <th className="section-hide" scope="col" data-sortable='true'> Status </th>
          <th className="section-hide" scope="col" data-sortable='true'> Save </th>
          <th className={user.name==="umamaheshwar@stalksnspice.com" ? "section-hide" : "hidden" } scope="col" data-sortable='true'> Delete </th>
        </tr>
      </thead>
    </>
  )

  const productsDisplay = (
    <div className="rowcontainer space-top">
      <div className="col-md-12 col-xl-12 col-sm-12 col-xs-12 b2corders">
        <table className='table'>
          {productHeader}
          <tbody>
            {products.map((product, i) => (
              <Fragment key={product.sku}>
                <tr className={`${!product.status ? "row-space list-item-disabled" : product.qty <= product.safetyStock ? "row-space low-stock-warning" : product.qty <= 0 ? "row-space stock-out" : "row-space"}`}>
                  <td className="section-hide"> {i+1} </td>
                  <td className="section-hide"> <input type="checkbox" id="checkbox" name="selectedOrders" onChange={selectedProductsUpdate} value={product.sku}/></td>
                  <td className=""> <a href={product.url} target="_blank"><img className="product-image" src={product.image ? product.image : "https://s3.ap-south-1.amazonaws.com/stalksnspice.com/logos/sns-logo-small-no-background-100px.pngg"}></img> </a></td>
                  <td className="product-id-text"> SKU: <Link to={`/products/${product.sku}`}> {product.sku}</Link> <br/> Amazon SKU: {product.amazonSku} <br/> Flipkart SKU: {product.flipkartSku} <br/> ECWID ProductID: {product.ecwidProductId} </td>
                  <td> <strong>{product.name}</strong> <br/> {product.tallyName}</td>
                  <td>
                    <input className='qty-input' type='text' defaultValue={parseInt(product.qty) ? parseInt(product.qty) : 0} onChange={e => productQtyUpdate(e.target.value)} name={product.sku}/> <br/>
                  </td>
                  <td className="section-hide"> {product.tenantInventory ? product.tenantInventory.amazonQty : 0} </td>
                  <td className="section-hide"> {product.tenantInventory ? product.tenantInventory.websiteQty : 0} </td>
                  <td className="section-hide"> {product.tenantInventory ? product.tenantInventory.flipkartQty : 0} </td>
                  <td className="section-hide"> {product.safetyStock ? product.safetyStock : 0} </td>
                  <td className="section-hide">{product.purchasePrice}</td>
                  <td className="section-hide">{product.mrp}</td>
                  <td className="section-hide">
                  <Switch
                    isOn={product.status ? product.status : 0}
                    handleToggle={(e) => updateStatus(e, product.status, i)}
                    sku={product.sku}/>
                  </td>
                  <td>
                    <div className = "iuo">
                      <input className = "btn btn-large btn-primary" type='submit' value='SAVE' name={product.sku} onClick={(e) => updateProduct(e, product.ecwidProductId, product.amazonSku)} />
                    </div>
                  </td>
                  <td className={user.email==="umamaheshwar@stalksnspice.com" ? "section-hide" : "hidden" } scope="col" data-sortable='true'>
                    <input className = {user.email==="umamaheshwar@stalksnspice.com" ? "btn btn-large btn-primary" : "hidden" } type='submit' value='X' name={product._id} onClick={deleteProduct} />
                  </td>
                </tr>
                {Array.isArray(product.variations) && product.variations.map((variation, i) => (
                  <tr key={variation.sku} className={`${variation.qty <= variation.safetyStock ? "row-space variation low-stock-warning" : variation.qty <= 0 ? "row-space variation stock-out" : "row-space variation"}`}>
                    <td className="section-hide"> {i+1}</td>
                    <td className="section-hide"> <input type="checkbox" id="checkbox" name="selectedOrders" onChange={selectedProductsUpdate} value={variation.sku}/></td>
                    <td className="section-hide"> <a href={product.url} target="_blank"><img className="product-image" src={product.image ? product.image : ""}></img></a></td>
                    <td className="product-id-text"> SKU:<Link to={`/products/${product.sku}`}> {variation.sku} </Link> <br/> Amazon SKU: {variation.amazonSku} <br/> ECWID ID: {variation.variationId}</td>
                    <td> {product.name}, {variation.name}: {variation.value} </td>
                    <td><input className='qty-input' type='text' defaultValue={parseInt(variation.qty) ? parseInt(variation.qty) : 0} onChange={e => productQtyUpdate(e.target.value)} name={product.sku}/></td>
                    <td className="section-hide"> {variation.safetyStock ? variation.safetyStock : 0} </td>
                    <td className="section-hide">{variation.purchasePrice ? variation.purchasePrice : 0}</td>
                    <td className="section-hide">{variation.mrp}</td>
                    <td>
                      <div className = "iuo">
                        <input className = "btn btn-large btn-primary" type='submit' value='SAVE' name={variation.sku} onClick={(e) => updateVariationQty(e, product.ecwidProductId, variation.variationId, variation.amazonSku)} />
                      </div>
                    </td>
                  </tr>
                ))}
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )

  return (
    <div className="container-fluid">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalBody}</Modal.Body>
      </Modal>
      <div className="content-wrapper">
        <header>
          <div className="header-align">
            <h3 className="header">Products</h3>
            {filterProductPage}
          </div>
        </header>
        <div>
          {productsDisplay}
        </div>
      </div>
      <div className="align-upload section-hide">
        <Pagination size="lg">
          <Pagination.First href={"/#/products/?limit=100&page=" + 1  + '&productStatus=' + productStatus + "&stockStatus=" + stockStatus + "&searchTerm=" + searchTerm}/>
          <Pagination.Prev href={'/#/products/?limit=100&page=' + Math.max(page - 1,1)  + '&productStatus=' + productStatus  + "&stockStatus=" + stockStatus + "&searchTerm=" + searchTerm} />
          <Pagination.Next href={'/#/products/?limit=100&page=' + parseInt(page + 1)  + '&productStatus=' + productStatus + "&stockStatus=" + stockStatus + '&searchTerm=' + searchTerm}/>
          <Pagination.Last href={'/#/products/?limit=100&page=' + 10  + '&productStatus=' + productStatus + "&stockStatus=" + stockStatus + '&searchTerm=' + searchTerm}/>
        </Pagination>
      </div>
    </div>
  );
}


export default Products;


// <input
//   checked={product.status ? product.sku : 0}
//   onChange = {(e) => updateStatus(e, product.status)}
//   value={product.sku}
//   className="react-switch-checkbox"
//   id={`${product.sku}`}
//   type="checkbox"
// />
// <label
//   style={{ background: product.status ? '#06D6A0' : "gray" }}
//   className="react-switch-label"
//   htmlFor={`${product.sku}`}
// >
//   <span className={`react-switch-button`} />
// </label>

// <Switch
//   isOn={product.status}
//   handleToggle={(e) => updateStatus(e, product.sku, product.status)}
//   sku={product.sku}
//   />



// {renderRow(order)}
