import React, { useState, useEffect, Fragment } from 'react';
import { CSVLink } from "react-csv";
import axios from 'axios';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";


function PaymentFollowUp (props) {
  const [amountCollected, setAmountCollected] = useState("");
  const [paymentsFollowUp, setPaymentsFollowUp] = useState([]);
  const [newPaymentFollowUp, setNewPaymentFollowUp] = useState({orderDate: "",invoiceNo: "",creditPeriod:"",name:"", customerRep: "", amount: "", amountCollected:"", status:"Pending"})
  const [outstanding, setOutstanding] = useState(0);
  const [disabled, setDisabled] = useState(0);
  const { loading, user, tenantId } = useAuth0();


  useEffect(() => {
    axios.get('/payment-follow-up?tenantId=' + tenantId)
    .then(res => {
      setPaymentsFollowUp(res.data)
      var outstanding = 0;
      var amountCollected = 0;
      outstanding = res.data.reduce(function (sum, dues) {
          return sum + dues.amount;
      }, 0);

      amountCollected = res.data.reduce(function (sum, dues) {
          return sum + dues.amountCollected;
      }, 0);

      console.log(outstanding)
      console.log(amountCollected)
      setOutstanding(Number(outstanding-amountCollected).toLocaleString())
    })
    .catch(err => console.log(err));
  }, []);

  // const searchQuery = (e) => {
  //   axios.post('/dashboard/search-product', {
  //     searchTerm: searchTerm
  //   })
  //   .then(res => {
  //     console.log(res.data)
  //     setProducts(res.data)
  //   })
  //   .catch(err => console.log(err));
  // }
  //
  const updatePayment = (e) => {
    axios.put("/payment-follow-up?tenantId=" + tenantId, {
      amountCollected: amountCollected,
      update: "amountCollected",
      id: e.target.name
    })
    .then(res => {
      console.log("amount collected updated!");
      setPaymentsFollowUp(res.data);
    })
    .catch(err => console.log(err));
  }

  const handlePaymentFollowUp = e => {
    const { name, value } = e.target;
    setNewPaymentFollowUp(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  const paymentsFollowUpUpdate = (event) => {
    event.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0);
    }.bind(this),10000);
    axios.post('/payment-follow-up?tenantId=' + tenantId, {
      orderDate: newPaymentFollowUp.orderDate,
      invoiceNo: newPaymentFollowUp.invoiceNo,
      creditPeriod: newPaymentFollowUp.creditPeriod,
      name: newPaymentFollowUp.name,
      customerRep: newPaymentFollowUp.customerRep,
      amount: newPaymentFollowUp.amount,
      amountCollected: newPaymentFollowUp.amountCollected,
      status: newPaymentFollowUp.status,
    })
    .then(res => {
      console.log("Successfully added pending items to database")
      setPaymentsFollowUp(res.data);
      setNewPaymentFollowUp(prevState => ({
          ...prevState,
          orderDate: "",
          invoiceNo: "",
          creditPeriod: "",
          name: "",
          customerRep: "",
          amount: "",
          amountCollected: "",
          status: "Pending",
      }));
    })
    .catch(err => console.log(err));
  };

  const paymentsFollowUpDelete = (event) => {
    event.preventDefault();
    console.log(event.target.name)
    axios.put('/payment-follow-up?tenantId=' + tenantId, {
      id: event.target.name,
    })
    .then(res => {
      console.log("Succesfully deleted customer requirement")
      setPaymentsFollowUp(res.data);
      // window.location.reload();
    })
    .catch(err => console.log(err));
  };


  const paymentsHeader = (
    <>
      <thead>
        <tr>
          <th className="section-hide"> No. </th>
          <th scope="col" data-sortable='true'> Date (DD/MMM/YY) </th>
          <th scope="col" data-sortable='true'> Invoice No </th>
          <th scope="col" data-sortable='true'> Credit Period (Days) </th>
          <th scope="col" data-sortable='true'> Customer Name </th>
          <th scope="col" data-sortable='true'> Representative </th>
          <th scope="col" data-sortable='true'> Invoice Amount </th>
          <th scope="col" data-sortable='true'> Amount Collected </th>
          <th scope="col" data-sortable='true'> Balance Pending </th>
          <th scope="col" data-sortable='true'> Status </th>
          <th scope="col" data-sortable='true'> Update </th>
          <th className = {user.name==="umamaheshwar@stalksnspice.com" ? "" : "hidden" } scope="col" data-sortable='true'> Delete </th>
        </tr>
      </thead>
    </>
  )

  const paymentsDisplay = (
    <div className="rowcontainer space-top">
      <div className="col-md-12 col-xl-12 col-sm-12 col-xs-12 b2corders">
        <table className='table'>
          {paymentsHeader}
          <tbody>
            <tr>
              <td className="section-hide"></td>
              <td>
                <label><input  className="tracking-input" name="orderDate" value={newPaymentFollowUp.orderDate || ""} onChange={handlePaymentFollowUp} /> </label>
              </td>
              <td>
                <label><input  className="tracking-input" name="invoiceNo" value={newPaymentFollowUp.invoiceNo || ""}  onChange={handlePaymentFollowUp}/> </label>
              </td>
              <td>
                <label><input  className="tracking-input" name="creditPeriod" value={newPaymentFollowUp.creditPeriod || ""}  onChange={handlePaymentFollowUp}/> </label>
              </td>
              <td>
                <label><input  className="tracking-input" name="name" value={newPaymentFollowUp.name || ""}  onChange={handlePaymentFollowUp}/> </label>
              </td>
              <td>
                <label><input  className="tracking-input" name="customerRep" value={newPaymentFollowUp.customerRep || ""}  onChange={handlePaymentFollowUp}/> </label>
              </td>
              <td>
                <label><input  className="tracking-input" name="amount" value={newPaymentFollowUp.amount || ""}  onChange={handlePaymentFollowUp}/> </label>
              </td>
              <td>
                <label><input  className="tracking-input" name="amountCollected" value={newPaymentFollowUp.amountCollected || ""}  onChange={handlePaymentFollowUp}/> </label>
              </td>
              <td>
                -
              </td>
              <td>
                -
              </td>
              <td>
                <div className = "iuo">
                  <input className = "btn btn-large btn-primary" type='submit' value='&#10004;' onClick={paymentsFollowUpUpdate}  disabled={disabled} />
                </div>
              </td>
            </tr>
            {paymentsFollowUp.map((payments, i) => (
              <Fragment key={payments._id}>
                <tr className={`${(moment(new Date()).isAfter(moment(payments.orderDate).add(payments.creditPeriod, 'days').calendar()) && payments.amountCollected !== payments.amount) ? "row-space date-crossed" : payments.amountCollected === payments.amount ? "row-space payment-made" : "row-space"}`}>
                  <td> {i+1} </td>
                  <td> {moment(payments.orderDate).format("DD-MMM-YY")}</td>
                  <td> {payments.invoiceNo}</td>
                  <td> {payments.creditPeriod} </td>
                  <td> {payments.name} </td>
                  <td> {payments.customerRep} </td>
                  <td>{payments.amount.toLocaleString()}</td>
                  <td>
                    <input className='form-control' type='text' defaultValue={parseInt(payments.amountCollected) ? parseInt(payments.amountCollected) : 0} onChange={e => setAmountCollected(e.target.value)}/>
                  </td>
                  <td>{(payments.amount - (payments.amountCollected || 0)).toLocaleString()}</td>
                  <td> {payments.amountCollected===payments.amount ? "RECEIVED" : "PENDING"} </td>
                  <td>
                    <div className = "iuo">
                      <input className = "btn btn-large btn-primary" type='submit' value='&#10004;' name={payments._id} onClick={updatePayment} />
                    </div>
                  </td>
                  <td>
                    <div className = {user.email==="umamaheshwar@stalksnspice.com" ? "iou" : "hidden" }>
                      <input className = {user.email==="umamaheshwar@stalksnspice.com" ? "btn btn-large btn-primary" : "hidden" } type='submit' value='X' name={payments._id} onClick={paymentsFollowUpDelete} />
                    </div>
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )

  return (
    <div className="container-fluid">
      <div className="content-wrapper">
        <header>
          <div className="header-align">
            <h3 className="header">Payments Follow Up</h3>
            <CSVLink  className="btn btn-large btn-primary bulk-update-order-button" data={paymentsFollowUp.map( payments => ({
              Date: payments.orderDate,
              InvoiceNo: payments.invoiceNo,
              CreditPeriod: payments.creditPeriod,
              Name: payments.name,
              Amount: payments.amount.toLocaleString(),
              AmountPending: (payments.amount - (payments.amountCollected || 0)).toLocaleString(),
            }))} >Export To Excel</CSVLink>
          </div>
        </header>
        <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12 dashboard">
          <h3> Total Amount Outstanding: {outstanding} </h3>
        </div>
        <div className="space-top">
          <div>
            {paymentsDisplay}
          </div>
        </div>
      </div>
    </div>
  );
}


export default PaymentFollowUp;
