import React from 'react'
import { useAuth0 } from "@auth0/auth0-react";

function Packaging(props) {
  const { isAuthenticated, loginWithRedirect, logout, loading, user } = useAuth0();

  return (
    <div >
      {!isAuthenticated && (
        <img className= "full-width" src="https://s3.ap-south-1.amazonaws.com/stalksnspice.com/seller-central/packaging.png"/>
      )}
    </div>
  )
}


export default Packaging
