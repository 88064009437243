import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";

import moment from 'moment';


function Dashboard (props) {
  const [pendingItems, setPendingItems] = useState([]);
  const [orderDefects, setOrderDefects] = useState([]);
  const [customerRequirements, setCustomerRequirements] = useState([]);
  const [newCustomerRequirement, setNewCustomerRequirement] = useState({name: "",product: ""})
  const [newPendingItem, setNewPendingItem] = useState({name: "",quantity: ""})
  const [newOrderDefect, setNewOrderDefect] = useState({date: "",orderId: "",name:"",description:"", status:"Pending"})
  const [orderDefectRate, setOrderDefectRate] = useState("");
  const [orderCount, setOrderCount] = useState("");

  const { user, tenantId } = useAuth0();

  useEffect(() => {
    axios.get('/dashboard?tenantId=' + tenantId)
    .then(result => {
      setOrderCount(result.data.orderCount)
      axios.get('/dashboard/order-defects?tenantId=' + tenantId)
      .then(res => {
        var orderDefectRateVar = (res.data.length / result.data.orderCount * 100).toFixed(2)
        console.log(orderCount)
        console.log(orderDefectRateVar)
        setOrderDefectRate(orderDefectRateVar)
        console.log(res.data)
        setOrderDefects(res.data)
      })
      .catch(err => console.log(err));

    })
    .catch(err => console.log(err));


    axios.get('/dashboard/customer-requirement?tenantId=' + tenantId)
    .then(res => {
      setCustomerRequirements(res.data);
    })
    .catch(err => console.log(err));

    axios.get('/dashboard/pending-items?tenantId=' + tenantId)
    .then(res => {
      setPendingItems(res.data)
    })
    .catch(err => console.log(err))

  }, []);


  const handleChangeCustomerRequirement = e => {
    const { name, value } = e.target;
    setNewCustomerRequirement(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  const handleChangePendingItem = e => {
    const { name, value } = e.target;
    setNewPendingItem(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  const handleChangeOrderDefect = e => {
    const { name, value } = e.target;
    setNewOrderDefect(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  const customerRequirementUpdate = (event) => {
    event.preventDefault();
    axios.post('/dashboard/customer-requirement?tenantId=' + tenantId, {
      name: newCustomerRequirement.name,
      product: newCustomerRequirement.product
    })
    .then(res => {
      setCustomerRequirements(res.data);
      setNewCustomerRequirement(prevState => ({
          ...prevState,
          name: "",
          product: ""
      }));
      // window.location.reload();

    })
    .catch(err => console.log(err));
  };

  const pendingItemUpdate = (event) => {
    event.preventDefault();
    console.log(newPendingItem)
    axios.post('/dashboard/pending-items?tenantId=' + tenantId, {
      name: newPendingItem.name,
      quantity: newPendingItem.quantity
    })
    .then(res => {
      console.log("Successfully added pending items to database")
      setPendingItems(res.data);
      setNewPendingItem(prevState => ({
          ...prevState,
          name: "",
          quantity: ""
      }));
    })
    .catch(err => console.log(err));
  };

  const orderDefectUpdate = (event) => {
    event.preventDefault();
    axios.post('/dashboard/order-defects?tenantId=' + tenantId, {
      date: newOrderDefect.date,
      orderId: newOrderDefect.orderId,
      name: newOrderDefect.name,
      description: newOrderDefect.description,
      status: newOrderDefect.status
    })
    .then(res => {
      console.log("Successfully added order defects to database")
      setOrderDefects(res.data);
      setNewOrderDefect(prevState => ({
          ...prevState,
          date: "",
          orderId: "",
          name: "",
          description: "",
      }));
    })
    .catch(err => console.log(err));
  };

  const customerRequirementDelete = (event) => {
    event.preventDefault();
    console.log(event.target.name)
    axios.put('/dashboard/customer-requirement?tenantId=' + tenantId, {
      id: event.target.name,
    })
    .then(res => {
      console.log("Succesfully deleted customer requirement")
      setCustomerRequirements(res.data);
      // window.location.reload();
    })
    .catch(err => console.log(err));
  };

  const pendingItemDelete = (event) => {
    event.preventDefault();
    console.log(event.target.name)
    axios.put('/dashboard/pending-items?tenantId=' + tenantId, {
      id: event.target.name,
    })
    .then(res => {
      console.log("Succesfully deleted pending item")
      setPendingItems(res.data);
    })
    .catch(err => console.log(err));
  };

  const orderDefectDelete = (event) => {
    event.preventDefault();
    console.log(event.target.name)
    axios.put('/dashboard/order-defects?tenantId=' + tenantId, {
      id: event.target.name,
    })
    .then(res => {
      console.log("Succesfully deleted order defect")
      setOrderDefects(res.data);
    })
    .catch(err => console.log(err));
  };

  const customerrequirement = (
    <>
      <h3> Customer Requirements </h3>
      <table className='table'>
        <thead>
          <tr>
            <th> Customer Name </th>
            <th> Product Required </th>
            <th>  </th>
          </tr>
        </thead>
          <tbody>
            {customerRequirements ? (customerRequirements.map((customerRequirement, i) => (
              <tr key={i}>
                <td> {customerRequirement.name} </td>
                <td> {customerRequirement.product} </td>
                <td>
                  <div className = "iuo">
                    <input className = "btn btn-large btn-primary" type='submit' value='X' name={customerRequirement._id} onClick={customerRequirementDelete}  />
                  </div>
                </td>
              </tr>
            ))) : []}
            <tr>
              <td>
                <label><input  className="tracking-input" name="name" value={newCustomerRequirement.name || ""} onChange={handleChangeCustomerRequirement} /> </label>
              </td>
              <td>
                <label><input  className="tracking-input" name="product" value={newCustomerRequirement.product || ""}  onChange={handleChangeCustomerRequirement}/> </label>
              </td>
              <td>
                <div className = "iuo">
                  <input className = "btn btn-large btn-primary" type='submit' value='&#10004;' onClick={customerRequirementUpdate}  />
                </div>
              </td>
            </tr>
          </tbody>
      </table>
    </>
  )

  const orderdefect = (
    <>
      <h3> Order Defects </h3>
      <table className='table'>
        <thead>
          <tr>
            <th> Order Date (DD-MMM-YY) </th>
            <th> OrderId </th>
            <th> Customer Name </th>
            <th> Defect Description </th>
            <th> Status </th>
            <th> </th>
          </tr>
        </thead>
          <tbody>
            {orderDefects ? (orderDefects.map((orderDefect, i) => (
              <tr key={i}>
                <td> {moment(orderDefect.date).format("DD-MMM-YY")} </td>
                <td> {orderDefect.orderId} </td>
                <td> {orderDefect.name} </td>
                <td> {orderDefect.description} </td>
                <td> {orderDefect.status} </td>
                {
                  user.email === "umamaheshwar@stalksnspice.com"
                  ? (
                      <>
                        <td>
                          <div className = "iuo">
                            <input className = "btn btn-large btn-primary" type='submit' value='X' name={orderDefect._id} onClick={orderDefectDelete}  />
                          </div>
                        </td>
                      </>
                    )
                  : null
                }
              </tr>
            ))) : []}
              <tr>
                <td>
                  <label><input  className="tracking-input" name="date" value={newOrderDefect.date || ""} onChange={handleChangeOrderDefect} /> </label>
                </td>
                <td>
                  <label><input  className="tracking-input" name="orderId" value={newOrderDefect.orderId || ""}  onChange={handleChangeOrderDefect}/> </label>
                </td>
                <td>
                  <label><input  className="tracking-input" name="name" value={newOrderDefect.name || ""}  onChange={handleChangeOrderDefect}/> </label>
                </td>
                <td>
                  <label><input  className="tracking-input" name="description" value={newOrderDefect.description || ""}  onChange={handleChangeOrderDefect}/> </label>
                </td>
                <td> {newOrderDefect.status} </td>
                <td>
                  <div className = "iuo">
                    <input className = "btn btn-large btn-primary" type='submit' value='&#10004;' onClick={orderDefectUpdate}  />
                  </div>
                </td>
              </tr>
        </tbody>
      </table>
    </>
  )

  const pendingItemsToShow = (
    <>
      <h3> Pending Item </h3>
      <table className='table'>
        <thead>
          <tr>
            <th> Product Name </th>
            <th> Quantity </th>
            <th>  </th>
          </tr>
        </thead>
          <tbody>
          {pendingItems ? (pendingItems.map((pendingItem, i) => (
            <tr key={i}>
              <td> {pendingItem.name} </td>
              <td> {pendingItem.quantity} </td>
              <td>
                <div className = "iuo">
                  <input className = "btn btn-large btn-primary" type='submit' value='X' name={pendingItem._id} onClick={pendingItemDelete}  />
                </div>
              </td>
            </tr>
          ))) : []}
          <tr>
            <td>
              <label><input  className="tracking-input" name="name" value={newPendingItem.name || ""} onChange={handleChangePendingItem} /> </label>
            </td>
            <td>
              <label><input  className="tracking-input" name="quantity" value={newPendingItem.quantity || ""}  onChange={handleChangePendingItem}/> </label>
            </td>
            <td>
              <div className = "iuo">
                <input className = "btn btn-large btn-primary" type='submit' value='&#10004;' onClick={pendingItemUpdate}  />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )


  return (
    <div className="content-wrapper">
      <div className="">
        <h1 className=""> DASHBOARD </h1>
        <div>
          <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12 dashboard">
            <h3> Order Defect Rate: {orderDefectRate} % </h3>
            <p> {orderDefects.length} Defects in {orderCount} Orders in Past 60 Days</p>
          </div>
          <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12">
            {orderdefect}
          </div>
          <div className="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12 dashboard">
            {customerrequirement}
          </div>
          <div className="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12 dashboard">
            {pendingItemsToShow}
          </div>
        </div>
      </div>
    </div>
  )
}


export default Dashboard;
