import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {Pagination, Tooltip, OverlayTrigger, Modal} from 'react-bootstrap'
import moment from 'moment';
import queryString from 'query-string';
import { CSVLink } from "react-csv";
import {FULFILLMENTOPTIONS} from '../shared/fulfillmentOptions.js';
import { useAuth0 } from "@auth0/auth0-react";

import {professionalRateCalculator, pickrrRateCalculator, indiaPostRateCalculator, shipEaseRateCalculator} from '../shared/sharedFunctionsFrontEnd.js';

function Order (props) {
  const [isEditing, setEditing] = useState(false);
  const [dataView, setDataView] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [editRow, setEditRow] = useState(null);
  const [editPackageTrackingDetails, setEditPackageTrackingDetails] = useState({height: "",weight: "",width:"",length:"", trackingId: ""});
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("")
  const [rates, setRates] = useState([])
  const [width, setWidth] = useState("")
  const [height, setHeight] = useState("")
  const [length, setLength] = useState("")
  const [weight, setWeight] = useState("")
  const [trackingId, setTrackingId] = useState("")
  const [orders, setOrders] = useState([]);
  const [fulfillmentStatus, setFulfillmentStatus] = useState("");
  const [locality] = useState("");
  const [limit, setLimit] = useState("");
  const [route, setRoute] = useState("");
  const [selectedOrders, updateSelectedOrders] = useState([]);
  const [backendIdentifier] = useState("shippingPage");
  const [data, setData] = useState([]);
  const [productsToDisplay, setProductsToDisplay] = useState(2);
  const [isOpen, setisOpen] = useState(false);
  const [fulfillmentOptions] = useState(FULFILLMENTOPTIONS);
  const [status, setStatus] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [disabled, setDisabled] = useState(0);
  const location = useLocation()
  const { loading, user} = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if(user) {
      setDataView(true)
    }
    setTenantId(user.tenantId)
    console.log(tenantId)
    setPage(parseInt(searchParams.get('page')));
    setLimit(searchParams.get('limit'));
    setFulfillmentStatus(searchParams.get('fulfillmentStatus'));
    setRoute(searchParams.get('route'));
    if(dataView) {
      axios.get(`/orders?fulfillmentStatus=${fulfillmentStatus}&route=${route}&limit=${limit}&backendIdentifier=shippingPage&page=${page}&tenantId=${tenantId}`)
      .then(res => {
        var courierRates = [];
        res.data.forEach((order) => {
          if(order.assignedRoute === "Professional") {
            courierRates.push(professionalRateCalculator(order))
          } else if(order.assignedRoute === "IndiaPost") {
            courierRates.push(indiaPostRateCalculator(order))
          } else if(order.assignedRoute === "Shipease") {
            courierRates.push(shipEaseRateCalculator(order))
          } else if(order.assignedRoute === "Pickrr") {
            courierRates.push(pickrrRateCalculator(order))
          } else {
            courierRates.push("Weight Not Set")
          }
        })
        setRates(courierRates)
        setOrders(res.data)
      })
      .catch(err => console.log(err));
    }

  }, [location, dataView]);

  const showMore = (e, length) => {
    if(isOpen === true) {
      var products = 2
    } else {
      var products = length
    }
    setProductsToDisplay(products);
    setisOpen(!isOpen);
  }

  const exportPicklist = (e) => {
    e.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0);
   }.bind(this),10000);
    console.log('exportPicklist being called')
    axios.post('/dashboard/picklist', {
      orders: selectedOrders,
    }, {
     responseType: 'arraybuffer',
     headers: {
       'Accept': 'application/pdf'
     }
   })
    .then(res => {
      // console.log(res)
      updateSelectedOrders([])
      const blob = new Blob([res.data], {type: 'application/pdf'})
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `Picklist.pdf`
      link.click()
    })
    .catch(err => console.log(err.response));
  }

  const exportCourierCharges = (e) => {
    e.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0);
   }.bind(this),10000);
    console.log('export courier charges being called')
    axios.get('/dashboard/export-shipping-charges', {
     responseType: 'arraybuffer',
     headers: {
       'Accept': 'csv'
     }
   })
    .then(res => {
      // console.log(res)
      const blob = new Blob([res.data], {type: 'text/csv'})
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `charges.csv`
      link.click()
    })
    .catch(err => console.log(err.response));
  }

  const exportProfessionalMIS = (e) => {
    e.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0);
   }.bind(this),10000);
    console.log('Professional MIS being exported!')
    axios.get('/dashboard/export-professional-mis', {
     responseType: 'arraybuffer',
     headers: {
       'Accept': 'csv'
     }
   })
    .then(res => {
      // console.log(res)
      const blob = new Blob([res.data], {type: 'text/csv'})
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `professional-mis.csv`
      link.click();
    })
    .catch(err => console.log(err.response));
  }

  const exportAmazonShippingFile = (e) => {
    e.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0);
   }.bind(this),10000);
    console.log('Amazon Shipping File being exported!')
    console.log(tenantId)
    axios.get(`/dashboard/amazon-shipping-file?tenantId=${tenantId}`, {
     responseType: 'arraybuffer',
     headers: {
       'Accept': 'txt'
     }
   })
    .then(res => {
      // console.log(res)
      const blob = new Blob([res.data], {type: 'text/csv'})
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `amazonShippingFile.txt`
      link.click();
    })
    .catch(err => console.log(err.response));
  }

  const exportDelayedShipmentList = (e) => {
    e.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0);
   }.bind(this),10000);
    console.log('Delayed Shipments being exported!')
    axios.get('/dashboard/delayed-shipments', {
     responseType: 'arraybuffer',
     headers: {
       'Accept': 'csv'
     }
   })
    .then(res => {
      // console.log(res)
      const blob = new Blob([res.data], {type: 'text/csv'})
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `delayed-shipments.csv`
      link.click();
    })
    .catch(err => console.log(err.response));
  }

  const exportManifest = (e) => {
    e.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0);
   }.bind(this),10000);
    console.log('exportPdf being called')
    axios.post('/dashboard/export-manifest', {
      orders: selectedOrders,
      route: props.match.params.routeId
    }, {
     responseType: 'arraybuffer',
     headers: {
       'Accept': 'application/pdf'
     }
   })
    .then(res => {
      console.log(res)
      const blob = new Blob([res.data], {type: 'application/pdf'})
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `Route`
      link.click()
    })
    .catch(err => console.log(err.response));
  }

  const selectedOrdersUpdate = (e) =>  {
    if(e.target.checked) {
      updateSelectedOrders(selectedOrders.concat(e.target.value))
      var ordersToPush = []
      orders.map((order) => {
        ordersToPush = ordersToPush.concat(order.orderDetails.orderId)
      })
      if (ordersToPush.length === selectedOrders.length) {
        var checkbox = document.getElementById('selectAllCheckbox');
        checkbox.checked = true
      }
    } else {
      var updatedSelectedOrders = selectedOrders.filter(filterEvent => filterEvent !== e.target.value)
      updateSelectedOrders(updatedSelectedOrders)
      var checkbox = document.getElementById('selectAllCheckbox');
      if (checkbox.checked === true) {
        checkbox.checked = false
      }
    }
  }

  const handleOnSelectAll = (e) => {
    var ordersToPush = []
    if(e.target.checked) {
      orders.map((order) => {
        ordersToPush = ordersToPush.concat(order.orderDetails.orderId)
      })
      var checkboxes = document.getElementsByName('selectedOrders');
      for (var checkbox of checkboxes) {
        checkbox.checked = true;
      }
      updateSelectedOrders(ordersToPush)
    } else {
      var checkboxes = document.getElementsByName('selectedOrders');
      for (var checkbox of checkboxes) {
        checkbox.checked = false;
      }
      updateSelectedOrders(ordersToPush)
    }
  }

  const bulkOrdersUpdate = (e) => {
    e.preventDefault();
    axios.post('/bulktask/bulkupdate', {
      ordersToUpdate: selectedOrders,
      fulfillmentStatus: status
    })
    .then(res => {
      setOrders(res.data)
      updateSelectedOrders([])
      window.location.reload();
    })
    .catch(err => console.log(err.response));
  }

  const bulkOrdersExport = (e) => {
    e.preventDefault();
    console.log(e.target.value)
    axios.get('/bulktask/export?ordersToUpdate=' + selectedOrders)
    .then(res => {
      console.log("Orders succesfully exported")
    })
    .catch(err => console.log(err.response));
  }

  const orderUpdate = (event) => {
    console.log("OrderUpdate Function being fired")
    event.preventDefault();
    const data = new FormData(event.target);
    var orderFulfillmentStatus = data.get('fulfillmentStatus')
    var orderOrderId = data.get('orderId')
    var customerName = data.get('name')
    var customerEmail = data.get('email')
    axios.post('/orders/' + orderOrderId, {
      fulfillmentStatus: orderFulfillmentStatus,
      backendIdentifier: backendIdentifier,
      emailId: customerEmail,
      customerName: customerName,
      user: user.name
    })
    .then(res => {
      console.log(res.data)
      setOrders(res.data)
      window.location.reload();
    })
    .catch(err => console.log(err));
  };

  const fulfillmentStatusUpdate = (value) => {
    setFulfillmentStatus(value)
    navigate('/shipping/?fulfillmentStatus=' + value + '&route=' + route + '&limit=' + limit + '&page=' + page);
  }

  const limitUpdate = (value) => {
    setLimit(value)
    navigate('/shipping?fulfillmentStatus=' + fulfillmentStatus + '&route=' + route  + '&limit=' + value + '&page=' + page);
  }

  const routeUpdate = (value) => {
    setRoute(value)
    navigate('/shipping?fulfillmentStatus=' + fulfillmentStatus + '&route=' + value + '&limit=' + limit + '&page=' + page);
  }

  const navigatePage = (value) => {
    navigate('/shipping?fulfillmentStatus=' + fulfillmentStatus + '&route=' + route + '&limit=' + limit + '&page=' + value);
  }

  const packageAndTrackingUpdate = (event) => {
    event.preventDefault();
    var queryValues = queryString.parse(props.location.search);
    var editFulfillmentStatus = queryValues.fulfillmentStatus;
    var editRoute = queryValues.route;
    var editLimit = queryValues.limit;
    axios.put('/orders/' + props.match.params.orderId, {
      height: editPackageTrackingDetails.height,
      length: editPackageTrackingDetails.length,
      width: editPackageTrackingDetails.width,
      weight: editPackageTrackingDetails.weight,
      trackingId: editPackageTrackingDetails.trackingId,
      backendIdentifier: "packageTrackingDetailsUpdateShippingPage",
      fulfillmentStatus: editFulfillmentStatus,
      route: editRoute,
      limit: editLimit
    })
    .then(res => {
      setOrders(res.data)
      setEditing(false)

    })
    .catch(err => console.log(err));
  };


  const editRowFunc = (e) => {
    setEditing(true);
    const orderEdit = orders[e.target.name];
    setEditRow(orderEdit.orderDetails.orderId);
    console.log(editRow);
    // console.log(leadEdit)
    setEditPackageTrackingDetails({...editPackageTrackingDetails,
        height: orderEdit.shipping.height,
        width: orderEdit.shipping.width,
        length: orderEdit.shipping.length,
        weight: orderEdit.shipping.weight,
        trackingId: orderEdit.shipping.trackingId,
    });
  }

  const handleEditPackageTrackingDetails = e => {
    const { name, value } = e.target;
    setEditPackageTrackingDetails(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  const updateShipments = (
    <div className="bulk-update section-hide">
      {
        tenantId !== "stalksnspice"
        ? null
        : (
          <>
            <div>
              <input className="btn bulk-update-button btn-large bulk-update-order-button" onClick={bulkOrdersUpdate} type='submit' value='Mark Selected As' disabled={selectedOrders.length === 0 || status === ""}/>
            </div>
            <div className="bulk-update-select">
              <select className='form-control' type="text" onChange={e => setStatus(e.target.value)}>
                <option value=''> -- </option>
                <option value='Delivered'> Delivered </option>
                <option value='Marked For Delivery'> Marked For Delivery </option>
                <option value='Shipped'> Shipped </option>
                <option value='Delivery Exception'> Delivery Exception </option>
              </select>
            </div>
          </>
        )
      }
    </div>
  )
  const filterOrderPage = (
    <div className="bulk-update section-hide">
      <div className="bulk-update-select">
        <select className='form-control' type="text" onChange={e => fulfillmentStatusUpdate(e.target.value)} name="fulfillmentStatus" required>
          {fulfillmentOptions.map((status) => (
            <option key={status.id} value={status.name}> {status.name} </option>
          ))}
        </select>
      </div>
      <div className="bulk-update-select">
        <select className='form-control' type="text" onChange={e => limitUpdate(e.target.value)} name="limit" required>
          <option value=''> -- </option>
          <option value='10'> 10 </option>
          <option value='30'> 30 </option>
          <option value='60'> 60 </option>
          <option value='100'> 100 </option>
        </select>
      </div>
      {
        tenantId !== "stalksnspice"
        ? null
        : (
            <div className="bulk-update-select">
              <select className='form-control' type="text" onChange={e => routeUpdate(e.target.value)} name="route" required>
                <option value='All Couriers'> All Couriers </option>
                <option value='IndiaPost'> India Post </option>
                <option value='Pickrr'> Pickrr </option>
                <option value='Professional'> Professional </option>
                <option value='Shipease'> Shipease </option>
                <option value='Shiprocket'> Shiprocket </option>
                <option value='BlueDart'> Blue Dart </option>
                <option value='DTDC'> DTDC </option>
              </select>
            </div>
        )
      }
    </div>
  )

  const updateOrder = (
    <>
      {
        tenantId !== "stalksnspice"
        ? null
        : (
            <>
              <div className="bulk-update section-hide">
                <input className="btn bulk-update-button btn-large bulk-update-order-button" onClick={exportAmazonShippingFile} type='submit' value={disabled ? 'Exporting Amazon File...' : 'Export Amazon File' } disabled={disabled}/>
                <input className="btn bulk-update-button btn-large bulk-update-order-button" onClick={exportManifest} type='submit' value={disabled ? 'Printing Manifest...' : 'Print Manifest' } disabled={disabled}/>
                <input className="btn bulk-update-button btn-large bulk-update-order-button" onClick={exportProfessionalMIS} type='submit' value={disabled ? 'Exporting Professional MIS...' : 'Profesional MIS' } disabled={disabled}/>
                <input className="btn bulk-update-button btn-large bulk-update-order-button" onClick={exportDelayedShipmentList} type='submit' value={disabled ? 'Exporting Delayed Shipments...' : 'Export Delayed Shipments' } disabled={disabled}/>
                <input className="btn bulk-update-button btn-large bulk-update-order-button" onClick={exportCourierCharges} type='submit' value={disabled ? 'Exporting Courier Charges...' : 'Export Courier Charge' } disabled={disabled}/>
              </div>
            </>
        )
      }
    </>
  )


  const orderHeader = (
    <>
      <thead>
        <tr>
          <th className="section-hide"> No. </th>
          {
            tenantId !== "stalksnspice"
            ? null
            : (
              <>
                <th className="section-hide"> <input type="checkbox" id="selectAllCheckbox" name="selectAll" onChange={handleOnSelectAll}/></th>
              </>
            )
          }
          <th className="col-width-200px" scope="col" data-sortable='true'> Order Detils </th>
          <th className="section-hide col-width-update" scope="col" data-sortable='true'> Order Date <br/> Delivery Date </th>
          <th className="col-width-address" scope="col" data-sortable='true'> Delivery Address </th>
          <th className="col-width-200px" scope="col" data-sortable='true'> Product Details </th>
          <th className="section-hide" scope="col" data-sortable='true'> Package Details </th>
          <th className="section-hide" scope="col" data-sortable='true'> Freight Earned </th>
          <th  className="section-hide" scope="col" data-sortable='true'> Courier Rate </th>
          <th className="section-hide" scope="col" data-sortable='true'> Shipping Tracking Id </th>
          <th className="col-width-update" scope="col" data-sortable='true'> Fulfillment Status </th>
          {
            tenantId !== "stalksnspice"
            ? null
            : (
              <>
                <th className="col-width-update" scope="col" data-sortable='true'> Assigned Route </th>
              </>
            )
          }
        </tr>
      </thead>
    </>
  )

  const ordersDisplay = (
    <div className="rowcontainer">
      <div className="col-md-12 col-xl-12 col-sm-12 col-xs-12 b2corders">
        <table className='table row-number'>
          {orderHeader}
          <tbody >
          {orders.map((order, i) => (
              <tr key={order.orderDetails.orderId} className={`${moment(order.orderDetails.deliverByDate).isAfter(moment(new Date())) ? "" : "date-not-crossed"}`}>
                <td className="section-hide"></td>
                {
                  tenantId !== "stalksnspice"
                  ? null
                  : (
                    <>
                      <td className="section-hide"> <input type="checkbox" id="checkbox" name="selectedOrders" onChange={selectedOrdersUpdate} value={order.orderDetails.orderId}/></td>
                    </>
                  )
                }
                <td> <Link to={`/orders/${order.orderDetails.orderId}`}> {order.orderDetails.orderId} </Link> <br/> {order.orderDetails.customerName} <br/> {order.orderDetails.customerPhone}</td>
                <td className="section-hide">
                  {moment(order.orderDetails.orderDate).format("DD-MMM-YY")} <br/>
                  {moment(order.orderDetails.deliverByDate).format("DD-MMM-YY")}
                </td>
                <td>
                  {order.orderDetails.deliveryAddress.deliveryStreet1} <br/>
                  {order.orderDetails.deliveryAddress.deliveryStreet2} <br/>
                  {order.orderDetails.deliveryAddress.deliveryCity}, {order.orderDetails.deliveryAddress.deliveryState}, {order.orderDetails.deliveryAddress.deliveryZip}
                </td>
                <td>
                  {order.orderDetails.productDetails.slice(0, productsToDisplay).map((product) => (
                    <p key={product.productSku}> {product.productName}, {product.productOption ? product.productOption : ''} : {product.productQuantity} </p>
                  ))}
                  <input className="" onClick={() => showMore(order.orderDetails.productDetails.length)} type='submit' value={isOpen ? 'Show Less' : 'Show More'}/>
                </td>
                <td className="section-hide">
                  <span className={isEditing && editRow === order.orderDetails.orderId ? "hidden" : ""}>
                    {order.shipping.length}cm x {order.shipping.width}cm x {order.shipping.height}cm, {order.shipping.weight}kg
                  </span>
                  <div className={isEditing && editRow === order.orderDetails.orderId ? '' : "hidden"}>
                    <span className="dim-input-span">
                      <label><input  className="dim-input" name="length" value={editPackageTrackingDetails.length} onChange={handleEditPackageTrackingDetails}/> L (cm) </label>
                    </span>
                    <span className="dim-input-span">
                      <label><input  className="dim-input" name="width" value={editPackageTrackingDetails.width} onChange={handleEditPackageTrackingDetails}/> W (cm) </label>
                    </span>
                    <span className="dim-input-span">
                      <label><input  className="dim-input" name="height" value={editPackageTrackingDetails.height} onChange={handleEditPackageTrackingDetails}/> H (cm) </label>
                    </span>
                    <span className="dim-input-span">
                      <label><input  className="dim-input" name="weight" value={editPackageTrackingDetails.weight} onChange={handleEditPackageTrackingDetails}/> WE (Kg) </label>
                    </span>
                  </div>
                </td>
                <td className="section-hide"> {order.orderDetails.shippingPrice ? order.orderDetails.shippingPrice: "" } </td>
                <td className="section-hide"> {order.shipping.weight ? rates[i] : "Package Dim Not Set" }</td>
                <td className="section-hide">
                  <span className={isEditing && editRow === order.orderDetails.orderId ? "hidden" : ""}> {order.shipping.trackingId} </span>
                  <label><input  className={isEditing && editRow === order.orderDetails.orderId ? "tracking-input" : "hidden"} type="text" name="trackingId" value={order.shipping.trackingId || ""} onChange={e => setTrackingId(e.target.value)} /> </label>
                </td>
                <td className="text-center"> <strong> {order.fulfillmentStatus} </strong>
                </td>
                {
                  tenantId !== "stalksnspice"
                  ? null
                  : (
                    <>
                      <td className="text-center"> <strong> {order.assignedRoute} </strong> </td>
                    </>
                  )
                }
                <td className="text-center">
                  <div className = "iuo">
                    <input className = {isEditing && editRow === order.orderDetails.orderId ? "btn btn-large btn-primary" : "hidden" } type='submit' value='&#10004;' name={order.orderDetails.orderId} onClick={packageAndTrackingUpdate}/>
                    <input className = {isEditing && editRow === order.orderDetails.orderId?  "btn btn-large btn-primary" : "hidden" } type='submit' value='X' name={order.orderDetails.orderId} onClick={(e) => setEditing(false)} />
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
      </div>
    </div>
  )

  return (
    <div className="container-fluid">
      <div className="content-wrapper">
        <header>
          <div className="header-align">
            <h3 className="header">Shipments</h3>
            {filterOrderPage}
            {updateShipments}
          </div>
        </header>
        <div>
          {updateOrder}
          {ordersDisplay}
        </div>
      </div>
      <div className="align-upload section-hide">
        <Pagination size="lg">
          <Pagination.First onClick={(e) => navigatePage(1)}/>
          <Pagination.Prev onClick={(e) => navigatePage(Math.max(page - 1,1))} />
          <Pagination.Next onClick={(e) => navigatePage(parseInt(page + 1))} />
          <Pagination.Last onClick={(e) => navigatePage(100)}/>
        </Pagination>
      </div>
    </div>
  )
}


export default Order;

// {renderRow(order)}
