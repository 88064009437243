export const ROUTEMAPS =
[
  {
    route: 1,
    src: "https://www.google.com/maps/d/u/0/embed?mid=1MY8EiPWZhlaExihmJFYyjjwnWKlkTJU-"
  },
  {
    route: 2,
    src: "https://www.google.com/maps/d/u/0/embed?mid=14G5zBCXt8r2L3j_WHGO8bOEkrIWLOV41"
  },
  {
    route: 3,
    src: "https://www.google.com/maps/d/u/0/embed?mid=1nvgfBwBj70dWnQ0WOGuAZUz64ghRm73p"
  },
  {
    route: 4,
    src: "https://www.google.com/maps/d/u/0/embed?mid=18zVl7CROspxb0wEbTOxnkaWMlEHj9_yI"
  },
  {
    route: 5,
    src: "https://www.google.com/maps/d/u/0/embed?mid=12BB3JyKR8GrhAO34QsVdpWRx7kKP_elH"
  },
]
