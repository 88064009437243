import React, { useState, useEffect } from 'react';
import './App.css';
// import {BrowserRouter } from 'react-router-dom'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import ProtectedRoute from "./auth/protected-route.js";
import Order from './pages/OrderPage.js';
import Shipping from './pages/ShippingPage.js';
import Reports from './pages/ReportsPage.js'
import Products from './pages/ProductPage.js';
import ProductDetails from './pages/ProductDetailsPage.js';
import Header from './components/HeaderComponentHooks.js'
import Home from './pages/HomePage.js'
import Packaging from './pages/PackagingPage.js'
import Admin from './pages/Admin.js';
import RouteHomePage from './pages/RouteHomePage.js'
import RouterPage from './pages/RouterPage.js'
import DashboardPage from './pages/DashboardPage.js'
import Passbook from './pages/Passbook.js'
import QuickShip from './pages/QuickShip.js'
import Customers from './pages/CustomerPage.js'
import LeadsTrackingPage from './pages/leadsTrackingPage.js'
import AddProducts from './pages/AddProducts.js'
import AbandonedCarts from './pages/AbandonedCarts.js'
import ShippingCharges from './pages/ShippingCharges.js'
import MisReport from './pages/MisReport.js'
import Login from "./pages/Login.js";
import Signup from './pages/Signup.js';
import OrderSearch from './pages/OrderSearch.js'
import PaymentFollowUp from './pages/PaymentFollowUpPage.js'
import { HashRouter } from 'react-router-dom'
import { useAuth0 } from "@auth0/auth0-react";

// const store = ConfigureStore();

function App(props) {
  const { user, isLoading, isAuthenticated } = useAuth0();
  // const [tenantIdApp, setTenantIdApp] = useState("");
  //
  // useEffect(() => {
  //   if(user) {
  //     setTenantIdApp(user.tenantId)
  //   }
  // }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <Header/>
        <Routes>
          <Route path='/packaging' element={<Packaging/>}/>
          <Route path='/login' element={<Login/>}/>
          <Route path='/signup' element={<Signup/>}/>
          <Route path='/passbook' element={<Passbook/>} />
          <Route
            path="/orders"
            element={<ProtectedRoute component={Order}/>}
          />
          <Route path='/reports' element={<Reports/>} />
          <Route path='/abandoned-carts' element={<AbandonedCarts/>} />
          <Route path='/orders/:orderId'
            element={<ProtectedRoute component={OrderSearch} />} 
            />
          <Route
            path="/shipping"
            element={<ProtectedRoute component={Shipping} />}
          />
          <Route path='/routes' element={<RouteHomePage/>} />
          <Route path='/products' element={<Products/>} />
          <Route path='/dashboard' element={<DashboardPage/>} />
          <Route
            path='/quick-ship'
            element={<ProtectedRoute component={QuickShip} />}
          />
          <Route path='/add-products' element={<AddProducts/>} />
          <Route path='/payment-follow-up' element={<PaymentFollowUp/>} />
          <Route 
            path='/leads-tracking' 
            element={<ProtectedRoute component={LeadsTrackingPage} />} />
          <Route path='/customers' element={<Customers/>} />
          <Route path='/shipping-charges' element={<ShippingCharges/>} />
          <Route path='/mis-report' element={<MisReport/>} />
          <Route path='/products/:sku' element={<ProductDetails/>} />
          <Route path='/routes/:routeId' element={<RouterPage/>}/>
        </Routes>
    </div>
  );
}




export default App;
