import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { Link, useLocation } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";


function AbandonedCarts (props) {
  const [orders, setOrders] = useState([]);


  useEffect(() => {
    console.log("Calling Abandoned Carts")
    axios.get('/dashboard/abandoned-carts')
    .then(res => {
      setOrders(res.data.items);
    })
    .catch(err => console.log(err))
  }, []);

  const orderHeader = (
    <>
      <thead>
        <tr>
          <th> No </th>
          <th className="section-hide"> <input type="checkbox" id="selectAllCheckbox" name="selectAll"/></th>
          <th scope="col" data-sortable='true'> Cart ID </th>
          <th scope="col" data-sortable='true'> Customer Details </th>
          <th className="col-width-address" scope="col" data-sortable='true'> Delivery Address </th>
          <th scope="col" data-sortable='true'> Product Details </th>
        </tr>
      </thead>
    </>
  )

  const viewOrders = (
    <div className="rowcontainer space-top">
      <div className="col-md-12 col-xl-12 col-sm-12 col-xs-12 b2corders">
        <table className='table row-number'>
          {orderHeader}
          {orders.sort( (a, b) => new Date(a.updateDate) - new Date(b.updateDate) ).map((order) => (
              <tbody key={order.cartId}>
              <tr>
                <td className="text-center"></td>
                <td className="section-hide"> <input type="checkbox" name="selectedOrders"/></td>
                <td> {order.cartId} </td>
                <td> {order.shippingPerson.name} <br/> {order.shippingPerson.phone}<br/> {order.email}</td>
                <td>
                  {order.shippingPerson.street} <br/>
                  {order.shippingPerson.city} <br/>
                  {order.shippingPerson.stateOrProvinceName}, {order.shippingPerson.postalCode}
                </td>
                <td>
                  {order.items.map((item) => (
                    <p key={item.sku}> {item.name}, {item.selectedOptions ? item.selectedOptions[0].value : ''}: {item.quantity} </p>
                  ))}
                </td>
              </tr>
              </tbody>
            ))}
          </table>
      </div>
    </div>
  )

  return (
    <div>
      <div id="capture" className="container-fluid">
        <header>
          <div className="space-top-extra">
            <h1 className="space-top">Abandoned Carts</h1>
          </div>
        </header>
        <div>
          {viewOrders}
        </div>
      </div>
    </div>
  );
}


export default AbandonedCarts
