export const MENUOPTIONSFORALL =
[
  {
    id: 0,
    name: "Orders",
    img: "https://website-essentials.s3.ap-south-1.amazonaws.com/orders.png",
    options: [
      {
        id: 0,
        name: "Display Orders",
        url: "/orders?fulfillmentStatus=&channel=&limit=10&page=1&searchTerm="
      },
      {
        id: 1,
        name: "Abandoned Carts",
        url: "/abandoned-carts"
      },
      {
        id: 2,
        name: "Add Orders",
        url: "/quick-ship"
      },
    ]
  },
  {
    id: 1,
    name: "Shipments",
    img: "https://website-essentials.s3.ap-south-1.amazonaws.com/shipments.png",
    options:[
      {
        id: 0,
        name: "Display Shipments",
        url: "/shipping?fulfillmentStatus=All%20Orders&route=All%20Couriers&limit=10&page=1",
      },
      {
        id: 1,
        name: "Product Weights",
        url: "/dashboard/weight-recon",
      },
    ]
  },
  // {
  //   id: 2,
  //   name: "Routes",
  //   img: "https://website-essentials.s3.ap-south-1.amazonaws.com/route.png",
  //   options: [
  //     {
  //       id: 0,
  //       name: "Route 1",
  //       url: "/routes/1"
  //     },
  //     {
  //       id: 1,
  //       name: "Route 2",
  //       url: "/routes/2"
  //     },
  //     {
  //       id: 2,
  //       name: "Route 3",
  //       url: "/routes/3"
  //     },
  //     {
  //       id: 3,
  //       name: "Route 4",
  //       url: "/routes/4"
  //     },
  //     {
  //       id: 4,
  //       name: "Route 5",
  //       url: "/routes/5"
  //     },
  //   ]
  // },
  // {
  //   id: 2,
  //   name: "Dashboard",
  //   img: "https://website-essentials.s3.ap-south-1.amazonaws.com/dashboard.png",
  //   options: [
  //     {
  //       id: 0,
  //       name: "Dashboard View",
  //       url: "/dashboard",
  //     },
  //     {
  //       id: 1,
  //       name: "Reports",
  //       url: "/reports",
  //     },
  //     {
  //       id: 2,
  //       name: "MIS Reports",
  //       url: "/mis-report?page=1&searchTerm=",
  //     },
  //   ]
  // },
  {
    id: 3,
    name: "CRM",
    img: "https://website-essentials.s3.ap-south-1.amazonaws.com/crm.png",
    options: [
      {
        id: 0,
        name: "Customers",
        url: "/customers?page=1&limit=10&searchTerm=",
      },
      {
        id: 1,
        name: "Leads Management",
        url: "/leads-tracking",
      },
      {
        id: 2,
        name: "Payment Follow Up",
        url: "/payment-follow-up",
      },
    ]
  },
  // {
  //   id: 4,
  //   name: "Products",
  //   img: "https://website-essentials.s3.ap-south-1.amazonaws.com/products.png",
  //   options:[
  //     {
  //       id: 0,
  //       name: "Display Products",
  //       url: "/products/?limit=100&page=1&productStatus=&stockStatus=&searchTerm=",
  //     },
  //     {
  //       id: 1,
  //       name: "Add Products",
  //       url: "/add-products",
  //     },
  //
  //   ]
  // },
  // {
  //   id: 5,
  //   name: "Billing",
  //   img: "https://website-essentials.s3.ap-south-1.amazonaws.com/wallet-white-bg.png",
  //   options: [
  //     {
  //       id: 0,
  //       name: "Shipping Charges",
  //       url: "/shipping-charges?page=1&limit=100&searchTerm=&courier=",
  //     },
  //     {
  //       id: 1,
  //       name: "All Recharges",
  //       url: "/recharges",
  //     },
  //     {
  //       id: 2,
  //       name: "Passbook",
  //       url: "/passbook",
  //     },
  //   ]
  // },
]

export const MENUOPTIONSFORDRIVERS =
[
  {
    id: 1,
    name: "Routes",
    options: [
      {
        id: 0,
        name: "Route 1",
        url: "/routes/1"
      },
      {
        id: 1,
        name: "Route 2",
        url: "/routes/2"
      },
      {
        id: 2,
        name: "Route 3",
        url: "/routes/3"
      },
      {
        id: 3,
        name: "Route 4",
        url: "/routes/4"
      },
      {
        id: 4,
        name: "Route 5",
        url: "/routes/5"
      },
    ]
  }
]
