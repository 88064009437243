import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { Auth0Provider } from "@auth0/auth0-react";


function QuickShip (props) {
  const [selectedFile, setSelectedFile] = useState(null)
  const [productId, setProductId] = useState("")
  const [selectedQuantityFile, setSelectedQuantityFile] = useState(null)

  const uploadCsv = (event) => {
    event.preventDefault();
    const data = new FormData();
    data.append('file', selectedFile);
    console.log(data);
    axios.post('/bulktask/bulk-product-upload', data,)
    .then(res => {
      console.log("Product Added Successfully")
      console.log(res)
      window.location.reload();
    })
    .catch(err => console.log(err));
  }

  const uploadRecipe = (event) => {
    event.preventDefault();
    const data = new FormData();
    data.append('file', selectedFile);
    console.log(data);
    axios.post('/recipe', data,)
    .then(res => {
      console.log("Product Added Successfully")
      console.log(res)
      window.location.reload();
    })
    .catch(err => console.log(err));
  }

  const updateStock = (event) => {
    event.preventDefault();
    const data = new FormData()
    data.append('file', selectedQuantityFile);
    console.log(data);
    axios.put('/bulktask/bulk-product-upload', data,)
    .then(res => {
      console.log("Product Stock Updated Successfully")
      console.log(res)
      window.location.reload();
    })
    .catch(err => console.log(err));
  }

  const addProductVariation = (e) => {
    axios.post('/dashboard/ecwid-variation-update', {
      productId: productId,
    })
    .then(res => {
      console.log("Product Variation Created Successfully")
      window.location.reload();
    })
    .catch(err => console.log(err))

  }


  useEffect(() => {
  });

  const addProducts = (
    <>
      <hr className="hr-large d-none d-md-block"/>
      <div className="bulk-update section-hide align-upload">
        <input type='file' onChange={(e) => setSelectedFile(e.target.files[0]) } accept=".csv"/>
        <input className="btn bulk-update-button btn-large" onClick={uploadCsv} type='submit' value='UPLOAD' disabled={selectedFile === null} />
      </div>
    </>
  )

  const updateProducts = (
    <>
      <hr className="hr-large d-none d-md-block"/>
      <div className="bulk-update section-hide align-upload">
        <input type='file' onChange={(e) => setSelectedQuantityFile(e.target.files[0]) } accept=".csv"/>
        <input className="btn bulk-update-button btn-large" onClick={updateStock} type='submit' value='UPLOAD' disabled={selectedQuantityFile === null} />
      </div>
    </>
  )

  const addVariations = (
    <div>
      <hr className="hr-large d-none d-md-block"/>
      <div>
        <h1> ADD PRODUCT VARIATIONS </h1>
        <span>
          <label><input  className="ecwid-order-input" name="ecwidProductId" value={productId} onChange={e => setProductId(e.target.value)}/> Enter ECWID Product ID </label>
        </span>
      </div>
      <div>
        <input className="btn bulk-update-button btn-large" onClick={addProductVariation} type='submit' value='UPLOAD' disabled={productId === ""} />
      </div>
    </div>
  )

  const addRecipe = (
    <>
      <hr className="hr-large d-none d-md-block"/>
      <div className="bulk-update section-hide align-upload">
        <input type='file' onChange={(e) => setSelectedFile(e.target.files[0]) } accept=".csv"/>
        <input className="btn bulk-update-button btn-large" onClick={uploadRecipe} type='submit' value='UPLOAD' disabled={selectedFile === null} />
      </div>
    </>
  )


  return (
    <div className="container-fluid" >
      <div className="content-wrapper">
        <div className="">
          <h1 className=""> ADD PRODUCTS </h1>
        </div>
        {addProducts}
        <div className="space-top-extra">
          <h1 className="text-center"> UPDATE DETAILS </h1>
        </div>
        {updateProducts}
        {addVariations}
      </div>
      <div className="space-top-extra">
        <h1 className="text-center"> ADD RECIPE </h1>
      </div>
      {addRecipe}
    </div>
  );
}

export default QuickShip
