import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {Pagination, Tooltip, OverlayTrigger, Modal} from 'react-bootstrap'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { CSVLink } from "react-csv";
import {FULFILLMENTOPTIONS} from '../shared/fulfillmentOptions.js';
import { useAuth0 } from "@auth0/auth0-react";
import {professionalRateCalculator, pickrrRateCalculator, indiaPostRateCalculator, shipEaseRateCalculator} from '../shared/sharedFunctionsFrontEnd.js';
import '../css/OrderPage.css';


function Order (props) {
  const [show, setShow] = useState(false);
  const [dataView, setDataView] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();
  const [orders, setOrders] = useState([]);
  const [courierChargesModalBody, setCourierChargesModalBody] = useState({})
  const [productDetailsForShowMoreProducts, setProductDetailsForShowMoreProducts] = useState({})
  const [orderIdForShowMoreProducts, setOrderIdForShowMoreProducts] = useState("")
  const [modalType, setModalType] = useState("")
  const [page, setPage] = useState(1);
  const [isEditing, setEditing] = useState(false)
  const [isEditingTrackingDetails, setEditingTrackingDetails] = useState(false)
  const [editPackageTrackingDetails, setEditPackageTrackingDetails] = useState({height: "",weight: "",width:"",length:""});
  const [editRow, setEditRow] = useState(null)
  const [fulfillmentStatus, setFulfillmentStatus] = useState("");
  const [channel, setChannel] = useState("");
  const [limit, setLimit] = useState("");
  const [disabled, setDisabled] = useState(0);
  const [selectedOrders, updateSelectedOrders] = useState([]);
  const [backendIdentifier] = useState("orderComponent");
  const [productsToDisplay, setProductsToDisplay] = useState(2);
  const [isOpen, setisOpen] = useState(false);
  const [fulfillmentOptions] = useState(FULFILLMENTOPTIONS);
  const [route, setRoute] = useState("");
  const [status, setStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("")
  const location = useLocation();
  const { user, isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();
  // const { tenantId } = props ;
  const [tenantId, setTenantId] = useState("")
  const exportPage = props => (
    <Tooltip {...props}>Export Page</Tooltip>
  );
  const search = props => (
    <Tooltip {...props}>Search</Tooltip>
  );
  const showMoreProducts = props => (
    <Tooltip {...props}>Show More</Tooltip>
  );
  const courierRates = props => (
    <Tooltip {...props}>Show All Rates</Tooltip>
  );

  const sort = props => (
    <Tooltip {...props}>Sort Cheapest Courier</Tooltip>
  );
  const editDimWeights = props => (
    <Tooltip {...props}>Edit Dimensions & Weights</Tooltip>
  );
  const editOrderFulfillmentStatus = props => (
    <Tooltip {...props}>Edit Fulfillment Statu</Tooltip>
  );



  useEffect(() => {
    // console.log(tenantId)
    if(user) {
      setDataView(true)
    }
    // console.log(props)
    setTenantId(user.tenantId)
    setPage(parseInt(searchParams.get('page')));
    setLimit(searchParams.get('limit'));
    setFulfillmentStatus(searchParams.get('fulfillmentStatus'));
    setChannel(searchParams.get('channel'));
    setSearchTerm(searchParams.get('searchTerm'));
    if(dataView) {
      axios.get('/orders?fulfillmentStatus=' + fulfillmentStatus + '&channel=' + channel + '&limit=' + limit + "&page=" + page + '&searchTerm=' + searchTerm + '&tenantId=' + tenantId)
      .then(res => {
        console.log("entering res")
        var courierRates = [];
        var recommendedCourier = [];
        res.data.forEach((order) => {
          var professionalRate = professionalRateCalculator(order);
          var pickrrRate = pickrrRateCalculator(order);
          var indiaPostRate = indiaPostRateCalculator(order);
          var shipEaseRate = shipEaseRateCalculator(order)
          courierRates.push({
            professionalRate: professionalRate,
            pickrrRate: pickrrRate,
            indiaPostRate: indiaPostRate,
            shipEaseRate: shipEaseRate
          })
          order.courierRates = {
            professionalRate: professionalRate,
            pickrrRate: pickrrRate,
            indiaPostRate: indiaPostRate,
            shipEaseRate: shipEaseRate
          }

          var min = Math.min(professionalRate, pickrrRate, indiaPostRate, shipEaseRate )
          var courierRatesArray = [professionalRate, pickrrRate, indiaPostRate, shipEaseRate];
          var index = courierRatesArray.indexOf(Math.min(...courierRatesArray));
          if (index === 0) {
            recommendedCourier.push({
              courier: "Professional",
              rate: min
            })
            order.recommendedCourier = {
              courier: "Professional",
              rate: min
            }
          } else if (index === 1) {
            recommendedCourier.push({
              courier: "Pickrr",
              rate: min
            })
            order.recommendedCourier = {
              courier: "Pickrr",
              rate: min
            }
          } else if(index === 2){
            recommendedCourier.push({
              courier: "India Post",
              rate: min
            })
            order.recommendedCourier = {
              courier: "India Post",
              rate: min
            }
          } else if(index === 3){
            recommendedCourier.push({
              courier: "Ship Ease",
              rate: min
            })
            order.recommendedCourier = {
              courier: "Ship Ease",
              rate: min
            }
          } else {
            recommendedCourier.push({
              courier: "Ship Ease",
              rate: min
            })
            order.recommendedCourier = {
              courier: "Ship Ease",
              rate: min
            }
          }
        })
        // console.log(courierRates)
        setOrders(res.data)
      })
      .catch(err => console.log(err));
    }

  }, [location, dataView]);

  const onSort = (event) => {
    // orders.sort((a,b) => a[sortKey].localeCompare(b[sortKey]))
    event.preventDefault();
    console.log("Entered onSort")
    orders.sort(function( a, b ) {
      a = a.recommendedCourier.courier;
      b = b.recommendedCourier.courier;
      return a < b ? -1 : a > b ? 1 : 0;
    })
    console.log(orders)
    setOrders([...orders])
  }

  const showMore = (e, order) => {
    setProductDetailsForShowMoreProducts(order.productDetails)
    setOrderIdForShowMoreProducts(order.orderId)
    setModalType("showMoreProducts")
    setShow(true)
  }

  const searchOrderQuery = (e) => {
    navigate('/orders/?fulfillmentStatus=' + fulfillmentStatus + '&channel=' + channel + '&limit=' + limit + '&page=' + page + '&searchTerm=' + searchTerm);
  }

  const searchOrderOnEnter = (e) => {
    if(e.which === 13) {
      searchOrderQuery(e);
    }
  }

  const exportPicklist = (e) => {
    e.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0);
   },10000);
    console.log('exportPicklist being called')
    axios.post('dashboard/picklist', {
      orders: selectedOrders,
      tenantId: tenantId
    }, {
     responseType: 'arraybuffer',
     headers: {
       'Accept': 'application/pdf'
     }
   })
    .then(res => {
      // console.log(res)
      updateSelectedOrders([])
      const blob = new Blob([res.data], {type: 'application/pdf'})
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `Picklist.pdf`
      link.click()
    })
    .catch(err => console.log(err.response));
  }

  const exportPickrrFile = (e) => {
    e.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0);
   },10000);
    axios.post('/dashboard/export-pickrr-file', {
      orders: selectedOrders,
      tenantId: tenantId
    }, {
     responseType: 'arraybuffer',
     headers: {
       'Accept': 'csv/txt'
     }
   })
    .then(res => {
      // console.log(res)
      updateSelectedOrders([])
      const blob = new Blob([res.data], {type: 'csv/txt'})
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `pickrr.csv`
      link.click()
    })
    .catch(err => console.log(err.response));
  }

  const exportAmazonShippingFile = (e) => {
    e.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0);
   },10000);
    axios.post('/dashboard/export-amazon-shipping-file', {
      orders: selectedOrders,
      tenantId: tenantId
    }, {
     responseType: 'arraybuffer',
     headers: {
       'Accept': 'csv/txt'
     }
   })
    .then(res => {
      // console.log(res)
      updateSelectedOrders([])
      const blob = new Blob([res.data], {type: 'csv/txt'})
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `amazon.csv`
      link.click()
    })
    .catch(err => console.log(err.response));
  }

  const exportXpressbeesFile = (e) => {
    e.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0);
   },10000);
    axios.post('/dashboard/export-xpressbees-file', {
      orders: selectedOrders,
      tenantId: tenantId
    }, {
     responseType: 'arraybuffer',
     headers: {
       'Accept': 'csv/txt'
     }
   })
    .then(res => {
      // console.log(res)
      updateSelectedOrders([])
      const blob = new Blob([res.data], {type: 'csv/txt'})
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `xpressbees.csv`
      link.click()
    })
    .catch(err => console.log(err.response));
  }

  const exportShipeaseFile = (e) => {
    e.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0);
   },10000);
    axios.post('/dashboard/export-shipease-file', {
      orders: selectedOrders,
      tenantId: tenantId
    }, {
     responseType: 'arraybuffer',
     headers: {
       'Accept': 'csv/txt'
     }
   })
    .then(res => {
      // console.log(res)
      updateSelectedOrders([])
      const blob = new Blob([res.data], {type: 'csv/txt'})
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `shipease.csv`
      link.click()
    })
    .catch(err => console.log(err.response));
  }

  const selectedOrdersUpdate = (e) =>  {
    if(e.target.checked) {
      updateSelectedOrders(selectedOrders.concat(e.target.value))
      var ordersToPush = []
      orders.forEach((order) => {
        ordersToPush = ordersToPush.concat(order.orderDetails.orderId)
      })
      if (ordersToPush.length === selectedOrders.length) {
        var checkbox = document.getElementById('selectAllCheckbox');
        checkbox.checked = true
      }
    } else {
      var updatedSelectedOrders = selectedOrders.filter(filterEvent => filterEvent !== e.target.value)
      updateSelectedOrders(updatedSelectedOrders)
      var checkbox = document.getElementById('selectAllCheckbox');
      if (checkbox.checked === true) {
        checkbox.checked = false
      }
    }
  }

  const handleOnSelectAll = (e) => {
    var ordersToPush = []
    if(e.target.checked) {
      orders.map((order) => {
        ordersToPush = ordersToPush.concat(order.orderDetails.orderId)
      })
      var checkboxes = document.getElementsByName('selectedOrders');
      for (var checkbox of checkboxes) {
        checkbox.checked = true;
      }
      updateSelectedOrders(ordersToPush)
    } else {
      var checkboxes = document.getElementsByName('selectedOrders');
      for (var checkbox of checkboxes) {
        checkbox.checked = false;
      }
      updateSelectedOrders(ordersToPush)
    }
  }

  const bulkOrdersUpdate = (e) => {
    e.preventDefault();
    axios.post('/bulktask/bulkupdate', {
      ordersToUpdate: selectedOrders,
      fulfillmentStatus: status,
      tenantId: tenantId
    })
    .then(res => {
      setOrders(res.data)
      updateSelectedOrders([])
      window.location.reload();
    })
    .catch(err => console.log(err.response));
  }

  const bulkOrdersRouteUpdate = (e) => {
    e.preventDefault();
    var updatedRoute = "";
    if (route === "None") {
      updatedRoute = "";
    } else {
      updatedRoute = route
    }
    axios.post('/bulktask/bulkupdate-route', {
      ordersToUpdate: selectedOrders,
      assignedRoute: updatedRoute,
      tenantId: tenantId
    })
    .then(res => {
      setOrders(res.data)
      updateSelectedOrders([])
      window.location.reload();
    })
    .catch(err => console.log(err.response));
  }

  const bulkOrdersExport = (e) => {
    e.preventDefault();
    console.log(e.target.value)
    axios.get('/bulktask/export?ordersToUpdate=' + selectedOrders)
    .then(res => {
      console.log("Orders succesfully exported")
    })
    .catch(err => console.log(err.response));
  }

  const orderUpdate = (event, i) => {
    event.preventDefault();
    const data = new FormData(event.target);
    var orderFulfillmentStatus = data.get('fulfillmentStatus')
    var orderOrderId = data.get('orderId')
    var customerName = data.get('name')
    var customerEmail = data.get('email')
    axios.post('/orders/' + orderOrderId, {
      fulfillmentStatus: orderFulfillmentStatus,
      backendIdentifier: backendIdentifier,
      emailId: customerEmail,
      customerName: customerName,
      user: user.name,
      tenantId: tenantId
    })
    .then(res => {
      var newOrders = [...orders]
      var professionalRate = professionalRateCalculator(res.data);
      var pickrrRate = pickrrRateCalculator(res.data);
      var indiaPostRate = indiaPostRateCalculator(res.data);
      var shipEaseRate = shipEaseRateCalculator(res.data)
      res.data.courierRates = {
        professionalRate: professionalRate,
        pickrrRate: pickrrRate,
        indiaPostRate: indiaPostRate,
        shipEaseRate: shipEaseRate
      }

      var min = Math.min(professionalRate, pickrrRate, indiaPostRate, shipEaseRate )
      var courierRatesArray = [professionalRate, pickrrRate, indiaPostRate, shipEaseRate];
      var index = courierRatesArray.indexOf(Math.min(...courierRatesArray));
      if (index === 0) {
        res.data.recommendedCourier = {
          courier: "Professional",
          rate: min
        }
      } else if (index === 1) {
        res.data.recommendedCourier = {
          courier: "Pickrr",
          rate: min
        }
      } else if(index === 2){
        res.data.recommendedCourier = {
          courier: "India Post",
          rate: min
        }
      } else if(index === 3){
        res.data.recommendedCourier = {
          courier: "Ship Ease",
          rate: min
        }
      } else {
        res.data.recommendedCourier = {
          courier: "Ship Ease",
          rate: min
        }
      }
      newOrders[i] = res.data
      setOrders(newOrders)
      setEditing(false)

      // window.location.reload();
    })
    .catch(err => console.log(err));
  };

  const fulfillmentStatusUpdate = (value) => {
    console.log(value)
    setFulfillmentStatus(value)
    navigate('/orders?fulfillmentStatus=' + value + '&channel=' + channel + '&limit=' + limit + '&page=' + page + '&searchTerm=' + searchTerm + '&tenantId=' + tenantId);
  }

  const channelUpdate = (value) => {
    setChannel(value)
    navigate('/orders?fulfillmentStatus=' + fulfillmentStatus + '&channel=' + value + '&limit=' + limit + '&page=' + page + '&searchTerm=' + searchTerm + '&tenantId=' + tenantId);
  }

  const limitUpdate = (value) => {
    setLimit(value)
    navigate('/orders?fulfillmentStatus=' + fulfillmentStatus + '&channel=' + channel + '&limit=' + value + '&page=' + page + '&searchTerm=' + searchTerm + '&tenantId=' + tenantId);
  }

  const navigatePage = (value) => {
    navigate('/orders?fulfillmentStatus=' + fulfillmentStatus + '&channel=' + channel + '&limit=' + limit + '&page=' + value + '&searchTerm=' + searchTerm + '&tenantId=' + tenantId);
  }

  const handleEditPackageTrackingDetails = e => {
    const { name, value } = e.target;
    setEditPackageTrackingDetails(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  const packageUpdate = (e, i) => {
    e.preventDefault();
    axios.put('/orders/' + e.target.name, {
      height: editPackageTrackingDetails.height,
      length: editPackageTrackingDetails.length,
      width: editPackageTrackingDetails.width,
      weight: editPackageTrackingDetails.weight,
      backendIdentifier: "packageDetailsUpdateOrderPage",
    })
    .then(res => {
      var newOrders = [...orders]
      var professionalRate = professionalRateCalculator(res.data);
      var pickrrRate = pickrrRateCalculator(res.data);
      var indiaPostRate = indiaPostRateCalculator(res.data);
      var shipEaseRate = shipEaseRateCalculator(res.data)
      res.data.courierRates = {
        professionalRate: professionalRate,
        pickrrRate: pickrrRate,
        indiaPostRate: indiaPostRate,
        shipEaseRate: shipEaseRate
      }

      var min = Math.min(professionalRate, pickrrRate, indiaPostRate, shipEaseRate )
      var courierRatesArray = [professionalRate, pickrrRate, indiaPostRate, shipEaseRate];
      var index = courierRatesArray.indexOf(Math.min(...courierRatesArray));
      if (index === 0) {
        res.data.recommendedCourier = {
          courier: "Professional",
          rate: min
        }
      } else if (index === 1) {
        res.data.recommendedCourier = {
          courier: "Pickrr",
          rate: min
        }
      } else if(index === 2){
        res.data.recommendedCourier = {
          courier: "India Post",
          rate: min
        }
      } else if(index === 3){
        res.data.recommendedCourier = {
          courier: "Ship Ease",
          rate: min
        }
      } else {
        res.data.recommendedCourier = {
          courier: "Ship Ease",
          rate: min
        }
      }
      newOrders[i] = res.data
      setOrders(newOrders)
      setEditingTrackingDetails(false)
      setEditPackageTrackingDetails(prevState => ({
          ...prevState,
          height: "",
          weight: "",
          width: "",
          length: "",
      }));
    })
    .catch(err => console.log(err));
  };

  const editRowFunc = (e) => {
    setEditingTrackingDetails(true);
    setEditRow(e.target.name);
    // console.log(leadEdit)
  }

  const editRowStatusFunc = (e) => {
    setEditing(true);
    setEditRow(e.target.name);
    // console.log(leadEdit)
  }

  const handleClose = () => {
    setShow(false)
  }

  const checkCourierRates = (e, rateObject) => {
    setShow(true)
    setCourierChargesModalBody(rateObject)
    setModalType("courierRates")
  }

  const exportButtons = (
  <div className="bulk-update-select small-screen-hide">
    <OverlayTrigger placement="right" overlay={exportPage}>
      <CSVLink  className="btn btn-large btn-primary" data={orders.map( order => ({
        orderId: order.orderDetails.orderId,
        customerName: order.orderDetails.customerName,
        customerPhone: order.orderDetails.customerPhone,
        customerAddress: `${order.orderDetails.deliveryAddress ? `${order.orderDetails.deliveryAddress.deliveryStreet1}, ${order.orderDetails.deliveryAddress.deliveryStreet2},${order.orderDetails.deliveryAddress.deliveryCity}, ${order.orderDetails.deliveryAddress.deliveryState}, ${order.orderDetails.deliveryAddress.deliveryZip}` : ""}`,
        trackingId: order.trackingId
      }))} >&#x2B73;</CSVLink>
    </OverlayTrigger>
    <input className="btn bulk-update-button btn-large" onClick={exportPicklist} type='submit' value={disabled ? 'Exporting Picklist...' : 'Export Picklist' } disabled={selectedOrders.length === 0 || disabled}/>
  </div>
)

  const filterOrderPage = (
    <div className="bulk-update">
      <div className='input-group small-screen-block-display'>
        <div className="bulk-update-select">
          <select className='form-control' type="text" onChange={e => fulfillmentStatusUpdate(e.target.value)} name="fulfillmentStatus" required>
            {fulfillmentOptions.map((status) => (
              <option key={status.id} value={status.name}> {status.name} </option>
            ))}
          </select>
        </div>
        <div className="bulk-update-select">
          <select className='form-control' type="text" onChange={e => channelUpdate(e.target.value)} name="channel" required>
            <option value=''> All Channels </option>
            <option value='Amazon'> Amazon </option>
            <option value='Ecwid'> Ecwid </option>
            <option value='Flipkart'> Flipkart </option>
          </select>
        </div>
        <div className="bulk-update-select">
          <select className='form-control' type="text" onChange={e => limitUpdate(e.target.value)} name="limit" required>
            <option value=''> -- </option>
            <option value='10'> 10 </option>
            <option value='30'> 30 </option>
            <option value='60'> 60 </option>
            <option value='100'> 100 </option>
          </select>
        </div>
      </div>
    </div>
  )

  const updateOrder = (
    <div className="bulk-update small-screen-hide">
      <div>
        <input className="btn bulk-update-button btn-large bulk-update-order-button" onClick={bulkOrdersUpdate} type='submit' value='Mark Selected As' disabled={selectedOrders.length === 0 || status === ""}/>
      </div>
      <div className="bulk-update-select">
        <select className='form-control' type="text" onChange={e => setStatus(e.target.value)}>
          <option value=''> -- </option>
          <option value='Delivered'> Delivered </option>
          <option value='Marked For Delivery'> Marked For Delivery </option>
          <option value='Shipped'> Shipped </option>
          <option value='Delivery Exception'> Delivery Exception </option>
          <option value='RTO'> RTO </option>
        </select>
      </div>
      <div>
        <input className="btn bulk-update-button btn-large bulk-update-order-button" onClick={bulkOrdersRouteUpdate} type='submit' value='Assign Selected To Route' disabled={selectedOrders.length === 0 || route === ""}/>
      </div>
      <div className="bulk-update-select">
        <select className='form-control' type="text" onChange={e => setRoute(e.target.value)}>
          <option value=''> -- </option>
          <option value='DTDC'> DTDC </option>
          <option value='Professional'> Professional </option>
          <option value='Pickrr'> Pickrr </option>
          <option value='BlueDart'> Blue Dart </option>
          <option value='IndiaPost'> India Post </option>
          <option value='None'> None </option>
        </select>
      </div>
    </div>
  )

  const orderHeader = (
    <>
      <thead>
        <tr>
          <th className="section-hide"> No. </th>
          {
            tenantId !== "stalksnspice"
            ? null
            : (
                <>
                  <th className="section-hide"> <input type="checkbox" id="selectAllCheckbox" name="selectAll" onChange={handleOnSelectAll}/></th>
                </>
            )
          }
          <th scope="col" data-sortable='true'> Client <br/> Channel <br/> Tracking ID</th>
          <th scope="col" data-sortable='true'> Order Details </th>
          <th className="section-hide" scope="col" data-sortable='true'> Order & <br/> Delivery Date </th>
          <th className="col-width-200px" scope="col" data-sortable='true'> Delivery Address </th>
          <th className="col-width-200px" scope="col" data-sortable='true'> Product Details </th>
          <th className="section-hide" scope="col" data-sortable='true'> Package Details </th>
          {
            tenantId !== "stalksnspice"
            ? null
            : (
                <>
                  <th className="section-hide" scope="col" data-sortable='true'> Freight Earned </th>
                  <OverlayTrigger placement="right" overlay={sort}>
                    <th className="col-width-update" scope="col" data-sortable='true' onClick={(e) => onSort(e)}>
                      Recommended Courier <br/> & Rate &#x2B9F;
                    </th>
                   </OverlayTrigger>
                  <th className="col-width-update" scope="col" data-sortable='true'> Fulfillment Status </th>
                  <th className="col-width-update" scope="col" data-sortable='true'> Assigned Route </th>
                </>
              )
          }
        </tr>
      </thead>
    </>
  )

  const courierRateModalBody = (
    <>
      <table className='table'>
        <thead>
          <tr>
            <th className="section-hide"> No. </th>
            <th scope="col" data-sortable='true'> Courier </th>
            <th scope="col" data-sortable='true'> Rate </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td> 1 </td>
            <td> Professional </td>
            <td> {courierChargesModalBody.professionalRate}  </td>
          </tr>
          <tr>
            <td> 2 </td>
            <td> Shipease </td>
            <td> {courierChargesModalBody.shipEaseRate}  </td>
          </tr>
          <tr>
            <td> 3 </td>
            <td> India Post </td>
            <td> {courierChargesModalBody.indiaPostRate}  </td>
          </tr>
          <tr>
            <td> 4 </td>
            <td> Pickrr </td>
            <td> {courierChargesModalBody.pickrrRate}  </td>
          </tr>
        </tbody>
      </table>
    </>
  )

  const productExpansionModalBody = (
    <>
      <table className='table'>
        <thead>
          <tr>
            <th scope="col" data-sortable='true'> No. </th>
            <th scope="col" data-sortable='true'> SKU </th>
            <th scope="col" data-sortable='true'> Product </th>
            <th scope="col" data-sortable='true'> Qty </th>
          </tr>
        </thead>
        <tbody>
        {Object.keys(productDetailsForShowMoreProducts).length ? productDetailsForShowMoreProducts.map((product,i) => (
          <tr key={product.productSku}>
            <td> {i+1} </td>
            <td> {product.productSku} </td>
            <td> {product.productName ? product.productName : ''}, {product.productOption ? product.productOption : ''}  </td>
            <td> {product.productQuantity}  </td>
          </tr>
        )): ""}
        </tbody>
      </table>
    </>
  )

  const ordersDisplay = (
    <div className="col-md-12 col-xl-12 col-sm-12 col-xs-12 b2corders">
      <table className='table row-number'>
        {orderHeader}
        <tbody >
        {orders.map((order, i) => (
            <tr key={order.orderDetails.orderId} className={`${(moment(new Date()).isAfter(moment(order.orderDetails.deliverByDate)) && order.fulfillmentStatus !== "Delivered") ? "row-space date-crossed" : "row-space"}`}>
              <td className="section-hide"></td>
              {
                tenantId !== "stalksnspice"
                ? null
                : (
                    <>
                      <td className="section-hide"> <input type="checkbox" id="checkbox" name="selectedOrders" onChange={selectedOrdersUpdate} value={order.orderDetails.orderId}/></td>
                    </>
                )
              }
              <td className="section-hide"> {order.tenantId} <br/> {order.channel} <br/> {order.trackingId} </td>
              <td> <Link to={`/orders/${order.orderDetails.orderId}`}> {order.orderDetails.orderId} </Link> <br/> {order.orderDetails.customerName} <br/> {order.orderDetails.customerPhone}</td>
              <td className="section-hide">
                {moment(order.orderDetails.orderDate).format("DD-MMM-YY")} <br/>
                {moment(order.orderDetails.deliverByDate).format("DD-MMM-YY")}
              </td>
              <td>
                {order.orderDetails.deliveryAddress ? order.orderDetails.deliveryAddress.deliveryStreet1 : ""} <br/>
                {order.orderDetails.deliveryAddress ? order.orderDetails.deliveryAddress.deliveryStreet2 : ""} <br/>
                {order.orderDetails.deliveryAddress ? order.orderDetails.deliveryAddress.deliveryCity : ""}, {order.orderDetails.deliveryAddress ? order.orderDetails.deliveryAddress.deliveryState : ""}, {order.orderDetails.deliveryAddress ? order.orderDetails.deliveryAddress.deliveryZip : ""}
              </td>
              <td>
                {order.orderDetails.productDetails.slice(0, productsToDisplay).map((product) => (
                  <p key={product.productSku ? product.productSku : 100 }> {product.productName ? product.productName : ''}, {product.productOption ? product.productOption : ''} : {product.productQuantity ? product.productQuantity : ''} </p>
                ))}
                <OverlayTrigger placement="right" overlay={showMoreProducts}>
                  <input className="no-background-button" onClick={(e) => showMore(e,order.orderDetails)} type='submit' value="&#x2B89;"/>
                </OverlayTrigger>
              </td>
              <td className="section-hide">
                <span className={isEditingTrackingDetails && editRow === order.orderDetails.orderId ? "hidden" : ""}>
                  {order.shipping.length}cm x {order.shipping.width}cm x {order.shipping.height}cm, {order.shipping.weight}kg
                </span>
                <div className={isEditingTrackingDetails && editRow === order.orderDetails.orderId ? '' : "hidden"}>
                  <span className="dim-input-span">
                    <label><input  className="dim-input" name="length" value={editPackageTrackingDetails.length} onChange={handleEditPackageTrackingDetails}/> L (cm) </label>
                  </span>
                  <span className="dim-input-span">
                    <label><input  className="dim-input" name="width" value={editPackageTrackingDetails.width} onChange={handleEditPackageTrackingDetails}/> W (cm) </label>
                  </span>
                  <span className="dim-input-span">
                    <label><input  className="dim-input" name="height" value={editPackageTrackingDetails.height} onChange={handleEditPackageTrackingDetails}/> H (cm) </label>
                  </span>
                  <span className="dim-input-span">
                    <label><input  className="dim-input" name="weight" value={editPackageTrackingDetails.weight} onChange={handleEditPackageTrackingDetails}/> WE (Kg) </label>
                  </span>
                </div>
                {
                  tenantId !== "stalksnspice"
                  ? null
                  : (
                      <>
                        <div className = "iuo">
                          <input className = {isEditingTrackingDetails && editRow === order.orderDetails.orderId ?  "btn btn-large btn-primary" : "hidden" } type='submit' value='X' name={order.orderDetails.orderId} onClick={(e) => setEditingTrackingDetails(false)} />
                          <input className = {isEditingTrackingDetails && editRow === order.orderDetails.orderId ?  "btn btn-large btn-primary" : "hidden" } type='submit' value='&#10004;' name={order.orderDetails.orderId} onClick={(e) => packageUpdate(e, i)} />
                          <OverlayTrigger placement="right" overlay={editDimWeights}>
                            <input className = {isEditingTrackingDetails && editRow === order.orderDetails.orderId ?  "hidden" : "no-background-button" } type='submit' value='&#x270E;' name={order.orderDetails.orderId} onClick={editRowFunc} />
                          </OverlayTrigger>
                        </div>
                      </>
                  )
                }
              </td>
              {
                tenantId !== "stalksnspice"
                ? null
                : (
                    <>
                      <td className="section-hide"> {order.orderDetails.shippingPrice ? order.orderDetails.shippingPrice: "" } </td>
                      <td className="section-hide">
                        {order.shipping.weight ? order.recommendedCourier.courier : "Set Weight "} <br/>
                        {order.shipping.weight ? order.recommendedCourier.rate : ""} <br/>
                        <OverlayTrigger placement="right" overlay={courierRates}>
                          <input className="no-background-button" type='submit' value='&#x2B89;' name={order.courierRates} onClick={(e) => checkCourierRates(e,order.courierRates)} />
                        </OverlayTrigger>
                      </td>
                      <td className="text-center"> <span className={isEditing && editRow === order.orderDetails.orderId ? "hidden" : ""}> <strong> {order.fulfillmentStatus} </strong></span>
                        <div className={isEditing && editRow === order.orderDetails.orderId ?'thumbnail center well well-sm text-center text-center space-top' : "hidden"}>
                          <form className="needs-validation" onSubmit={(e) => orderUpdate(e, i)} action={'/orders/' + order.orderDetails.orderId} method='POST'>
                            <div className="input-group iuo">
                              <span className="input-group-addon">
                                <i className="fa fa-envelope"/>
                                <div>
                                  <select className='form-control' type="text" name="fulfillmentStatus">
                                    {fulfillmentOptions.map((status) => (
                                      <option key={status.id} value={status.name}> {status.name} </option>
                                    ))}
                                  </select>
                                  <label htmlFor='fulfillmentStatus'/>
                                </div>
                                <div className="hidden">
                                  <input  name="orderId" type="hidden" value={order.orderDetails.orderId} />
                                  <input  name="email" type="hidden" value={order.orderDetails.customerEmail} />
                                  <input  name="name" type="hidden" value={order.orderDetails.customerName} />
                                  <label htmlFor='orderId'/>
                                </div>
                              </span>
                              <div className = "iuo">
                                <input className = "btn btn-large btn-primary" type='submit' value='UPDATE STATUS' />
                              </div>
                            </div>
                          </form>
                        </div>
                        <div className = "iuo">
                          <input className = {isEditing && editRow === order.orderDetails.orderId ?  "btn btn-large btn-primary" : "hidden" } type='submit' value='X' name={order.orderDetails.orderId} onClick={(e) => setEditing(false)} />
                          <OverlayTrigger placement="right" overlay={editOrderFulfillmentStatus}>
                            <input className = {isEditing && editRow === order.orderDetails.orderId ?  "hidden" : "no-background-button" } type='submit' value='&#x270E;' name={order.orderDetails.orderId} onClick={editRowStatusFunc} />
                          </OverlayTrigger>
                        </div>
                      </td>
                      <td className="text-center"> <strong> {order.assignedRoute} </strong> </td>
                    </>
                )
              }
            </tr>
          ))}
          </tbody>
        </table>
    </div>
  )

  return (
    <div className="container-fluid">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {modalType === "courierRates" ? "Courier & Rates" : orderIdForShowMoreProducts}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalType === "courierRates" ? courierRateModalBody : productExpansionModalBody}
        </Modal.Body>
      </Modal>
      <div className="content-wrapper">
        <header>
          <div className="header-align">
            <h3 className="header">Orders</h3>
            {filterOrderPage}
            <div className="col-md-3 col-sm-12 col-xs-12">
              <div className='input-group input-add'>
                <span className="input-group-addon"> <i className="fa fa-envelope"></i></span>
                <input className='form-control' type='text' value={searchTerm} onChange={e => setSearchTerm(e.target.value)} onKeyPress={e => searchOrderOnEnter(e)} name='orderId' placeholder='Customer/Product'/>
                <OverlayTrigger placement="right" overlay={search}>
                  <input className='btn btn-large update-order' type='submit' onClick={(e) => searchOrderQuery(e)} value='&#x2315;'/>
                </OverlayTrigger>
              </div>
            </div>
          </div>
        </header>
        <div>
        {
          tenantId !== "stalksnspice"
          ? null
          : (
            <div className="header-align">
              {updateOrder}
              {exportButtons}
            </div>
          )
        }
          {ordersDisplay}
        </div>
      </div>
      <div className="align-upload section-hide">
        <Pagination size="lg">
          <Pagination.First onClick={(e) => navigatePage(1)}/>
          <Pagination.Prev onClick={(e) => navigatePage(Math.max(page - 1,1))} />
          <Pagination.Next onClick={(e) => navigatePage(parseInt(page + 1))} />
          <Pagination.Last onClick={(e) => navigatePage(100)}/>
        </Pagination>
      </div>
    </div>
  );
}


export default Order;
