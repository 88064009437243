import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import {Pagination, Tooltip, OverlayTrigger, Modal} from 'react-bootstrap'
import moment from 'moment';
import queryString from 'query-string';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

import {professionalRateCalculator, pickrrRateCalculator, indiaPostRateCalculator, shipEaseRateCalculator} from '../shared/sharedFunctionsFrontEnd.js';


function MisReport (props) {
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(0);
  const [reports, setReports] = useState([]);
  const [limit] = useState("");
  const [page, setPage] = useState(0);
  const location = useLocation()
  const navigate = useNavigate();
  const { tenantId } = useAuth0();

  const downloadCharges = props => (
    <Tooltip {...props}>Download Charges</Tooltip>
  );
  const search = props => (
    <Tooltip {...props}>Search</Tooltip>
  );

  useEffect(() => {
    var queryValues = queryString.parse(props.location.search)
    setPage(parseInt(queryValues.page))
    axios.get('/mis-report?page=' + queryValues.page + '&searchTerm=' + queryValues.searchTerm + '&tenantId=' + tenantId)
    .then(res => {
      console.log(res.data)
      setReports(res.data)
    })
    .catch(err => console.log(err));
  }, [location]);


  const searchQuery = (e) => {
    navigate('/mis-report?page=' + page);
  }

  const searchOnEnter = (e) => {
    if(e.which === 13) {
      searchQuery(e);
    }
  }

  const handleClose = () => {
    setShow(false)
  }


  // const filterCustomerPage = (
  //   <>
  //     <div className="flex">
  //       <div className='input-group'>
  //         <span className="input-group-addon"> <i className="fa fa-envelope"></i></span>
  //         <input className='form-control' type='text' value={searchTerm} onChange={e => setSearchTerm(e.target.value)} onKeyPress={e => searchOnEnter(e)} name='orderId' placeholder='Search Product'/>
  //         <OverlayTrigger placement="right" overlay={search}>
  //           <input className='btn btn-large update-order' type='submit' onClick={(e) => searchQuery(e)} value='&#x2315;'/>
  //         </OverlayTrigger>
  //       </div>
  //     </div>
  //   </>
  // )

  // <div className="bulk-update-select">
  //   <OverlayTrigger placement="right" overlay={downloadCharges}>
  //     <input className="btn bulk-update-button btn-large" onClick={exportCourierCharges} type='submit' value='&#x2B73;'  disabled={disabled}/>
  //   </OverlayTrigger>
  // </div>


  const customerHeader = (
    <>
      <thead>
        <tr>
          <th className="section-hide"> No. </th>
          <th scope="col" data-sortable='true'> Date </th>
          <th scope="col" data-sortable='true'> Category </th>
          <th scope="col" data-sortable='true'> Description </th>
          <th scope="col" data-sortable='true'> Download </th>
        </tr>
      </thead>
    </>
  )

  const customerDisplay = (
    <div className="rowcontainer space-top">
      <div className="col-md-12 col-xl-12 col-sm-12 col-xs-12 b2corders">
        <table className='table'>
          {customerHeader}
          <tbody>
            {reports.map((report, i) => (
              <Fragment key={i}>
                <tr>
                  <td> {i+1} </td>
                  <td> {moment(report.updatedAt).format("DD-MMM-YY")} </td>
                  <td> {report.category} </td>
                  <td> {report.description} </td>
                  <td> <a href={report.fileUrl}>Download File</a> </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )

  return (
    <div className="container-fluid">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Charges Fetch Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your charges are being fetched. Kindly wait 5-10s for the excel to download.</Modal.Body>
      </Modal>
      <div className="content-wrapper">
        <header>
          <div className="header-align">
            <h3 className="header">MIS Reports</h3>
          </div>
        </header>
        <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12 dashboard">
        </div>
        <div className="space-top">
          <div>
            {customerDisplay}
          </div>
        </div>
      </div>
      <div className="align-upload section-hide">
        <Pagination size="lg">
          <Pagination.First href={'/#/passbook?page=1&searchTerm='}/>
          <Pagination.Item href={"/#/passbook?page=1&searchTerm="}>{1}</Pagination.Item>
          <Pagination.Item href={"/#/passbook?page=2&searchTerm="}>{2}</Pagination.Item>
          <Pagination.Item href={"/#/passbook?page=3&searchTerm="}>{3}</Pagination.Item>
          <Pagination.Item href={"/#/passbook?page=4&searchTerm="}>{4}</Pagination.Item>
          <Pagination.Item href={"/#/passbook?page=5&searchTerm="}>{5}</Pagination.Item>
          <Pagination.Item href={"/#/passbook?page=6&searchTerm="}>{6}</Pagination.Item>
          <Pagination.Item href={"/#/passbook?page=7&searchTerm="}>{7}</Pagination.Item>
          <Pagination.Last href={"/#/passbook?page=8&searchTerm="}/>
        </Pagination>
      </div>
    </div>
  );
}


export default MisReport;
