import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import {Pagination} from 'react-bootstrap'
import moment from 'moment';
import queryString from 'query-string';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

function Customers (props) {
  const [dataView, setDataView] = useState(false)
  const [customers, setCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation()
  const [tenantId, setTenantId] = useState("")
  const { user, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    if(user) {
      setDataView(true)
    }
    searchParams.forEach((value, key) => {
      console.log(`${key}: ${value}`);
    });
    console.log(searchParams.get('limit'))
    setPage(parseInt(searchParams.get('page')));
    setLimit(searchParams.get('limit'));
    setTenantId(user.tenantId)
    setSearchTerm(searchParams.get('searchTerm'));
    console.log(limit)
    axios.get('/dashboard/customers?limit=' + searchParams.get('limit') + "&page=" + parseInt(searchParams.get('page')) + '&searchTerm=' + searchTerm + '&tenantId=' + user.tenantId)
    .then(res => {
      setCustomers(res.data)
    })
    .catch(err => console.log(err));
  }, [location]);

  const searchQuery = (e) => {
    navigate('/customers?&limit=' + '&page=' + '&searchTerm=' + searchTerm + '&tenantId=' + tenantId);
  }

  const searchOnEnter = (e) => {
    if(e.which === 13) {
      searchQuery(e);
    }
  }

  const filterCustomerPage = (
    <div className="bulk-update">
      <div className="bulk-update-select">
        <select className='form-control' type="text" onChange={e => limitUpdate(e.target.value)} name="limit" required>
          <option value=''> -- </option>
          <option value='10'> 10 </option>
          <option value='30'> 30 </option>
          <option value='60'> 60 </option>
          <option value='100'> 100 </option>
        </select>
      </div>
      <div className='input-group'>
        <span className="input-group-addon"> <i className="fa fa-envelope"></i></span>
        <input className='form-control' type='text' value={searchTerm} onChange={e => setSearchTerm(e.target.value)} onKeyPress={e => searchOnEnter(e)} name='orderId' placeholder='Search Product or Customer Name'/>
        <input className='btn btn-large update-order' type='submit' onClick={(e) => searchQuery(e)} value='SEARCH'/>
      </div>
    </div>
  )


  const customerHeader = (
    <>
      <thead>
        <tr>
          <th className="section-hide"> No. </th>
          <th scope="col" data-sortable='true'> Customer Name </th>
          <th scope="col" data-sortable='true'> Order Ids </th>
          <th scope="col" data-sortable='true'> Customer Phone </th>
          <th scope="col" data-sortable='true'> Customer Email </th>
          <th scope="col" data-sortable='true'> Order Count </th>
          <th scope="col" data-sortable='true'> Order Total </th>
          <th scope="col" data-sortable='true'> Last Order (DD/MMM/YY)</th>
        </tr>
      </thead>
    </>
  )

  const customerDisplay = (
    <div className="rowcontainer space-top">
      <div className="col-md-12 col-xl-12 col-sm-12 col-xs-12 b2corders">
        <table className='table'>
          {customerHeader}
          <tbody>
            {customers.map((customer, i) => (
              <Fragment key={i}>
                <tr className={`${moment(new Date()).isAfter(moment(customer.lastOrder).add(30, 'days').calendar()) ? "row-space date-crossed" : "row-space"}`}>
                  <td> {i+1} </td>
                  <td> {customer._id} </td>
                  <td> {customer.orderIds.join(', ')} </td>
                  <td> {customer.phone}</td>
                  <td> {customer.email}</td>
                  <td> {customer.count}</td>
                  <td> {customer.orderTotal}</td>
                  <td> {moment(customer.lastOrder).format("DD-MMM-YY")}</td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )

  const limitUpdate = (value) => {
    setLimit(value)
    navigate('/customers?limit=' + value + '&page=' + page + '&searchTerm=' + searchTerm + '&tenantId=' + tenantId);
  }

  const navigatePage = (value) => {
    navigate('/customers?limit=' + limit + '&page=' + value + '&searchTerm=' + searchTerm + '&tenantId=' + tenantId);
  }

  return (
    <div className="container-fluid">
      <div className="content-wrapper">
        <header>
          <div className="header-align">
            <h3 className="header">Customers</h3>
            {filterCustomerPage}
          </div>
        </header>
        <div>
          {customerDisplay}
        </div>
      </div>
      <div className="align-upload section-hide">
        <Pagination size="lg">
          <Pagination.First onClick={(e) => navigatePage(1)}/>
          <Pagination.Prev onClick={(e) => navigatePage(Math.max(page - 1,1))} />
          <Pagination.Next onClick={(e) => navigatePage(parseInt(page + 1))} />
          <Pagination.Last onClick={(e) => navigatePage(100)}/>
        </Pagination>
      </div>
    </div>
  );
}


export default Customers;
