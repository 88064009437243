import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { Link, useLocation } from 'react-router-dom';
import { CSVLink } from "react-csv";
import {FULFILLMENTOPTIONS} from '../shared/fulfillmentOptions.js';
import {ROUTEMAPS} from '../shared/routeMaps.js';
import QRCode from 'qrcode.react';
import { useAuth0 } from "@auth0/auth0-react";

function Route (props) {
  const [orders, setOrders] = useState([]);
  const [route, setRoute] = useState("");
  const [selectedOrders, updateSelectedOrders] = useState([]);
  const [backendIdentifier] = useState("routerPage");
  const [fulfillmentOptions] = useState(FULFILLMENTOPTIONS);
  const [routeMaps] = useState(ROUTEMAPS);
  const [disabled, setDisabled] = useState(0);
  const [bulkDisabled, setBulkDisabled] = useState(0);
  const [filterDate, setFilterDate] = useState(0);
  const [showMap, setShowMap] = useState(0);
  const [status, setStatus] = useState("");
  const location = useLocation()
  const { loading, user } = useAuth0();

  const orderUpdate = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    var orderFulfillmentStatus = data.get('fulfillmentStatus')
    var orderOrderId = data.get('orderId')
    var customerName = data.get('name')
    var customerEmail = data.get('email')
    axios.post('/orders/' + orderOrderId, {
      fulfillmentStatus: orderFulfillmentStatus,
      backendIdentifier: backendIdentifier,
      emailId: customerEmail,
      customerName: customerName,
      user: user.name
    })
    .then(res => {
      setOrders(res.data)
      window.location.reload();
    })
    .catch(err => console.log(err));
  };


  useEffect(() => {
    console.log(props.match.params.routeId)
    axios.get('/routes/' + props.match.params.routeId)
    .then(res => {
      console.log(res.data)
      setOrders(res.data);
    })
    .catch(err => console.log(err))
  }, [location]);

  const bulkLabelPrint = (e) => {
    e.preventDefault();
    setBulkDisabled(1)
    setTimeout(function(){
     setBulkDisabled(0);
   }.bind(this),10000);
    console.log('Bulk Label Print being called')
    console.log(selectedOrders)
    axios.post('/dashboard/print-label', {
      orders: selectedOrders,
    }, {
     responseType: 'arraybuffer',
     headers: {
       'Accept': 'application/pdf'
     }
   })
    .then(res => {
      console.log(res)
      const blob = new Blob([res.data], {type: 'application/pdf'})
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `Labels`
      link.click()
    })
    .catch(err => console.log(err.response));
  }

  const exportPdf = (e) => {
    e.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0);
   }.bind(this),10000);
    console.log('exportPdf being called')
    axios.post('/dashboard/exportpdf', {
      orders: orders,
      route: props.match.params.routeId
    }, {
     responseType: 'arraybuffer',
     headers: {
       'Accept': 'application/pdf'
     }
   })
    .then(res => {
      console.log(res)
      const blob = new Blob([res.data], {type: 'application/pdf'})
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `Route`
      link.click()
    })
    .catch(err => console.log(err.response));
  }

  const exportPicklist = (e) => {
    e.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0);
   }.bind(this),10000);
    console.log('exportPicklist being called')
    axios.post('/dashboard/picklist', {
      orders: selectedOrders,
    }, {
     responseType: 'arraybuffer',
     headers: {
       'Accept': 'application/pdf'
     }
   })
    .then(res => {
      // console.log(res)
      updateSelectedOrders([])
      const blob = new Blob([res.data], {type: 'application/pdf'})
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `Picklist.pdf`
      link.click()
    })
    .catch(err => console.log(err.response));
  }


  const selectedOrdersUpdate = (e) =>  {
    if(e.target.checked) {
      updateSelectedOrders(selectedOrders.concat(e.target.value))
      var ordersToPush = []
      orders.map((order) => {
        ordersToPush = ordersToPush.concat(order.orderDetails.orderId)
      })
      if (ordersToPush.length === selectedOrders.length) {
        var checkbox = document.getElementById('selectAllCheckbox');
        checkbox.checked = true
      }
    } else {
      var updatedSelectedOrders = selectedOrders.filter(filterEvent => filterEvent !== e.target.value)
      updateSelectedOrders(updatedSelectedOrders)
      var checkbox = document.getElementById('selectAllCheckbox');
      if (checkbox.checked === true) {
        checkbox.checked = false
      }
    }
  }

  const handleOnSelectAll = (e) => {
    var ordersToPush = []
    if(e.target.checked) {
      orders.map((order) => {
        ordersToPush = ordersToPush.concat(order.orderDetails.orderId)
      })
      var checkboxes = document.getElementsByName('selectedOrders');
      for (var checkbox of checkboxes) {
        checkbox.checked = true;
      }
      updateSelectedOrders(ordersToPush)
    } else {
      var checkboxes = document.getElementsByName('selectedOrders');
      for (var checkbox of checkboxes) {
        checkbox.checked = false
      }
      updateSelectedOrders(ordersToPush)
    }
  }

  const bulkOrdersUpdate = (e) => {
    e.preventDefault();
    axios.post('/bulktask/bulkupdate', {
      ordersToUpdate: selectedOrders,
      fulfillmentStatus: status
    })
    .then(res => {
      setOrders(res.data)
      updateSelectedOrders([])
      window.location.reload();
    })
    .catch(err => console.log(err.response));
  }

  const bulkOrdersRouteUpdate = (e) => {
    e.preventDefault();
    var updatedRoute = "";
    if (route === "None") {
      updatedRoute = "";
    } else {
      updatedRoute = route;
    }
    axios.post('/bulktask/bulkupdate-route', {
      ordersToUpdate: selectedOrders,
      assignedRoute: updatedRoute
    })
    .then(res => {
      setOrders(res.data)
      updateSelectedOrders([])
      window.location.reload();
    })
    .catch(err => console.log(err.response));
  }

  const toggleDateRange = (e) => {
    // e.preventDefault();
    console.log("toggledate range being clicked")
    axios.post('/routes', {
      backendIdentifier: backendIdentifier,
      currentRoute: props.match.params.routeId,
      filter: e.target.value
    })
    .then(res => {
      setFilterDate(!filterDate)
      setOrders(res.data)
      updateSelectedOrders([])
    })
    .catch(err => console.log(err.response));
  }


  const orderHeader = (
    <>
      <thead>
        <tr>
          <th> No </th>
          <th className="section-hide"> <input type="checkbox" id="selectAllCheckbox" name="selectAll" onChange={handleOnSelectAll}/></th>
          <th scope="col" data-sortable='true'> Order ID </th>
          <th scope="col" data-sortable='true'> Customer Details </th>
          <th className="col-width-address" scope="col" data-sortable='true'> Delivery Address </th>
          <th scope="col" data-sortable='true'> Product Details </th>
          <th className="section-hide" scope="col" data-sortable='true'> Tracking Id </th>
          <th scope="col" data-sortable='true'> Fulfillment Status </th>
          <th scope="col" data-sortable='true'> QR Code </th>
        </tr>
      </thead>
    </>
  )
  const updateOrder = (
    <div className="bulk-update section-hide">
      <div>
        <input className="btn bulk-update-button btn-large bulk-update-order-button" onClick={bulkOrdersUpdate} type='submit' value='Mark Selected As' disabled={selectedOrders.length === 0 || status === ""}/>
      </div>
      <div className="bulk-update-select">
        <select className='form-control' type="text" onChange={e => setStatus(e.target.value)}>
          <option value=''> -- </option>
          <option value='Delivered'> Delivered </option>
          <option value='Marked For Delivery'> Marked For Delivery </option>
          <option value='Shipped'> Shipped </option>
          <option value='Delivery Exception'> Delivery Exception </option>
        </select>
      </div>
      <div>
        <input className="btn bulk-update-button btn-large bulk-update-order-button" onClick={bulkOrdersRouteUpdate} type='submit' value='Assign Selected To Route' disabled={selectedOrders.length === 0 || route === ""}/>
      </div>
      <div className="bulk-update-select">
        <select className='form-control' type="text" onChange={e => setRoute(e.target.value)}>
          <option value=''> -- </option>
          <option value='1'> Route 1 </option>
          <option value='2'> Route 2 </option>
          <option value='3'> Route 3 </option>
          <option value='4'> Route 4 </option>
          <option value='5'> Route 5 </option>
          <option value='Professional'> Professional </option>
          <option value='None'> None </option>
        </select>
      </div>
    </div>
  )

  const filterOrder = (
    <div className="bulk-update-select">
      <select className='form-control' type="text" onChange={e => toggleDateRange(e)}>
        <option value=''> All Orders </option>
        <option value='1'> 1 Hour </option>
        <option value='2'> 2 Hours </option>
        <option value='6'> 6 Hours </option>
        <option value='12'> 12 Hours </option>
      </select>
    </div>
  )
  const exportFunc = (
    <div>
      <input className="btn bulk-update-button btn-large bulk-update-order-button" onClick={() => setShowMap(!showMap)} type='submit' value={ showMap ? 'Hide Map' : 'Show Map' }/>
      <CSVLink  className="btn btn-large btn-primary bulk-update-order-button" data={orders.map( order => ({
        orderId: order.orderDetails.orderId,
        customerName: order.orderDetails.customerName,
        customerPhone: order.orderDetails.customerPhone,
        customerAddress: order.orderDetails.deliveryAddress.deliveryStreet1 + ", " + order.orderDetails.deliveryAddress.deliveryStreet2
        + ", " + order.orderDetails.deliveryAddress.deliveryCity + ", " + order.orderDetails.deliveryAddress.deliveryState + ", " + order.orderDetails.deliveryAddress.deliveryZip,
        trackingId: order.trackingId,
        link:"https://stalksnspice.store/#/orders/" + order.orderDetails.orderId
      }))} >Bulk Export Page</CSVLink>
      <input className="btn bulk-update-button btn-large bulk-update-order-button" onClick={exportPdf} type='submit' value={disabled ? 'Exporting PDF...' : 'Export PDF' } disabled={disabled}/>
      <input className="btn bulk-update-button btn-large bulk-update-order-button" onClick={bulkLabelPrint} type='submit' value={bulkDisabled ? 'Printing Labels...' : 'Bulk Print Label' } disabled={selectedOrders.length === 0 || bulkDisabled}/>
      <input className="btn bulk-update-button btn-large bulk-update-order-button" onClick={exportPicklist} type='submit' value={disabled ? 'Exporting Picklist...' : 'Export Picklist' } disabled={selectedOrders.length === 0 || disabled}/>
    </div>
  )
  const viewOrders = (
    <div className="rowcontainer">
      <div className="col-md-12 col-xl-12 col-sm-12 col-xs-12 b2corders">
        <table className='table row-number'>
          {orderHeader}
          {orders.map((order) => (
              <tbody key={order.orderDetails.orderId}>
              <tr>
                <td className="text-center"></td>
                <td className="section-hide"> <input type="checkbox" name="selectedOrders" onChange={selectedOrdersUpdate} value={order.orderDetails.orderId}/></td>
                <td> <Link to={`/orders/${order.orderDetails.orderId}`}> {order.orderDetails.orderId} </Link></td>
                <td> {order.orderDetails.customerName} <br/> {order.orderDetails.customerPhone}</td>
                <td>
                  {order.orderDetails.deliveryAddress.deliveryStreet1} <br/>
                  {order.orderDetails.deliveryAddress.deliveryStreet2} <br/>
                  {order.orderDetails.deliveryAddress.deliveryCity}, {order.orderDetails.deliveryAddress.deliveryState}, {order.orderDetails.deliveryAddress.deliveryZip}
                </td>
                <td>
                  {order.orderDetails.productDetails.map((product) => (
                    <p key={product.productSku}> {product.productName}, {product.productOption ? product.productOption : ''}: {product.productQuantity} </p>
                  ))}
                </td>
                <td className="section-hide"> {order.trackingId}</td>
                <td className="text-center"> <strong> {order.fulfillmentStatus} </strong>
                  <div className="thumbnail center well well-sm text-center text-center space-top">
                    <form className="needs-validation" onSubmit={orderUpdate} action={'/orders/' + order.orderDetails.orderId} method='POST'>
                      <div className="input-group iuo">
                        <span className="input-group-addon">
                          <i className="fa fa-envelope"/>
                          <div>
                            <select className='form-control' type="text" name="fulfillmentStatus">
                              {fulfillmentOptions.map((status) => (
                                <option key={status.id} value={status.name}> {status.name} </option>
                              ))}
                            </select>
                            <label htmlFor='fulfillmentStatus'/>
                          </div>
                          <div className="hidden">
                            <input  name="orderId" type="hidden" value={order.orderDetails.orderId} />
                            <input  name="email" type="hidden" value={order.orderDetails.customerEmail} />
                            <input  name="name" type="hidden" value={order.orderDetails.customerName} />
                            <label htmlFor='orderId'/>
                          </div>
                        </span>
                        <div className = "iuo">
                          <input className = "btn btn-large btn-primary" type='submit' value='UPDATE STATUS' />
                        </div>
                      </div>
                    </form>
                  </div>
                </td>
                <td className="section-hide">
                  <div>
                    <QRCode
                      id="123456"
                      value={"http://stalksnspice.store/#/orders/" + order.orderDetails.orderId}
                      level={"H"}
                      includeMargin={true}
                    />
                  </div>
                </td>
              </tr>
              </tbody>
            ))}
          </table>
      </div>
    </div>
  )

  return (
    <div id="capture" className="container-fluid">
      <div className="content-wrapper">
        <header>
          <div className="header-align">
            <h3 className="header">{"ROUTE " + props.match.params.routeId}</h3>
            {filterOrder}
            {exportFunc}
          </div>
        </header>
        <div>
          {updateOrder}
        </div>
        <div className ={showMap ? "hidden" : "" }>
          {viewOrders}
        </div>
        <div className ={showMap ? "route-map-view" : "hidden" }>
          <iframe src={ routeMaps[props.match.params.routeId-1].src} width="100%" height="100%"></iframe>
        </div>
      </div>
    </div>
  );
}


export default Route
