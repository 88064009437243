import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import {Pagination, Tooltip, OverlayTrigger, Modal} from 'react-bootstrap'
import moment from 'moment';
import queryString from 'query-string';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";


function ShippingCharges (props) {
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(0);
  const [orders, setOrders] = useState([]);
  const [courier, setCourier] = useState("")
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const location = useLocation()
  const navigate = useNavigate();
  const { tenantId } = useAuth0();

  const downloadCharges = props => (
    <Tooltip {...props}>Download Charges</Tooltip>
  );
  const search = props => (
    <Tooltip {...props}>Search</Tooltip>
  );

  useEffect(() => {
    var queryValues = queryString.parse(props.location.search)
    setPage(parseInt(queryValues.page))
    axios.get('/dashboard/shipping-charges?page=' + queryValues.page + '&searchTerm=' + queryValues.searchTerm + '&courier=' + queryValues.courier + '&tenantId=' + tenantId)
    .then(res => {
      setOrders(res.data)
    })
    .catch(err => console.log(err));
  }, [location]);

  const exportCourierCharges = (e) => {
    setShow(true)
    e.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0);
   }.bind(this),10000);
    console.log('export courier charges being called')
    if(tenantId === "stalksnspice") {
      var url = `/dashboard/export-shipping-charges?tenantId=${tenantId}`
    } else {
      var url = `/dashboard/export-tenant-shipping-charges?tenantId=${tenantId}`
    }
    axios.get(url, {
     responseType: 'arraybuffer',
     headers: {
       'Accept': 'csv'
     }
   })
    .then(res => {
      // console.log(res)
      const blob = new Blob([res.data], {type: 'text/csv'})
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `charges.csv`
      link.click()
    })
    .catch(err => console.log(err.response));
  }


  const searchQuery = (e) => {
    navigate('/shipping-charges?page=' + page + '&searchTerm=' + searchTerm + '&courier=' + courier);
  }

  const searchOnEnter = (e) => {
    if(e.which === 13) {
      searchQuery(e);
    }
  }

  const courierUpdate = (value) => {
    setCourier(value)
    navigate('/shipping-charges?page=' + page + '&searchTerm=' + searchTerm + '&courier=' + value);
  }

  const handleClose = () => {
    setShow(false)
  }


  const filter = (
    <div className="bulk-update-select">
      <select className='form-control' type="text" onChange={e => courierUpdate(e.target.value)} name="route" required>
        <option value=''> All Couriers </option>
        <option value='Professional'> Professional </option>
        <option value='IndiaPost'> India Post </option>
        <option value='Xpressbees'> Xpressbees </option>
        <option value='Delhivery'> Delhivery </option>
      </select>
    </div>
  )
  const filterCustomerPage = (
    <>
      <div className="flex">
        <div className="bulk-update-select">
          <OverlayTrigger placement="right" overlay={downloadCharges}>
            <input className="btn bulk-update-button btn-large" onClick={exportCourierCharges} type='submit' value='&#x2B73;'  disabled={disabled}/>
          </OverlayTrigger>
        </div>
        <div className='input-group'>
          <span className="input-group-addon"> <i className="fa fa-envelope"></i></span>
          <input className='form-control' type='text' value={searchTerm} onChange={e => setSearchTerm(e.target.value)} onKeyPress={e => searchOnEnter(e)} name='orderId' placeholder='Search Product'/>
          <OverlayTrigger placement="right" overlay={search}>
            <input className='btn btn-large update-order' type='submit' onClick={(e) => searchQuery(e)} value='&#x2315;'/>
          </OverlayTrigger>
        </div>
      </div>
    </>
  )


  const customerHeader = (
    <>
      <thead>
        <tr>
          <th className="section-hide"> No. </th>
          <th scope="col" data-sortable='true'> Date </th>
          <th scope="col" data-sortable='true'> Order ID </th>
          <th scope="col" data-sortable='true'> AWB No. </th>
          <th scope="col" data-sortable='true'> Courier </th>
          <th scope="col" data-sortable='true'> Length </th>
          <th scope="col" data-sortable='true'> Width </th>
          <th scope="col" data-sortable='true'> Height </th>
          <th scope="col" data-sortable='true'> Weight </th>
          <th scope="col" data-sortable='true'> Volumetric Weight </th>
          <th scope="col" data-sortable='true'> Amazon Freight Earned </th>
          <th scope="col" data-sortable='true'> Actual Courier Charge </th>
          {
            tenantId === "stalksnspice"
            ? <th scope="col" data-sortable='true'> Assigned Route </th>
            : ""
          }
        </tr>
      </thead>
    </>
  )

  const customerDisplay = (
    <div className="rowcontainer space-top">
      <div className="col-md-12 col-xl-12 col-sm-12 col-xs-12 b2corders">
        <table className='table'>
          {customerHeader}
          <tbody>
            {orders.map((order, i) => (
              <Fragment key={i}>
                <tr className={order.shipping.courierChargedRate < order.orderDetails.shippingPrice ? "row-text-green" : "row-text-red"}>
                  <td> {i+1} </td>
                  <td> {moment(order.orderDetails.orderDate).format("DD-MMM-YY")} </td>
                  <td> <Link to={`/orders/${order.orderDetails.orderId}`}> {order.orderDetails.orderId} </Link> </td>
                  <td> {order.shipping.trackingId}</td>
                  <td> {order.shipping.courier }</td>
                  <td> {order.shipping.length }</td>
                  <td> {order.shipping.width }</td>
                  <td> {order.shipping.height }</td>
                  <td> {order.shipping.weight }</td>
                  <td> {order.shipping.length * order.shipping.height * order.shipping.width / 5000 }</td>
                  <td> {order.orderDetails.shippingPrice }</td>
                  {
                    tenantId === "stalksnspice"
                    ?
                    <td> {order.shipping.courierChargedRate ? order.shipping.courierChargedRate : "Rate Not Found"}</td>
                    :
                    <td> {order.tenantCourierCharge ? order.tenantCourierCharge : "Rate Not Found"}</td>
                  }

                  {
                    tenantId === "stalksnspice"
                    ? <td> {order.assignedRoute}</td>

                    : ""
                  }
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )

  return (
    <div className="container-fluid">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Charges Fetch Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your charges are being fetched. Kindly wait 5-10s for the excel to download.</Modal.Body>
      </Modal>
      <div className="content-wrapper">
        <header>
          <div className="header-align">
            <h3 className="header">Courier Charges</h3>
            {filter}
            {filterCustomerPage}
          </div>
        </header>
        <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12 dashboard">
        </div>
        <div className="space-top">
          <div>
            {customerDisplay}
          </div>
        </div>
      </div>
      <div className="align-upload section-hide">
        <Pagination size="lg">
          <Pagination.First href={'/#/shipping-charges?page=1&searchTerm=' + searchTerm + '&courier=' + courier}/>
          <Pagination.Item href={"/#/shipping-charges?page=1&searchTerm=" + searchTerm + '&courier=' + courier}>{1}</Pagination.Item>
          <Pagination.Item href={"/#/shipping-charges?page=2&searchTerm=" + searchTerm + '&courier=' + courier}>{2}</Pagination.Item>
          <Pagination.Item href={"/#/shipping-charges?page=3&searchTerm=" + searchTerm + '&courier=' + courier}>{3}</Pagination.Item>
          <Pagination.Item href={"/#/shipping-charges?page=4&searchTerm=" + searchTerm + '&courier=' + courier}>{4}</Pagination.Item>
          <Pagination.Item href={"/#/shipping-charges?page=5&searchTerm=" + searchTerm + '&courier=' + courier}>{5}</Pagination.Item>
          <Pagination.Item href={"/#/shipping-charges?page=6&searchTerm=" + searchTerm + '&courier=' + courier}>{6}</Pagination.Item>
          <Pagination.Item href={"/#/shipping-charges?page=7&searchTerm=" + searchTerm + '&courier=' + courier}>{7}</Pagination.Item>
          <Pagination.Last href={"/#/shipping-charges?page=8&searchTerm=" + searchTerm + '&courier=' + courier}/>
        </Pagination>
      </div>
    </div>
  );
}


export default ShippingCharges;
