import React, { useState, useEffect } from 'react';
import {MENUOPTIONSFORALL} from '../shared/menuOptions.js';
import {MENUOPTIONSFORDRIVERS} from '../shared/menuOptions.js';
import {DRIVER} from '../shared/userList.js'
import { Link, useNavigate } from 'react-router-dom';
import {Navbar, NavDropdown, Modal, Nav, Dropdown} from 'react-bootstrap'
import { useAuth0 } from "@auth0/auth0-react";
import '../css/Header.css';


function Header() {
  const [searchOrderId, searchOrderUpdate] = useState("");
  const [show, setShow] = useState(false);
  const [drivers] = useState(DRIVER);
  const [wallet, setWallet] = useState(0)
  const [rechargeAmount, setRechargeAmount] = useState(0)
  const [menuOptions, setMenuOptions] = useState([])
  const [sideBarStatus, toggleSideBarStatus] = useState(1)
  const { isAuthenticated, loginWithRedirect, logout, loading, user } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && drivers.includes(user.email)) {
      setMenuOptions(MENUOPTIONSFORDRIVERS)
    } else {
      setMenuOptions(MENUOPTIONSFORALL)
    }

    // axios.get('wallet/balance?tenantId=' + tenantId)
    // .then(res => {
    //   console.log(res.data)
    //   setWallet(res.data.balance)
    // })
    // .catch(err => console.log(err.response));
  }, []);

  const urlUpdate = (value) => {
    navigate(`/orders/${searchOrderId.trim()}`);
  }

  const urlUpdateOnEnter = (e) => {
    if(e.which === 13) {
      navigate(`/orders/${searchOrderId.trim()}`);
    }
  }

  const handleClose = () => {
    setShow(false)
  }

  const rechargeWallet = () => {
    setShow(true)
  }

  const razorpay = () => {
    setShow(false)
  }

  const paytm = () => {
    setShow(false)
  }

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="sidebar-menu-option"
    >
      {children}
      &#x25B6;
    </a>
  ));

  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                child.props.children.toLowerCase(),
            )}
          </ul>
        </div>
      );
    },
  );

  const authenticated = (
    <div className="App">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Recharge Wallet
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="center">
            <p> Current Balance: Rs. {wallet} </p>
          </div>
          <div className="center">
            <span className="dim-input-span">
              <label><input  className="tracking-input" name="rechargeAmount" value={rechargeAmount} onChange={e => setRechargeAmount(e.target.value)}/> Enter Amount in Rs. </label>
            </span>
          </div>
          <div className = "iuo">
            <input className = "btn btn-large btn-primary" onClick={razorpay} type='submit' value='Razorpay' />
            <input className = "btn btn-large btn-primary" onClick={paytm} type='submit' value='Paytm' />
          </div>
        </Modal.Body>
      </Modal>
      <div className="container-fluid">
        <div className={sideBarStatus? "sidebar" : "sidebar hide-class"}>
          <div>
            {menuOptions.map((menu) => (
              <div key={menu.id} className="sidebar-element">
                <img className= "icon" src={menu.img} />
                <Dropdown key = {menu.id} drop="right">
                  <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-2" > {menu.name} </Dropdown.Toggle>
                  <Dropdown.Menu as={CustomMenu} className="super-colors">
                    {menu.options.map((option) => (
                      <Dropdown.Item as={Link} key={option.id} to={option.url}>{option.name}</Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            ))}
          </div>
        </div>
        <header>
          <div className="nav navbar-home navbar navbar-expand-xl navbar-light bg-light">
            <div className="col-lg-6 cold-md-6 col-sm-12 col-xs-12 align-header">
              <img className= "icon" src="https://s3.ap-south-1.amazonaws.com/stalksnspice.com/logos/sns-logox60px.jpg"/>
            </div>
            <div className="col-lg-6 cold-md-6 col-sm-12 col-xs-12">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className='input-group'>
                  <div className="login-div">
                    <button className="btn btn-large" onClick={() => toggleSideBarStatus(!sideBarStatus)}><img className="login" src="https://website-essentials.s3.ap-south-1.amazonaws.com/hamburger-white-nackground.png"/></button>
                  </div>
                  <p className='btn btn-large btn-primary small-screen-hide'> {user ? "Welcome " + user.name : "Welcome"} </p>
                  <span className="input-group-addon"> <i className="fa fa-envelope"></i></span>
                  <input className='form-control' type='text' value={searchOrderId} onChange={e => searchOrderUpdate(e.target.value)} onKeyPress={e => urlUpdateOnEnter(e)} name='orderId' placeholder='Search OrderId, SNS TrackingId or Courier TrackingId'/>
                  <div className='invalid-feedback'> Please provide a valid order ID </div>
                    <input className='btn btn-large update-order' type='submit' onClick={urlUpdate} value='&#x2315;' />
                    <a className='btn btn-large btn-primary small-screen-hide' type='submit' onClick={rechargeWallet}>
                      <span className="horizontal-align">
                        <img className="header-icon" src="https://website-essentials.s3.ap-south-1.amazonaws.com/wallet-white-bg.png"/>
                        Rs.{wallet}
                      </span>
                    </a>
                  <div className="login-div">
                    {isAuthenticated && <button className="btn btn-large" onClick={() => logout()}><img className="login" src="https://website-essentials.s3.ap-south-1.amazonaws.com/login-white-background.png"/> <p className="logout-text"> Logout </p> </button>}
                  </div>

                  </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    </div>
  )

  const notAuthenticated = (
    <div className="App">
      <div className="container-fluid">
        <header>
          <Navbar bg="transparent" variant="light" expand="lg">
            <Navbar.Brand href="#home">
              <a class="navbar-brand" href="#"><img className="logo" src="https://s3.ap-south-1.amazonaws.com/stalksnspice.com/logos/sns-logo-small-no-background-100px.png"/></a>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link href="#home">Home</Nav.Link>
                <Nav.Link href="#fulfillment">Fulfillment</Nav.Link>
                <Nav.Link href="#facilities">Facilities</Nav.Link>
                <Nav.Link href="#packaging">Packaging</Nav.Link>
                <NavDropdown title="Channels" id="basic-nav-dropdown">
                  <NavDropdown.Item href="#amazon">Amazon</NavDropdown.Item>
                  <NavDropdown.Item href="#stalksnspice">Stalks N Spice</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#horeca">HORECA</NavDropdown.Item>
                  <NavDropdown.Item href="#udaan">Udaan</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="SAAS Platform" id="basic-nav-dropdown">
                  <NavDropdown.Item href="#crm">CRM Tool</NavDropdown.Item>
                  <NavDropdown.Item href="#dashabord">Dashboard</NavDropdown.Item>
                  <NavDropdown.Item href="#track-orders">Track Orders</NavDropdown.Item>
                  <NavDropdown.Item href="#retargeting">Retarget</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="#">
                  {!isAuthenticated && (
                    <button className="btn btn-large" onClick={() => loginWithRedirect({})}>
                    Login </button>
                  )}
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </header>
      </div>
    </div>
  )

  return (
    <>
      {isAuthenticated ? (
        <>
          {authenticated}
        </>
      ) : (
        <>
          {notAuthenticated}
        </>
      )

    }
    </>
  );
}


export default Header
