import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";

import moment from 'moment';


function Reports (props) {
  const [amazonSales, setAmazonSales] = useState(0);
  const [snsSales, setSnsSales] = useState(0);
  const [horecaSales, setHorecaSales] = useState(0);
  const [deliveryReport, setDeliveryReport] = useState([])
  const { loading, user, tenantId } = useAuth0();


  useEffect(() => {
    axios.get('/dashboard/sales-reports?tenantId=' + tenantId)
    .then(res => {
      res.data.forEach(function(channel) {
        if(channel._id==='Ecwid') {
          setSnsSales(channel.orderTotal)
        } else if(channel._id === 'Amazon') {
          setAmazonSales(channel.orderTotal)
        } else if(channel._id === 'HORECA') {
          setHorecaSales(channel.orderTotal)
        }
      });
    })
    .catch(err => console.log(err));

    axios.get('/dashboard/delivery-reports')
    .then(res => {
      setDeliveryReport(res.data)
    })
    .catch(err => console.log(err));

  }, []);

  const toggleDateRange = (e) => {
    // e.preventDefault();
    console.log("toggledate range being clicked")
    axios.post('/dashboard/sales-reports?tenantId=' + tenantId, {
      filter: e.target.value
    })
    .then(res => {
      var snsSales = 0;
      var amazonSales = 0;
      var horecaSales = 0;
      res.data.forEach(function(channel) {
        if(channel._id==='Ecwid') {
          snsSales = channel.orderTotal;
        } else if(channel._id === 'Amazon') {
          amazonSales = channel.orderTotal;
        } else if(channel._id === 'HORECA') {
          horecaSales = channel.orderTotal;
        }
      });
      setSnsSales(snsSales)
      setHorecaSales(horecaSales)
      setAmazonSales(amazonSales)

    })
    .catch(err => console.log(err.response));
  }

  const pincodesHeader = (
    <>
      <thead>
        <tr>
          <th scope="col" data-sortable='true'> Delhi </th>
          <th scope="col" data-sortable='true'> Haryana </th>
          <th scope="col" data-sortable='true'> Ahmedabad </th>
          <th scope="col" data-sortable='true'> Mumbai </th>
          <th scope="col" data-sortable='true'> Pune </th>
          <th scope="col" data-sortable='true'> Hyderabad </th>
          <th scope="col" data-sortable='true'> Rest of Andhra </th>
          <th scope="col" data-sortable='true'> Bangalore </th>
          <th scope="col" data-sortable='true'> Mysore </th>
          <th scope="col" data-sortable='true'> Rest of Karnataka </th>
          <th scope="col" data-sortable='true'> Chennai </th>
          <th scope="col" data-sortable='true'> Hosur </th>
          <th scope="col" data-sortable='true'> Rest of Tamil Nadu </th>
          <th scope="col" data-sortable='true'> Kerala </th>
          <th scope="col" data-sortable='true'> Rest of India </th>
        </tr>
      </thead>
    </>
  );

  const deliverReportDisplay = (
    <div className="col-md-12 col-xl-12 col-sm-12 col-xs-12 b2corders">
      <table className="table">
        {pincodesHeader}
        <tbody >
          <tr>
          {deliveryReport.map((report) => (
            <td key={report._id}>{report.count}</td>
          ))}
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <div className="content-wrapper">
      <div className="header-align">
        <h1 className="header"> REPORTS </h1>
        <div className="bulk-update-select align-center col-md-3 col-lg-3 col-xl-3 col-sm-12 col-xs-12">
          <select className='form-control' type="text" onChange={e => toggleDateRange(e)}>
            <option value=''> All Orders </option>
            <option value='1'> 1 Days </option>
            <option value='7'> 7 Days </option>
            <option value='14'> 14 Days </option>
            <option value='30'> 30 Days </option>
          </select>
        </div>
      </div>
      <div>
        <div className="flex-display space-top">
          <div className="col-md-3 col-lg-3 col-xl-3 col-sm-3 col-xs-3">
            <div className="card-body">
              <h3> Amazon Sales <br/> {Math.round(amazonSales).toLocaleString()} </h3>
            </div>
          </div>
          <div className="col-md-3 col-lg-3 col-xl-3 col-sm-3 col-xs-3">
            <div className="card-body">
              <h3> Website Sales <br/> {Math.round(snsSales).toLocaleString()} </h3>
            </div>
          </div>
          <div className="col-md-3 col-lg-3 col-xl-3 col-sm-3 col-xs-3">
            <div className="card-body">
              <h3> HORECA Sales <br/> {Math.round(horecaSales).toLocaleString()} </h3>
            </div>
          </div>
          <div className="col-md-3 col-lg-3 col-xl-3 col-sm-3 col-xs-3">
            <div className="card-body">
              <h3> Total Sales <br/> {(+Math.round(snsSales) + +Math.round(amazonSales) + +Math.round(horecaSales)).toLocaleString()} </h3>
            </div>
          </div>
        </div>
          <div className= "flex-display space-top">
          <div className="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12">
            <div className="chart-card-body">
              <iframe className="no-border" width="100%" height="480px" src="https://charts.mongodb.com/charts-stalksnspice-nwwzw/embed/charts?id=8d4433aa-c236-461f-bf1d-94d5c70d7a88&theme=light"></iframe>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12">
            <div className="chart-card-body">
              <iframe className="no-border" width="100%" height="480px" src="https://charts.mongodb.com/charts-stalksnspice-nwwzw/embed/charts?id=bd2e0cca-c9fe-4d21-a5fa-d148daa2c18d&theme=light"></iframe>
            </div>
          </div>
        </div>
          <div className="space-top-extra">
            <hr className="hr-large d-none d-md-block"/>
            <h3> Pincodes Delivery Report </h3>
            {deliverReportDisplay}
          </div>
      </div>
    </div>
  )
}


export default Reports;
