'use strict';
import {professionalKerala, professionalHyderabad, professionalKarnataka, professionalTamilNadu, professionalMumbai, bangalore, zoneA, zoneB, zoneC, zoneD, zoneE,
indiaPostLocal, indiaPostNCR, indiaPostMetro, indiaPostWithinState, indiaPostNeighboringState, indiaPostOtherStates} from './pincodes.js';
//
// function selectedOrdersUpdate(e) {
// 	if(e.target.checked) {
// 		updateSelectedOrders(selectedOrders.concat(e.target.value))
// 		var ordersToPush = []
// 		orders.map((order) => {
// 			ordersToPush = ordersToPush.concat(order.orderDetails.orderId)
// 		})
// 		if (ordersToPush.length === selectedOrders.length) {
// 			var checkbox = document.getElementById('selectAllCheckbox');
// 			checkbox.checked = true
// 		}
// 	} else {
// 		var updatedSelectedOrders = selectedOrders.filter(filterEvent => filterEvent !== e.target.value)
// 		updateSelectedOrders(updatedSelectedOrders)
// 		var checkbox = document.getElementById('selectAllCheckbox');
// 		if (checkbox.checked === true) {
// 			checkbox.checked = false
// 		}
// 	}
// }
//
// function handleOnSelectAll(e){
// 	var ordersToPush = []
// 	if(e.target.checked) {
// 		orders.map((order) => {
// 			ordersToPush = ordersToPush.concat(order.orderDetails.orderId)
// 		})
// 		var checkboxes = document.getElementsByName('selectedOrders');
// 		for (var checkbox of checkboxes) {
// 			checkbox.checked = true;
// 		}
// 		updateSelectedOrders(ordersToPush)
// 	} else {
// 		var checkboxes = document.getElementsByName('selectedOrders');
// 		for (var checkbox of checkboxes) {
// 			checkbox.checked = false
// 		}
// 		updateSelectedOrders(ordersToPush)
// 	}
// }
//
// function bulkOrdersUpdate (e) {
// 	e.preventDefault();
// 	axios.post('/bulktask/bulkupdate', {
// 		ordersToUpdate: selectedOrders,
// 		fulfillmentStatus: status
// 	})
// 	.then(res => {
// 		setOrders(res.data)
// 		updateSelectedOrders([])
// 		window.location.reload();
// 	})
// 	.catch(err => console.log(err.response));
// }
//
// function bulkOrdersRouteUpdate(e) {
// 	e.preventDefault();
// 	var updatedRoute = "";
// 	if (route === "None") {
// 		updatedRoute = "";
// 	} else {
// 		updatedRoute = route;
// 	}
// 	axios.post('/bulktask/bulkupdate-route', {
// 		ordersToUpdate: selectedOrders,
// 		assignedRoute: updatedRoute
// 	})
// 	.then(res => {
// 		setOrders(res.data)
// 		updateSelectedOrders([])
// 		window.location.reload();
// 	})
// 	.catch(err => console.log(err.response));
// }
//
// function toggleDateRange(e){
// 	// e.preventDefault();
// 	console.log("toggledate range being clicked")
// 	axios.post('/routes', {
// 		backendIdentifier: backendIdentifier,
// 		currentRoute: props.match.params.routeId,
// 		filter: e.target.value
// 	})
// 	.then(res => {
// 		setFilterDate(!filterDate)
// 		setOrders(res.data)
// 		updateSelectedOrders([])
// 	})
// 	.catch(err => console.log(err.response));
// }
function professionalRateCalculator(order) {
  var rate = 0;
  var cal_weight = 0;
  var weight = order.shipping.weight;
  var vol_weight = order.shipping.length * order.shipping.width * order.shipping.height / 5000;
  cal_weight = (weight > vol_weight) ? weight : vol_weight;
  // console.log("cal_weight:" + cal_weight)
  // cal_weight > 2 ? cal_weight === Math.ceil(cal_weight) : cal_weight === Math.round(cal_weight*2)/2
  if(bangalore.includes(order.orderDetails.deliveryAddress.deliveryZip)) {
    rate = 20 + ((Math.ceil(cal_weight*2)/2)/0.5 - 1) * 20
  } else if(professionalKerala.includes(order.orderDetails.deliveryAddress.deliveryZip)) {
    rate = 25 + ((Math.ceil(cal_weight*2)/2)/0.5 - 1) * 20
  } else if(professionalHyderabad.includes(order.orderDetails.deliveryAddress.deliveryZip)) {
    rate = 25 + ((Math.ceil(cal_weight*2)/2)/0.5 - 1) * 20
  } else if(professionalTamilNadu.includes(order.orderDetails.deliveryAddress.deliveryZip)) {
    rate = 25 + ((Math.ceil(cal_weight*2)/2)/0.5 - 1) * 20
  } else if(professionalKarnataka.includes(order.orderDetails.deliveryAddress.deliveryZip)) {
    rate = 25 + ((Math.ceil(cal_weight*2)/2)/0.5 - 1) * 20
  } else if(professionalMumbai.includes(order.orderDetails.deliveryAddress.deliveryZip)) {
    rate = 30 + ((Math.ceil(cal_weight*2)/2)/0.5 - 1) * 20
  } else {
    rate = 10000
  }
  return rate;
}

function pickrrRateCalculator(order) {
  var rate = 0;
  var cal_weight = 0;
  var weight = order.shipping.weight;
  var vol_weight = order.shipping.length * order.shipping.width * order.shipping.height / 5000;
  cal_weight = (weight > vol_weight) ? weight : vol_weight;
  // console.log("cal_weight:" + cal_weight)
  // cal_weight > 2 ? cal_weight === Math.ceil(cal_weight) : cal_weight === Math.round(cal_weight*2)/2
  if(zoneA.includes(order.orderDetails.deliveryAddress.deliveryZip)) {
    rate = 22 + ((Math.ceil(cal_weight*2)/2)/0.5 - 1) * 22
  } else if(zoneB.includes(order.orderDetails.deliveryAddress.deliveryZip)) {
    rate = 25 + ((Math.ceil(cal_weight*2)/2)/0.5 - 1) * 25
  } else if(zoneC.includes(order.orderDetails.deliveryAddress.deliveryZip)) {
    rate = 32 + ((Math.ceil(cal_weight*2)/2)/0.5 - 1) * 32
  } else if(zoneD.includes(order.orderDetails.deliveryAddress.deliveryZip)) {
    rate = 35 + ((Math.ceil(cal_weight*2)/2)/0.5 - 1) * 35
  } else if(zoneE.includes(order.orderDetails.deliveryAddress.deliveryZip)) {
    rate = 44 + ((Math.ceil(cal_weight*2)/2)/0.5 - 1) * 44
  } else {
    rate = 10000
  }
  return rate;
}

function shipEaseRateCalculator(order) {
  var rate = 0;
  var cal_weight = 0;
  var weight = order.shipping.weight;
  var vol_weight = order.shipping.length * order.shipping.width * order.shipping.height / 5000;
  cal_weight = (weight > vol_weight) ? weight : vol_weight;
  // console.log("cal_weight:" + cal_weight)
  // cal_weight > 2 ? cal_weight === Math.ceil(cal_weight) : cal_weight === Math.round(cal_weight*2)/2
  if(zoneA.includes(order.orderDetails.deliveryAddress.deliveryZip)) {
    if (cal_weight <= 0.5) {
      rate = 22
    } else if (cal_weight > 0.5 && cal_weight <= 2) {
      rate = 31 + Math.max(Math.ceil(cal_weight) - 1, 0) * 22
    } else if (cal_weight > 2) {
      rate = 51 + Math.max(Math.ceil(cal_weight) - 3, 0) * 21
    }
  } else if(zoneB.includes(order.orderDetails.deliveryAddress.deliveryZip)) {
    if (cal_weight <= 0.5) {
      rate = 25
    } else if (cal_weight > 0.5 && cal_weight <= 2) {
      rate = 34 + Math.max(Math.ceil(cal_weight) - 1, 0) * 29
    } else if (cal_weight > 2) {
      rate = 68 + Math.max(Math.ceil(cal_weight) - 3, 0) * 27
    }
  } else if(zoneC.includes(order.orderDetails.deliveryAddress.deliveryZip)) {
    if (cal_weight <= 0.5) {
      rate = 32
    } else if (cal_weight > 0.5 && cal_weight <= 2) {
      rate = 44 + Math.max(Math.ceil(cal_weight) - 1) * 35
    } else if (cal_weight > 2) {
      rate = 90 + Math.max(Math.ceil(cal_weight) - 3) * 33
    }
  } else if(zoneD.includes(order.orderDetails.deliveryAddress.deliveryZip)) {
    if (cal_weight <= 0.5) {
      rate = 35
    } else if (cal_weight > 0.5 && cal_weight <= 2) {
      rate = 54 + Math.max(Math.ceil(cal_weight) - 1, 0) * 42
    } else if (cal_weight > 2) {
      rate = 105 + Math.max(Math.ceil(cal_weight) - 3, 0) * 40
    }
  } else if(zoneE.includes(order.orderDetails.deliveryAddress.deliveryZip)) {
    if (cal_weight <= 0.5) {
      rate = 44
    } else if (cal_weight > 0.5 && cal_weight <= 2) {
      rate = 58 + Math.max(Math.ceil(cal_weight) - 1, 0) * 50
    } else if (cal_weight > 2) {
      rate = 122 + Math.max(Math.ceil(cal_weight) - 3, 0) * 48
    }
  } else {
    rate = 10000
  }
  return rate;
}

function indiaPostRateCalculator(order) {
	var rate = 0;
  var cal_weight = 0;
  var weight = order.shipping.weight;
  var vol_weight = order.shipping.length * order.shipping.width * order.shipping.height / 5000;
  cal_weight = (weight > vol_weight) ? weight : vol_weight;
  if(indiaPostLocal.includes(order.orderDetails.deliveryAddress.deliveryZip)) {
    rate = 45 + (Math.min(Math.max(Math.ceil(cal_weight), 2), 5) - 2) * 12 + Math.max(Math.ceil(cal_weight) - 5, 0) * 14
  } else if(indiaPostWithinState.includes(order.orderDetails.deliveryAddress.deliveryZip)) {
    rate = 80 + (Math.min(Math.max(Math.ceil(cal_weight), 2), 5) - 2) * 20 + Math.max(Math.ceil(cal_weight) - 5, 0) * 22
  } else if(indiaPostNeighboringState.includes(order.orderDetails.deliveryAddress.deliveryZip)) {
    rate = 100 + (Math.min(Math.max(Math.ceil(cal_weight), 2), 5) - 2) * 25 + Math.max(Math.ceil(cal_weight) - 5, 0) * 28
  } else if(indiaPostMetro.includes(order.orderDetails.deliveryAddress.deliveryZip)) {
    rate = 105 + (Math.min(Math.max(Math.ceil(cal_weight), 2), 5) - 2) * 25 + Math.max(Math.ceil(cal_weight) - 5, 0) * 28
  } else if(indiaPostNCR.includes(order.orderDetails.deliveryAddress.deliveryZip)) {
    rate = 70 + (Math.min(Math.max(Math.ceil(cal_weight), 2), 5) - 2) * 15 + Math.max(Math.ceil(cal_weight) - 5, 0) * 18
  } else if(indiaPostOtherStates.includes(order.orderDetails.deliveryAddress.deliveryZip)) {
    rate = 115 + (Math.min(Math.max(Math.ceil(cal_weight), 2), 5) - 2) * 30 + Math.max(Math.ceil(cal_weight) - 5, 0) * 32
  } else {
    rate = 10000
  }

  return rate;
}

export {
  professionalRateCalculator,
  pickrrRateCalculator,
  indiaPostRateCalculator,
  shipEaseRateCalculator
// 	selectedOrdersUpdate: selectedOrdersUpdate,
// 	handleOnSelectAll: handleOnSelectAll,
// 	bulkOrdersRouteUpdate: bulkOrdersRouteUpdate,
// 	bulkOrdersUpdate: bulkOrdersUpdate,
// 	toggleDateRange: toggleDateRange,
}
